import styles from './PrevButton.module.scss'

interface IPrevButtonProps {
  disabled: boolean
  clickCallback: Function
}

export default function PrevButton({ disabled, clickCallback }: IPrevButtonProps) {
  return (
    <button className={styles['prev-button']} disabled={disabled} onClick={() => clickCallback()}>
      <svg width="32" height="32" viewBox="0 0 32 32">
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="black" fill-opacity="0.1" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" data-circle="true" />
        <path d="M18.9891 22L13 16.0109L18.9891 10L19.6046 10.6187L14.2341 16.0109L19.6046 21.3813L18.9891 22Z" />
      </svg>
    </button>
  )
}
