import parse from 'html-react-parser'
import cx from 'classnames'
import styles from './HeroGeneralInfo.module.scss'
import { HeroGeneralInfoProps, HeroInfoBarLinkProp, ImageProps } from '~types'
import { Heading, HeroInfoBarLink } from '~elements'
import { useWindowSize } from 'utils/hooks'

const HeroGeneralInfo = ({ headline, copy, infoItems, backgroundImageXl, backgroundImageSm, showBackgroundImage }: HeroGeneralInfoProps) => {
  const { isBreakpoint: isBelowBreakpointSm } = useWindowSize('md', '<')
  const generalInfoImg = isBelowBreakpointSm ? backgroundImageSm : backgroundImageXl

  const getInfoImageWidth = (image: ImageProps) => (isBelowBreakpointSm ? 20 : image.width)
  const getInfoImageHeight = (image: ImageProps) => (isBelowBreakpointSm ? 20 : image.height)

  const showInfoClass =
    infoItems && infoItems.length > 2 ? cx([styles['show-info'], styles['justify-between']]) : cx([styles['show-info'], styles['justify-around']])

  const generalInfoItems = (
    <div className={showInfoClass}>
      {infoItems.map(({ anchorText, url, image: { image } }: HeroInfoBarLinkProp) => (
        <HeroInfoBarLink
          key={anchorText}
          imageWidth={getInfoImageWidth(image)}
          imageHeight={getInfoImageHeight(image)}
          imageUrl={image.url}
          url={url}
          anchorText={anchorText}
        />
      ))}
    </div>
  )

  return (
    <div className={cx(styles['hero-general-info'], generalInfoImg?.url ? styles['has-header'] : [])}>
      <div className={styles['hero-general-info-container']}>
        <div className={styles['general-info']}>
          {(headline || copy) && (
            <div>
              {headline && <Heading level={2}>{headline}</Heading>}
              {copy && copy?.html && parse(copy.html)}
            </div>
          )}
          {infoItems && infoItems.length > 0 && generalInfoItems}
        </div>
      </div>
      {showBackgroundImage && <div className={styles['general-info__bg-img']} style={{ backgroundImage: `url(${generalInfoImg?.url})` }} />}
    </div>
  )
}

export default HeroGeneralInfo
