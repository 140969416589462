import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { ResultModal } from '@msgtechnology/sphere'
import styles from './SignUp.module.scss'
import { Button, Heading, Paragraph } from '~elements'
import { BackgroundMedia, ButtonProps, ISignUpProps, ISignUpSubmission } from '~types'
import { useWindowSize, useIsSmartphoneLandscape } from 'utils/hooks'
import { useRouter } from 'next/router'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { createFormstackPayload } from 'utils'
import { fetchFormstackData, createFormstackPostCall } from 'lib/formstack'
import parse from 'html-react-parser'

interface IInvalidFields {
  name?: string
  email?: string
  age?: string
}

const SignUp = ({
  heading,
  headingIsCenterAligned = false,
  body,
  bodyIsCenterAligned = false,
  disclaimer,
  disclaimerIsCenterAligned = false,
  submitButton,
  redirectAfterFormSubmit,
  buttonGroup,
  buttonsAreCenterAligned = false,
  confirmationModalText,
  backgroundMedia,
  mobileBackgroundMedia,
  formId,
  checkboxIsDisabled = true
}: ISignUpProps) => {
  const [data, setData] = useState<any>()
  const [fields, setFields] = useState<any[]>([])
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [over21Checked, setOver21Checked] = useState<boolean>(false)
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [invalidFields, setInvalidFields] = useState<IInvalidFields>({})
  const buttonContainerStyles = cx([styles['btn-container'], styles[buttonsAreCenterAligned ? 'center-align' : '']])
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const isSmartphoneLandscape = useIsSmartphoneLandscape()
  const signUpContainerStyles = cx([styles['sign-up-container'], styles[isSmartphoneLandscape ? 'landscape' : '']])
  const backgroundVideoRef = useRef<HTMLVideoElement>(null)

  const router = useRouter()

  const emailRegex = '[@]'
  const requiredMessage = 'Please fill out this field.'

  useEffect(() => {
    if (!data && formId) {
      ;(async () => {
        try {
          const data = await fetchFormstackData(formId)
          setData(data)
          setFields(data.fields)
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [data])

  useEffect(() => {
    backgroundVideoRef.current?.load()
  }, [isBelowBreakpointMd])

  const handleInputValidation = (e: SyntheticEvent<ISignUpSubmission>) => {
    const invalidFields: IInvalidFields = {}
    const emailRegexTester = new RegExp(emailRegex)

    if (e.currentTarget.elements.name.value === '') {
      invalidFields.name = requiredMessage
    }

    if (e.currentTarget.elements.email.value === '') {
      invalidFields.email = requiredMessage
    } else if (!emailRegexTester.test(e.currentTarget.elements.email.value)) {
      invalidFields.email = 'Please include an “@” in the email address.'
    }

    if (!checkboxIsDisabled && over21Checked === false) {
      invalidFields.age = 'Please check this box if you want to proceed.'
    }
    return invalidFields
  }

  const handleFailedSubmission = (error?: any) => {
    const invalidFields: IInvalidFields = {}
    invalidFields.name = error ? error.message : 'Something went wrong!'
    invalidFields.email = error ? error.message : 'Something went wrong!'

    setInvalidFields(invalidFields)
    setIsDisabled(false)
  }

  const clearForm = () => {
    setName('')
    setEmail('')
    setOver21Checked(false)
    setInvalidFields({})
    setIsDisabled(false)
  }

  const handleSubmit = async (e: React.SyntheticEvent<ISignUpSubmission>) => {
    e.preventDefault()
    setIsDisabled(true)
    const invalidFields = handleInputValidation(e as React.SyntheticEvent<ISignUpSubmission>)

    if ((fields.length && !invalidFields) || Object.keys(invalidFields).length === 0) {
      try {
        const payload = createFormstackPayload(fields, e.currentTarget.elements)
        const formstackPost = createFormstackPostCall(payload, formId)
        const response = await formstackPost

        if (response.status !== 'error') {
          setIsDisabled(false)
          setConfirmationModalIsOpen(true)
          clearForm()
        } else {
          handleFailedSubmission({ message: response.error })
          setIsDisabled(false)
        }
      } catch (err) {
        console.error(err)
        setIsDisabled(false)
        handleFailedSubmission()
      }
    } else {
      setInvalidFields(invalidFields)
      setIsDisabled(false)
    }
  }

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (buttonProps.url) {
      router.push(buttonProps.url)
    } else {
      gaBtnClick(CTA_CLICK_EVENT, heading || '', buttonProps.title)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    const { key } = event
    if (key === ' ' || key === 'Spacebar') {
      setOver21Checked(!over21Checked)
    }
  }

  const displayBGMedia = (bgMedia: BackgroundMedia) => {
    if (bgMedia?.image) {
      return (
        <>
          <div
            className={styles['media-image']}
            style={{
              backgroundImage: `url(${bgMedia.imageUrlS3})`
            }}
          />
          <div className={styles['media-overlay']} />
        </>
      )
    } else if (bgMedia?.video[0] && bgMedia?.posterImageUrlS3) {
      return (
        <div className={styles['video-container']}>
          <video
            id={`background-video-${bgMedia.id}`}
            ref={backgroundVideoRef}
            loop
            muted
            autoPlay={true}
            poster={backgroundMedia.posterImageUrlS3}
            controls={false}
            playsInline={true}
            disableRemotePlayback
          >
            <source src={bgMedia.video[0].url} type="video/mp4" />
          </video>
          <div className={styles['media-overlay']} />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className={styles['wrapper-container']}>
      <div className={signUpContainerStyles}>
        <div className={styles['form-wrapper']}>
          <div className={styles['form-container']}>
            <div className={styles['text-container']}>
              {heading ? (
                <Heading level={2} justify={headingIsCenterAligned ? 'center' : 'left'}>
                  {heading}
                </Heading>
              ) : null}
              {body?.html && <Paragraph text={body} justify={bodyIsCenterAligned ? 'center' : 'left'} />}
            </div>

            <form id={'signup-form'} className={styles['signup-form']} noValidate onSubmit={handleSubmit}>
              <div className={styles['input-container']}>
                <input
                  type="text"
                  className={styles[invalidFields.name ? 'input-error' : '']}
                  placeholder={'Full Name *'}
                  id={'fullname'}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  tabIndex={0}
                  autoComplete={'name'}
                  name={'name'}
                />
                <label htmlFor={'name'}>Full Name *</label>
                {invalidFields && invalidFields.name ? (
                  <div className={styles['warning-container']}>
                    <span>{invalidFields.name}</span>
                  </div>
                ) : null}
              </div>
              <div className={styles['input-container']}>
                <input
                  type="email"
                  className={styles[invalidFields.email ? 'input-error' : '']}
                  placeholder={'Email *'}
                  id={'email'}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  tabIndex={0}
                  autoComplete={'email'}
                  name={'email'}
                />
                <label htmlFor={'email'}>Email *</label>
                {invalidFields && invalidFields.email ? (
                  <div className={styles['warning-container']}>
                    <span>{invalidFields.email}</span>
                  </div>
                ) : null}
              </div>
              {!checkboxIsDisabled ? (
                <div className={styles['checkbox-container']}>
                  <input
                    type="checkbox"
                    id={'age'}
                    className={styles[invalidFields.age ? 'input-error' : '']}
                    checked={over21Checked}
                    onChange={e => setOver21Checked(!over21Checked)}
                  />
                  <label htmlFor={'age'} tabIndex={0} onKeyDown={handleKeyDown}>
                    I am over 21 years old.*
                  </label>
                  {invalidFields && invalidFields.age ? (
                    <div className={styles['warning-container']}>
                      <span>{invalidFields.age}</span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className={buttonContainerStyles}>
                {submitButton.isEnabled ? <Button {...submitButton} disabled={isDisabled} onClick={e => {}} /> : null}
                {buttonGroup &&
                  buttonGroup.length > 0 &&
                  buttonGroup.map((buttonProps, index) => {
                    if (buttonProps.isEnabled) {
                      return <Button key={`${buttonProps.title}_${index}`} {...buttonProps} onClick={() => handleButtonClick(buttonProps)} />
                    }
                  })}
              </div>

              {disclaimer?.html && <Paragraph text={disclaimer} justify={disclaimerIsCenterAligned ? 'center' : 'left'} fontSize={'sm'} />}
            </form>
          </div>

          <ResultModal
            handleModalClose={() => {
              setConfirmationModalIsOpen(false)
              if (redirectAfterFormSubmit) {
                router.push(redirectAfterFormSubmit)
              }
            }}
            isOpen={confirmationModalIsOpen}
            type={4}
            closeOnOutsideClick={false}
            closeBtnText={'Close'}
          >
            {confirmationModalText?.html && (
              <p style={{ fontWeight: '600', fontSize: '18px', lineHeight: '150%' }}>{parse(confirmationModalText.html)}</p>
            )}
          </ResultModal>
          {isBelowBreakpointMd && mobileBackgroundMedia ? displayBGMedia(mobileBackgroundMedia) : displayBGMedia(backgroundMedia)}
        </div>
      </div>
    </div>
  )
}

export default SignUp
