import React, { useState } from 'react'
import styles from './PlayButton.module.scss'

const PlayButton = () => {
  const [isHovered, setIsHovered] = useState(false)

  const labelText = 'play video'

  return (
    <div
      className={styles['play-button-wrapper']}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label={labelText}
      style={{ position: 'relative' }}
    >
      <svg width="48" height="48" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" fill="none">
        <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" fill="none" />
        <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke={isHovered ? 'white' : 'grey'} />
        <path
          d="M26.5 20.134C27.1667 20.5189 27.1667 21.4811 26.5 21.866L19 26.1962C18.3333 26.5811 17.5 26.0999 17.5 25.3301L17.5 16.6699C17.5 15.9001 18.3333 15.4189 19 15.8038L26.5 20.134Z"
          fill={isHovered ? 'white' : '#919191'}
        />
      </svg>
      {isHovered && <div className={styles['tooltip']}>{labelText}</div>}
      <span className={styles['visually-hidden']}>{labelText}</span>
    </div>
  )
}

export default PlayButton
