import React from 'react'
import styles from './BenefitsCard.module.scss'
import Image from 'next/image'
import { Col, Row } from 'react-grid-system'
import { Benefit, Heading } from '~elements'
import { BenefitItemType, BenefitsCardType } from '~types'

const renderBenefits = (
  benefits: Array<BenefitItemType> | [],
  handleModalEvent: (status: boolean, benefitCardIndex: number, index: number) => void,
  benefitCardIndex: number,
  sectionTitle: string
): JSX.Element[] | null => {
  if (!Array.isArray(benefits)) return null

  return benefits.map((benefit: BenefitItemType, index: number) => {
    const { title } = benefit

    return (
      <Benefit
        benefitCardIndex={benefitCardIndex}
        title={title}
        index={index}
        handleModalEvent={handleModalEvent}
        key={index}
        sectionTitle={sectionTitle}
      />
    )
  })
}

const BenefitsCard = ({ benefitCardIndex, icon, title: sectionTitle, items, handleModalEvent }: BenefitsCardType) => {
  return (
    <Col xxl={4} lg={6} className={styles['benefits-card-container']}>
      <div className={styles['benefits-card-inner']}>
        <Row>
          <Col className="benefits-card-icon-container">
            {icon.fileName && <Image alt={`${sectionTitle} icon`} style={{ height: 'auto' }} src={icon.url} width={85} height={85} />}
          </Col>
          <Col className="align-contents-vertical">
            <Heading level={4}>
              <>{sectionTitle}</>
            </Heading>
          </Col>
        </Row>
        <ul>{renderBenefits(items, handleModalEvent, benefitCardIndex, sectionTitle)}</ul>
      </div>
    </Col>
  )
}

export default BenefitsCard
