import parse from 'html-react-parser'
import styles from './Quote.module.scss'
import Heading from '../Heading/Heading'

interface QuoteProps {
  classification: {
    quoteTitle: string
    quoteDescription: {
      html: string
    }
    quoteSubHeadline: string
    quoteCopy: {
      html: string
    }
  }
}

const Quote = ({ classification }: QuoteProps) => (
  <div className={styles['quote-container']}>
    <blockquote>
      <Heading level={4} levelDisplay="eyebrow" color="accent">
        <span>{classification?.quoteTitle}</span>
        {classification?.quoteDescription?.html && parse(classification?.quoteDescription?.html)}
      </Heading>
      <div className={styles['sub-headline']}>
        <Heading level={2} levelDisplay="eyebrow" color="accent">
          {classification?.quoteSubHeadline}
        </Heading>
        {classification?.quoteCopy?.html && parse(classification?.quoteCopy?.html)}
      </div>
    </blockquote>
  </div>
)

export default Quote
