import { Fragment } from 'react'
import cx from 'classnames'
import moment from 'moment-timezone'

import TileViewEventDay from '../TileViewEventDay/TileViewEventDay'
import { GridViewEventDayMonthsProps } from 'types/calendar'

import styles from '../../../ShowsCalendar.module.scss'
import { DATE_FORMAT } from '~constants'

const TileViewEventDayMonths = ({ filteredCalendarData, selectedMonth, handleDateSelection, artistDetails }: GridViewEventDayMonthsProps) => {
  const [filteredMonth, filteredYear] = selectedMonth.value.split('-')
  const monthIndex = +moment().month(filteredMonth).format('M')
  const calendarData =
    filteredCalendarData && filteredCalendarData[filteredYear] && filteredCalendarData[filteredYear][monthIndex]
      ? Object.values(filteredCalendarData[filteredYear][monthIndex])
      : null

  const monthStart = moment(`${filteredYear}-${filteredMonth}`, 'YYYY-MMM').clone().startOf('month')
  const monthEnd = moment(`${filteredYear}-${filteredMonth}`, 'YYYY-MMM').clone().endOf('month')

  let day = monthStart
  let formattedDate = ''
  let calendarListItems = []
  while (day <= monthEnd) {
    const cloneDay = day.format(DATE_FORMAT.SHORT_DATE_FORMAT)
    const [filteredDate] = calendarData
      ? calendarData.filter(({ date }) => date.valueOf() === moment.utc(cloneDay, DATE_FORMAT.SHORT_DATE_FORMAT).valueOf())
      : []

    const { artists, events }: EventDayProps = filteredDate ?? { events: [] }

    calendarListItems.push(
      <>
        {events && (
          <TileViewEventDay events={events} artists={artists} date={day} handleDateSelection={handleDateSelection} artistDetails={artistDetails} />
        )}
      </>
    )
    day = day.clone().add(1, 'day')
  }

  return (
    <>
      <div className={styles['calendar__month']}>
        <div className={styles['calendar__grid-wrapper']}>{calendarListItems}</div>
      </div>
    </>
  )
}

export default TileViewEventDayMonths
