import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

type JsonLDType = { data: Array<any> | Object }
const JsonLD = ({ data }: JsonLDType) => {
  if (!data) return null
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data)}</script>
    </Helmet>
  )
}

JsonLD.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

JsonLD.defaultProps = {
  data: null
}

export default JsonLD
