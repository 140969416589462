import moment from 'moment'
import { DATE_FORMAT, PST_TZ } from '~constants'
import { EventProps } from '~types'
import { listeners } from '../Calendar/calendarFunctions'
import { EventDayProps, ListViewCalendarDataProps } from 'types/calendar'
import { MomentInput } from 'moment-timezone'

const getNextActiveEventDate = (eventDateResults: Array<EventDayProps>) => {
  const eventsMap = eventDateResults.map(eventDate => [...eventDate.events])
  const allEvents = eventsMap.reduce((a, b) => a.concat(b), [])
  const currentUTCTimestamp = moment.utc().valueOf()
  const [nextEventDateObj] = allEvents.filter(({ date_time }) => {
    return currentUTCTimestamp < date_time
  }) || [{}]
  return nextEventDateObj
}

const transformAllEventsResponse = (results: Array<EventDayProps>) => {
  const data: ListViewCalendarDataProps = {}
  results.forEach(result => {
    const dateValue = result.date
    const localeTimestamp = moment.utc(dateValue)
    const year = +localeTimestamp.format(DATE_FORMAT.YEAR)
    const monthIndex = +localeTimestamp.format(DATE_FORMAT.SHORT_MM)
    const dayOfMonth = +localeTimestamp.format(DATE_FORMAT.SHORT_DATE)

    if (!data[year]) data[year] = {}
    if (!data[year][monthIndex]) data[year][monthIndex] = {}

    const eventsList = result.events ? sortEvents(result?.events) : []

    data[year][monthIndex][dayOfMonth] = {
      date: localeTimestamp,
      dayOfMonth,
      events: eventsList,
      monthIndex,
      price: result.price,
      timestamp: result.date,
      year,
      artists: result.artists
    }
  })
  return data
}

const transformEventsResponse = (calendarList: Array<EventDayProps>) => {
  const data = calendarList.map(event => {
    const sortedEvents = event?.events && event?.events.length > 0 ? sortEvents(event?.events) : []
    return {
      ...event,
      events: sortedEvents,
      eventsCount: sortedEvents?.length
    }
  })
  return {
    data
  }
}

export const sortEvents = (events: Array<EventProps>) => {
  return events.sort(
    (a, b) =>
      Date.parse('1970/01/01 ' + a?.formatted_start_time?.slice(0, -2) + ' ' + a?.formatted_start_time?.slice(-2)) -
      Date.parse('1970/01/01 ' + b?.formatted_start_time?.slice(0, -2) + ' ' + b?.formatted_start_time?.slice(-2))
  )
}

export const fetchShowsCalendarData = async (eventEngineApiUrl: string, eventEngineApiToken: string, startDate: MomentInput) => {
  const currentStartDate = startDate ? moment(startDate).startOf('month').startOf('day') : moment.tz(PST_TZ).startOf('day')
  const calendarStartDate = currentStartDate.format('MMDDYYYY')

  // Check if current date is in the last week of the month
  const isLastWeekOfMonth = currentStartDate.isSame(currentStartDate.clone().endOf('month'), 'week')
  let eventEngineAPIRequestUrl = `${eventEngineApiUrl}`

  if (isLastWeekOfMonth) {
    const endOfCurrentMonth = currentStartDate.clone().endOf('month')
    let nextMonthEndDate = endOfCurrentMonth.clone().add(1, 'day').endOf('week').format('MMDDYYYY')

    const endOfMonthDay = endOfCurrentMonth.day()
    if (endOfMonthDay >= 0 && endOfMonthDay <= 2) {
      // Sunday, Monday, Tuesday
      nextMonthEndDate = endOfCurrentMonth
        .clone()
        .add(6 - endOfMonthDay, 'days')
        .format('MMDDYYYY')
    } else {
      // Wednesday, Thursday, Friday, Saturday
      nextMonthEndDate = endOfCurrentMonth
        .clone()
        .add(7 + (6 - endOfMonthDay), 'days')
        .format('MMDDYYYY')
    }

    eventEngineAPIRequestUrl += `&start_date=${calendarStartDate}&end_date=${nextMonthEndDate}`
  } else {
    const calendarEndDate = currentStartDate.clone().endOf('month').format('MMDDYYYY')
    eventEngineAPIRequestUrl += `&start_date=${calendarStartDate}&end_date=${calendarEndDate}`
  }

  const result = await fetch(`${eventEngineAPIRequestUrl}`, {
    headers: {
      Authorization: `Bearer ${eventEngineApiToken}`
    }
  })
  const { results, meta } = await result.json()

  if (results.length === 0) {
    return null
  }

  const { calendarData } = listeners.processCalendarData(results)
  const calendarFilterItems: any = listeners.processMonthNavigation(calendarData, false)
  const [selectedMonth] = calendarFilterItems

  const calendarList: Array<EventDayProps> = Object.values(results)
  const seasonStartDate = Math.min(...calendarList.map(eventDate => eventDate.date))
  const seasonEndDate = Math.max(...calendarList.map(eventDate => eventDate.date))
  const nextEventDateObj = getNextActiveEventDate(calendarList)
  const nextActiveEvent = nextEventDateObj ? nextEventDateObj.id : null
  const noOfMonths = Math.round(moment(seasonEndDate).endOf('month').diff(moment(seasonStartDate).startOf('month'), 'months', true))
  const { data } = transformEventsResponse(calendarList)
  return {
    calendarList: data,
    seasonStartDate: moment(seasonStartDate).utc().format(DATE_FORMAT.SHORT_DATE_FORMAT),
    seasonEndDate: moment(seasonEndDate).utc().format(DATE_FORMAT.SHORT_DATE_FORMAT),
    nextActiveEvent,
    noOfMonths,
    calendarData,
    calendarFilterItems,
    selectedMonth,
    meta
  }
}

export const getFilteredCalendarData = (allEvents: Array<EventDayProps>) => {
  const calendarDataList = transformAllEventsResponse(allEvents)

  return {
    calendarDataList
  }
}
