import React, { useRef, useState, useEffect } from 'react'
import { mobileAssetHeight, HeroEDPHeaderV2Props } from '../types'
import styles from './HeroEdpHeaderMobile.module.scss'
import { Button, Heading, Paragraph } from '~elements'
import { Col, Container, Row } from 'react-grid-system'
import cx from 'classnames'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'

const VIDEO_PLAYBACK_MODE = {
  SUCCESS: 'success',
  FAILED: 'failed'
}
const HeroEdpHeaderMobile = ({
  cta,
  description,
  eyebrow,
  backgroundAsset,
  infiniteVideoLoop = false,
  headline,
  mobileAssetHeight,
  eyebrowColor,
  showVideoOnMobile,
  isButtonVisible,
  isDescriptionVisible,
  isEyebrowVisbile,
  isHeadlineVisible
}: HeroEDPHeaderV2Props) => {
  const edpHeroVideoRef = useRef<HTMLVideoElement>(null)
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(true)
  const [videoPlaybackMode, setVideoPlaybackMode] = useState<string>('')
  const heightClassMap: { [key in mobileAssetHeight]: string } = {
    vh33: styles['hero-container__background-height--third'],
    vh50: styles['hero-container__background-height--half'],
    vh100: styles['hero-container__background-height--full']
  }
  const backgroundCheckForNone = mobileAssetHeight == 'vh100' ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 47.56%)' : 'black'
  useEffect(() => {
    if (edpHeroVideoRef && edpHeroVideoRef.current) {
      edpHeroVideoRef.current
        .play()
        .then(() => {
          if (edpHeroVideoRef.current) {
            edpHeroVideoRef.current.poster = ''
          }
          setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)
        })
        .catch(() => {
          // Automatic playback failed
          setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.FAILED)
        })
    }
  }, [])
  const handleVideoEnd = () => {
    setVideoIsPlaying(false)
  }
  const handleVideoPlay = () => {
    setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)
  }
  const handlePlaying = () => {
    setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)
  }

  const { query } = useRouter()
  const [source] = [...(query.slug as string[])].reverse()

  const handleButtonClick = (buttonProps: ButtonProps, isScrollIntoView: boolean) => {
    if (isScrollIntoView) {
      const targetElement = buttonProps.anchorLink ? document.getElementById(buttonProps.anchorLink) : null
      if (buttonProps && buttonProps.anchorLink && targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
    gaBuyTicketsClick({
      buyType: buttonProps.buyType,
      clickSource: source ?? '',
      clickTracking: `${window.location.href} hero_edp_header:buy_tickets`,
      eventDate: '',
      eventVenueName: 'sphere_vegas',
      eventCategory: 'other',
      eventType: 'other events',
      eventDataStatus: '',
      linkLocation: HeroEdpHeaderMobile.displayName,
      linkPosition: '',
      ticketmasterEventId: '',
      cmsIdentifier: buttonProps.cmsIdentifier,
      buttonPlacementDescription: buttonProps.buttonPlacementDescription
    })
  }
  return (
    <>
      <div className={cx([styles['hero-container'], styles['has-details']], heightClassMap[mobileAssetHeight])}>
        <div className={`${styles['hero']} `} style={{ background: backgroundCheckForNone }}>
          <Container>
            <Row>
              <Col className={'margin-top-auto'}>
                <div className={styles['header-title-container']}>
                  <div>
                    {eyebrow && isEyebrowVisbile && (
                      <span className={styles['title-container-eyebrow']}>
                        <Heading level={2} levelDisplay="eyebrow" color={eyebrowColor}>
                          {eyebrow}
                        </Heading>
                      </span>
                    )}
                    {headline && isHeadlineVisible && (
                      <span className={styles['title-container-headline']}>
                        <Heading level={1}>{headline}</Heading>
                      </span>
                    )}
                  </div>
                </div>
                {description && isDescriptionVisible && (
                  <div className={styles['description-container']}>
                    <Paragraph text={description} />
                  </div>
                )}
                {cta && isButtonVisible && (
                  <div className={styles['btn-container']}>
                    {cta.length === 3 ? (
                      <>
                        {/* First button with size large */}
                        <Button
                          key={cta[0].title}
                          color={cta[0].bgColor}
                          fill={cta[0].fill}
                          size={cta[0].size}
                          title={cta[0].title}
                          {...(cta[0].anchorLink
                            ? { onClick: () => handleButtonClick(cta[0], true) }
                            : { ...cta[0], onClick: () => handleButtonClick(cta[0], false) })}
                        />
                        {/* Second and third buttons side by side */}
                        <div className={styles['small-buttons-container']}>
                          {cta.slice(1, 3).map((buttonProps: ButtonProps, index: number) => (
                            <Button
                              key={`${buttonProps.title}_${index}`}
                              {...buttonProps}
                              color={buttonProps.bgColor}
                              fill={buttonProps.fill}
                              size={buttonProps.size}
                              title={buttonProps.title}
                              {...(buttonProps.anchorLink
                                ? { onClick: () => handleButtonClick(buttonProps, true) }
                                : { ...buttonProps, onClick: () => handleButtonClick(buttonProps, false) })}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className={styles['two-or-fewer']}>
                        {cta.map((buttonProps: ButtonProps, index: number) => (
                          <Button
                            key={`${buttonProps.title}_${index}`}
                            {...buttonProps}
                            color={buttonProps.bgColor}
                            fill={buttonProps.fill}
                            size={buttonProps.size}
                            title={buttonProps.title}
                            {...(buttonProps.anchorLink
                              ? { onClick: () => handleButtonClick(buttonProps, true) }
                              : { ...buttonProps, onClick: () => handleButtonClick(buttonProps, false) })}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {backgroundAsset?.video && showVideoOnMobile && (
          <div className={`${heightClassMap[mobileAssetHeight]} ${styles['hero-container__bg-video']}`}>
            <video
              loop={infiniteVideoLoop}
              ref={edpHeroVideoRef}
              onEnded={handleVideoEnd}
              onPlay={handleVideoPlay}
              onTimeUpdate={handlePlaying}
              autoPlay={true}
              muted
              playsInline={true}
              poster={backgroundAsset.video.posterImage?.[0]?.url}
              {...(videoPlaybackMode === VIDEO_PLAYBACK_MODE.FAILED ? {} : { className: styles['video-without-pointer-event'] })}
            >
              <source src={backgroundAsset.video.video?.[0]?.url} type="video/mp4" />
            </video>
          </div>
        )}
        {backgroundAsset?.image && backgroundAsset?.image?.url && !showVideoOnMobile && (
          <div
            className={`${heightClassMap[mobileAssetHeight]} ${styles['hero-container__bg-img']}`}
            style={{ backgroundImage: `url(${backgroundAsset?.image?.url})` }}
          />
        )}
      </div>
    </>
  )
}
HeroEdpHeaderMobile.displayName = 'hero_edp_header'
export default HeroEdpHeaderMobile
