interface HamburgerButtonProps {
  onClick: () => void
}

const HamburgerButton = ({ onClick }: HamburgerButtonProps) => {
  const labelText = 'menu'

  return (
    <div className="menu-button-wrapper" aria-label={labelText} style={{ position: 'relative' }} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" style={{ display: 'block', margin: 'auto' }}>
        <rect x="8" y="7" width="8" height="1" rx="0.5" fill="white" />
        <rect x="4" y="12" width="16" height="1" rx="0.5" fill="white" />
        <rect x="8" y="17" width="8" height="1" rx="0.5" fill="white" />
      </svg>
    </div>
  )
}

export default HamburgerButton
