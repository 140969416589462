import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isProd } from '~constants'

export const useGa = () => {
  const [isReady, setIsReady] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    const isGaReady = setInterval(() => {
      if (typeof window.ga === 'function') {
        clearInterval(isGaReady)
        setIsReady(true)
      }
    }, 100)
  }, [])

  const sendEvent = (eventName: string) => {
    if (isReady && isProd) {
      console.debug(`Sending ${eventName} - slug: ${router.asPath}`)
      window.dataLayer.push({ event: 'pageview', slug: router.asPath })
      return
    }

    console.debug(`[NONPROD] - We would be sending custom event: ${eventName}`)
  }

  return {
    isReady,
    sendEvent
  }
}
