import React from 'react'
import parse from 'html-react-parser'
import { Container } from 'react-grid-system'
import styles from './Disclaimer.module.scss'

interface DisclaimerProps {
  copy: { html: string }
}
const Disclaimer = ({ copy }: DisclaimerProps) => {
  const htmlParseVal = parse(copy.html)
  return (
    <Container className={styles['disclaimer-container']}>
      <span className={styles['disclaimer']}>{htmlParseVal}</span>
    </Container>
  )
}

export default Disclaimer
