import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import parse from 'html-react-parser'
import { Heading, Paragraph } from '~elements'
import { Container } from 'react-grid-system'
import styles from './LostandFound.module.scss'
import { useRouter } from 'next/router'
import Head from 'next/head'

interface LostandFoundProps {
  description: { html: string }
  headline: string
  lostandfoundData: string
}

const LostandFound = ({ description, headline, lostandfoundData }: LostandFoundProps) => {
  return (
    <Container className="container">
      <div className={styles.lostandfound}>
        <div className={cx([styles.card, styles['col-wrapper--content']])}>
          {headline && (
            <Heading level={1}>
              <>{headline}</>
            </Heading>
          )}
          {description && description?.html && (
            <div className={styles['description-container']}>
              <Paragraph text={description} fontSize={'md'} />
            </div>
          )}
        </div>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: lostandfoundData }} />
      </div>
    </Container>
  )
}

export default LostandFound
