import React, { useEffect, useRef, useState } from 'react'
import { NavigationHeader, NotificationBanner } from '~modules'
import { NavigationHeaderProps, NotificationBannerProps } from '~types'
import styles from './CombinationHeader.module.scss'
import cx from 'classnames'

interface CombinationHeaderProps {
  navigationHeader: NavigationHeaderProps
  notificationBanner: NotificationBannerProps
}

const CombinationHeader = ({ navigationHeader, notificationBanner }: CombinationHeaderProps) => {
  const bannerRef = useRef<HTMLDivElement | null>(null)
  const navHeaderRef = useRef<HTMLDivElement | null>(null)
  const [bannerHeight, setBannerHeight] = useState(0)

  useEffect(() => {
    const adjustHeaderOffset = () => {
      if (bannerRef.current) {
        setBannerHeight(bannerRef.current.offsetHeight)
      }
    }

    adjustHeaderOffset()
    window.addEventListener('resize', adjustHeaderOffset)

    return () => {
      window.removeEventListener('resize', adjustHeaderOffset)
    }
  }, [notificationBanner])

  useEffect(() => {
    if (navHeaderRef.current) {
      navHeaderRef.current.style.marginTop = `${bannerHeight}px`
    }
  }, [bannerHeight])

  return (
    <div className={styles['combination-header__container']}>
      {notificationBanner && notificationBanner.showBanner && (
        <div ref={bannerRef} className={styles['notification-banner']}>
          <NotificationBanner copy={notificationBanner.copy} ctaPrimary={notificationBanner.ctaPrimary} showBanner={notificationBanner.showBanner} />
        </div>
      )}
      <div
        ref={navHeaderRef}
        className={cx(styles['navigation-header-global'], {
          [styles['with-banner']]: notificationBanner && notificationBanner.showBanner
        })}
      >
        <NavigationHeader
          brandLogo={navigationHeader.brandLogo}
          bannerRef={bannerRef}
          navigationMenuItems={navigationHeader.navigationMenuItems}
          buttonTitle={navigationHeader.buttonTitle}
          buttonUrl={navigationHeader.buttonUrl}
          isTargetBlank={navigationHeader.isTargetBlank}
          endNavItem={navigationHeader.endNavItem}
          endNavItemUrl={navigationHeader.endNavItemUrl}
        />
      </div>
    </div>
  )
}

export default CombinationHeader
