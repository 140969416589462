import React from 'react'
import cx from 'classnames'
import styles from './MediaLegend.module.scss'
import { Heading, Paragraph } from '~elements'

export interface MediaLegendProps {
  eyebrow?: string
  title?: string
  subTitle?: string
}

const MediaLegend = ({ eyebrow = '', title = '', subTitle = '' }: MediaLegendProps) => {
  return (
    <legend className={styles['media-legend']}>
      <>
        {eyebrow && <Heading level={6}>{eyebrow}</Heading>}
        {title && <Heading level={4}>{title}</Heading>}
        {subTitle && <p>{subTitle}</p>}
      </>
    </legend>
  )
}

export default MediaLegend
