import styles from './NextButton.module.scss'
interface INextButtonProps {
  disabled: boolean
  clickCallback: Function
}

export default function NextButton({ disabled, clickCallback }: INextButtonProps) {
  return (
    <button className={styles['next-button']} disabled={disabled} onClick={() => clickCallback()}>
      <svg width="32" height="32" viewBox="0 0 32 32">
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="black" fill-opacity="0.1" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" data-circle="true" />
        <path d="M18.3704 16.0109L13 10.6187L13.6155 10L19.6046 16.0109L13.6155 22L13 21.3813L18.3704 16.0109Z" />
      </svg>
    </button>
  )
}
