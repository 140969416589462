import React, { Suspense, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { PreviewAlert } from '../../elements'
import { fireAnalytics, getPageType } from '~analyticsUtils'
import styles from './Page.module.scss'
import { ANALYTICS_EVENT_PAGE_LOAD } from '~analyticsConstants'

interface BlokInterface {
  body: Array<object>
}

interface PageProps {
  blok: BlokInterface
  components: any
  preview: boolean
  constantsMap: object
  lostandfoundData: string
}

const Page = ({ blok, components, preview, lostandfoundData }: PageProps) => {
  const { body = {} } = blok
  const { blocks = [], slug = '', constantsMap } = body
  const { asPath } = useRouter()
  const [interactive, setInteractive] = useState(false)
  const [isStickyDisclaimerOpen, setIsStickyDisclaimerOpen] = useState(true)

  useEffect(() => {
    fireAnalytics(ANALYTICS_EVENT_PAGE_LOAD, getPageType(asPath))
  }, [slug])

  const handleInteractiveOverlayCardLoad = value => {
    setInteractive(value)
  }
  const handleStickyDisclaimerCloseClick = value => {
    setIsStickyDisclaimerOpen(!value)
  }

  const componentsList = blocks.map((block, index) => {
    const { componentName } = block
    const DynamicComponent = components[componentName]
    const componentProps = {
      ...block
    }

    if (DynamicComponent) {
      return (
        <DynamicComponent
          key={index}
          {...componentProps}
          constantsMap={constantsMap}
          lostandfoundData={lostandfoundData}
          handleInteractiveOverlayCardLoad={value => handleInteractiveOverlayCardLoad(value)}
          handleStickyDisclaimerCloseClick={value => handleStickyDisclaimerCloseClick(value)}
          interactive={interactive}
          isStickyDisclaimerOpen={isStickyDisclaimerOpen}
          businessUnit={body.businessUnit ? body.businessUnit : ''}
        />
      )
    }
  })

  return (
    <main>
      <Suspense fallback={<div>Loading...</div>}>{componentsList}</Suspense>
      {preview && (
        <div className={styles.previewAlertContainer}>
          <PreviewAlert />
        </div>
      )}
    </main>
  )
}

export default Page
