import React, { Component } from 'react'
import Link from 'next/link'

const PreviewAlert = () => {
  return (
    <>
      This page is a preview.{' '}
      {/* This is not recommended by NextJS. We should use next/link component. Link component is prefetching the exit-preview link even if we set `prefetch={false}` which removes the preivew mode cookies immediately.  */}
      <a href="/api/exit-preview">Click Here</a>
      to exit preview mode.
    </>
  )
}

export default PreviewAlert
