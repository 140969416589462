import React, { useEffect, useRef, useState, TouchEvent } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import cx from 'classnames'
import styles from './HeroFullScreen.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Heading, Pill } from '~elements'
import { Container } from 'react-grid-system'
import Slider from 'react-slick'
import { HOME_URL } from '~constants'
import { HeroFullScreenProps } from '~types'

interface SliderInterface {
  slickNext: () => void
  slickPrev: () => void
}

//arbitrary value on how much distance user must swipe for the next/prev slide.
//the larger the number, the greater distance a user must swipe for the next/prev slide and vice versa.
//only applicable on touch events on mobile, not mouse
const swipeDistanceLimit = 50

const HeroFullScreen = ({ backgroundImage, carouselSlides, subTitle, textLogo, title }: HeroFullScreenProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)
  //const [displayReadMore, setDisplayReadMore] = useState(true)
  const [scrollYPosition, setScrollPosition] = useState<number>(0)
  const [onHomePage, setOnHomePage] = useState<boolean | null>(null)
  const { asPath } = useRouter()
  const sliderRef = useRef<SliderInterface | null>(null)
  const [touchX, setTouchX] = useState<number | null>(null)
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(null)

  const carouselSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    infinite: true,
    dots: false,
    fade: true,
    slidesToShow: 1,
    slideToScroll: 1,
    //Set to false if we want users to not be able to scroll in Slider component
    //swipe: false,
    // TODO
    // speed: 7000,
    beforeChange: (current: number, next: number) => {
      setActiveSlideIndex(next)
    }
  }

  useEffect(() => {
    setOnHomePage(asPath === HOME_URL)
  }, [asPath])

  // useEffect(() => {
  //  @TODO: Somehow incorporate threshold value to toggle "MORE" indicator
  //   const handleReadMoreDisplay = (e: Event) => {
  //     setDisplayReadMore(e.type !== SCROLL_ANIMATING.START)
  //   }

  //   window.addEventListener(SCROLL_ANIMATING.START, handleReadMoreDisplay)
  //   window.addEventListener(SCROLL_ANIMATING.STOP, handleReadMoreDisplay)

  //   return () => {
  //     window.removeEventListener(SCROLL_ANIMATING.START, handleReadMoreDisplay)
  //     window.removeEventListener(SCROLL_ANIMATING.STOP, handleReadMoreDisplay)
  //   }
  // }, [])

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset
      setScrollPosition(position)
    }
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const prevSlideClickHandler = () => {
    const newIdx = activeSlideIndex === 0 ? carouselSlides.length - 1 : activeSlideIndex - 1
    setActiveSlideIndex(newIdx)
    sliderRef?.current?.slickPrev()
  }

  const nextSlideClickHandler = () => {
    const newIdx = activeSlideIndex === carouselSlides.length - 1 ? 0 : activeSlideIndex + 1
    setActiveSlideIndex(newIdx)
    sliderRef?.current?.slickNext()
  }

  const touchStartHandler = (e: TouchEvent<HTMLDivElement>) => {
    setTouchX(e.touches[0].clientX)
  }

  const touchMoveHandler = (e: TouchEvent<HTMLDivElement>) => {
    if (touchX && carouselSlides.length > 1) {
      const xPosition = e.touches[0].clientX
      const distance = Math.abs(touchX - xPosition)
      if (distance > swipeDistanceLimit) {
        if (touchX > xPosition) {
          setSwipeDirection('left')
        } else {
          setSwipeDirection('right')
        }
      }
    }
  }

  const touchEndHandler = () => {
    if (touchX && carouselSlides.length > 1) {
      if (swipeDirection === 'right') {
        prevSlideClickHandler()
      } else if (swipeDirection === 'left') {
        nextSlideClickHandler()
      }
    }
    setTouchX(null)
    setSwipeDirection(null)
  }

  return (
    <div
      className={styles['hero-full-screen']}
      style={{
        backgroundImage: `url('${backgroundImage.url}')`
      }}
    >
      <div className={styles.content}>
        <Container>
          <div className={styles.headings}>
            <Heading level={1}>
              <>{title}</>
            </Heading>
            {subTitle && (
              <Heading level={4}>
                <>{subTitle}</>
              </Heading>
            )}
          </div>
          <div className={styles['brand-logo-xl']}>
            <div className={'image-container'}>
              <Image src={textLogo.url} alt={textLogo.fileName} width="0" height="0" sizes="100vw" priority />
            </div>
          </div>
          <div className={styles['pill-container']} onTouchStart={touchStartHandler} onTouchMove={touchMoveHandler} onTouchEnd={touchEndHandler}>
            {carouselSlides.length > 1 && (
              <div className={cx([styles['slick-arrow-custom'], styles['slick-arrow-custom--prev']])} onClick={prevSlideClickHandler}>
                <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
              </div>
            )}
            {carouselSlides.length > 0 && <Pill {...carouselSlides[activeSlideIndex].pill} />}
            {carouselSlides.length > 1 && (
              <div className={cx([styles['slick-arrow-custom'], styles['slick-arrow-custom--next']])} onClick={nextSlideClickHandler}>
                <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
              </div>
            )}
          </div>
          {/* Uncomment incase we need the dots, would still need to pass the new index to the Slider component */}
          {/* <div className={'slick-dots'}>
            {carouselSlides.length > 1 && (
              <ul>
                {carouselSlides.map((slide, index) => (
                  <li className={activeSlideIndex == index ? 'slick-active' : ''}>
                    <button onClick={()=> setActiveSlideIndex(index)}/>
                  </li>
                ))}  
              </ul>
            )}
          </div> */}

          {/** targetVersion: v2.0.0 */}
          {/* {onHomePage && scrollYPosition < 12 && (
            <div className={styles['scroll-indicator']}>
              <p>
                More <div className={styles['caret']} />
              </p>
            </div>
          )} */}
        </Container>
      </div>

      <Slider ref={sliderRef} {...carouselSettings}>
        {carouselSlides.map(slide => (
          <div key={carouselSlides.indexOf(slide)}>
            {slide.img && (
              <div
                className={styles['slide']}
                key={carouselSlides.indexOf(slide)}
                style={{
                  backgroundImage: `url(${typeof slide.img === null ? '' : slide.img.image.url})`
                }}
              />
            )}
          </div>
        ))}
      </Slider>

      <div className={styles['bottom-slogan']}>The Experience Is Just Getting Started.</div>
    </div>
  )
}

export default HeroFullScreen
