import { isProd } from '~constants'

export const gaBtnClick = (clickEvent: string, ctaLocation: string, ctaAction: string) => {
  if (typeof window !== 'undefined' && isProd) {
    // @ts-ignore
    window.dataLayer.push({ event: clickEvent, ctaLocation, ctaAction })
  } else {
    console.debug(`Sending ${clickEvent} at ${ctaLocation} on ${ctaAction}`)
  }
}

interface IBuyTicketParams {
  businessUnit?: string
  buyType?: string
  clickSource?: string
  clickTracking?: string
  eventDate?: string
  eventCategory?: string
  eventVenueName?: string
  eventType?: string
  eventDataStatus?: string
  linkLocation?: string
  linkPosition?: number | string
  ticketmasterEventId?: string
  cmsIdentifier?: string
  buttonPlacementDescription?: string
}

export const gaBuyTicketsClick = (params: IBuyTicketParams) => {
  const event = 'click_buy'
  const brandName = 'sphere_vegas'
  if (typeof window !== 'undefined' && isProd) {
    // @ts-ignore
    window.dataLayer.push({
      event,
      brand_name: brandName,
      business_unit: params.businessUnit,
      buy_type: params.buyType ?? '',
      click_source: params.clickSource,
      click_tracking: params.clickTracking,
      event_date: params.eventDate,
      event_category: params.eventCategory,
      event_venue_name: params.eventVenueName,
      event_type: params.eventType,
      event_data_status: params.eventDataStatus,
      link_location: params.linkLocation,
      link_position: params.linkPosition,
      ticketmaster_event_id: params.ticketmasterEventId,
      cms_identifier: params.cmsIdentifier ?? '',
      button_placement_description: params.buttonPlacementDescription ?? ''
    })
  } else {
    console.debug(
      `Event: ${event}, Brand Name: ${brandName}, Business Unit: ${params.businessUnit}, Buy Type: ${params.buyType},
      Click Source: ${params.clickSource}, Click Tracking: ${params.clickTracking}, Event Date: ${params.eventDate}, Event Category: ${params.eventCategory}, 
      Event Venue Name: ${params.eventVenueName}, Event Type: ${params.eventType}, Event Data Status: ${params.eventDataStatus}, Link Position: ${params.linkPosition}, 
      Ticket Master Event Data: ${params.ticketmasterEventId}, CMS Identifier: ${params.cmsIdentifier}, Button Placement Description: ${params.buttonPlacementDescription}`
    )
  }
}
