import React from 'react'
import cx from 'classnames'
import { Col, Container, Row } from 'react-grid-system'

import { ButtonProps } from '~types'
import { Card, Divider } from '~elements'

import styles from './CardOverlay.module.scss'

interface CardProps {
  backgroundImage: { url: string }
  cta: ButtonProps
  description: { html: string }
  headline: string
  icon: { url: string }
}

interface CardOverlayProps {
  cards: Array<CardProps>
}

const CardOverlay = ({ cards }: CardOverlayProps) => (
  <>
    {cards && cards.length > 0 && (
      <Container className="container">
        <div className={cx([styles['card-overlay-container']])}>
          <div className={styles['card-overlay']}>
            <Col sm={12}>
              <Row>
                {cards.map((card, index) => (
                  <Col lg={6} key={index}>
                    <div className={styles['col-wrapper--media']}>
                      <Card {...card} />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </div>
        </div>
      </Container>
    )}
    <Divider />
  </>
)

export default CardOverlay
