import React from 'react'
import styles from './Benefit.module.scss'
import { BenefitProps } from '~types'
import { Button } from '~elements'
import { formatAnalyticsText } from '~analyticsUtils'
import { ANALYTICS_LINK_LOCATION_BODY, ANALYTICS_LINK_MODULE_GLOBAL_NAV } from '~analyticsConstants'

const Benefit = ({ benefitCardIndex, index, title, handleModalEvent, sectionTitle }: BenefitProps) => {
  const linkId = `benefits:${formatAnalyticsText(sectionTitle)}:${formatAnalyticsText(title)}`

  return (
    <li
      className={styles['benefit-item']}
      key={index}
      linkModule={ANALYTICS_LINK_MODULE_GLOBAL_NAV}
      linkLocation={ANALYTICS_LINK_LOCATION_BODY}
      linkId={linkId}
      onClick={() => handleModalEvent(true, benefitCardIndex, index)}
    >
      {title}
    </li>
  )
}

export default Benefit
