import React, { useCallback, useEffect, useState } from 'react'
import { CalendarProps, CalendarNavProps, MonthProps, EventProps } from '~types'
import { Button, Heading, PortalModal } from '~elements'
import { Container } from 'react-grid-system'
import CalendarFilter from './CalendarFilter/CalendarFilter'
import { listeners } from './calendarFunctions'
import styles from './Calendar.module.scss'
import CalendarModalContent from './CalendarModalContent/CalendarModalContent'
import EventsList from './EventsList/EventsList'
import { useRouter } from 'next/router'

const Calendar = ({
  authorizationToken,
  calendarId,
  confirmationModalButtonCtaText,
  eventEngineApiUrl,
  eventsLimit,
  isChildOfCard = false,
  loadCalendarDataFromS3,
  numberOfMonths,
  showCalendar,
  showMonthSeparator,
  showMonthFilter,
  soldOutConfirmationModalText,
  staticCalendarDataS3Url,
  hidePricing,
  overrideApiSoldOutStatus,
  showPagination,
  heading,
  businessUnit
}: CalendarProps) => {
  const [selectedMonth, setSelectedMonth] = useState<MonthProps | object>({})
  const [calendarFilterItems, setCalendarFilterItems] = useState<Array<MonthProps>>([])
  const [filteredCalendarData, setFilteredCalendarData] = useState<Array<EventDateProps>>([])
  const [calendarData, setCalendarData] = useState<Map<string, object> | object>({})
  const [allEventsData, setAllEventsData] = useState<Map<string, object> | object>({})
  const [calendarApiResponse, setCalendarApiResponse] = useState<Array<EventDateProps>>([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [priceRange, setPriceRange] = useState({
    minPrice: 0,
    maxPrice: 0
  })
  const [showModal, setShowModal] = useState(false)
  const [modalEventInfo, setModalEventInfo] = useState<EventProps>()
  const [filteredYear, setFilteredYear] = useState<string>('')
  const { asPath } = useRouter()
  const [, hash] = (asPath as string).split('#')

  useEffect(() => {
    const fetchData = async () => {
      const { calendarData, allEvents, calendarFilterItems, selectedMonth }: CalendarNavProps = await listeners.fetchCalendarData(
        eventEngineApiUrl,
        loadCalendarDataFromS3,
        staticCalendarDataS3Url,
        authorizationToken,
        numberOfMonths
      )
      setAllEventsData(allEventsData)
      setCalendarData(calendarData)
      setCalendarFilterItems(calendarFilterItems)
      setSelectedMonth(selectedMonth)
      setCalendarApiResponse(allEvents)
    }
    if (staticCalendarDataS3Url || eventEngineApiUrl) {
      fetchData()
    }
  }, [staticCalendarDataS3Url, eventEngineApiUrl])

  useEffect(() => {
    if (selectedMonth && selectedMonth.label) {
      const { priceRange, currentPageIndex, calendarDataList, calendarYear, totalPagesCount } = listeners.getFilteredCalendarData(
        calendarFilterItems,
        selectedMonth,
        calendarApiResponse,
        currentPage,
        eventsLimit
      )
      setFilteredCalendarData(calendarDataList)
      setPriceRange(priceRange)
      setCurrentPage(currentPageIndex)
      setPageCount(totalPagesCount)
      setFilteredYear(calendarYear)
    }
  }, [selectedMonth, calendarData, calendarFilterItems, eventsLimit, currentPage])

  const handleMonthNavChange = (value: MonthProps) => {
    setSelectedMonth(value)
    setCurrentPage(1)
  }

  const handleBtnOnClick = (eventInfo: EventProps) => {
    setModalEventInfo(eventInfo)
    setShowModal(true)
  }

  const handlePageChange = () => {
    setCurrentPage(currentPage + 1)
  }

  const onCloseModalHandler = useCallback(() => setShowModal(false), [])
  useEffect(() => {
    if (hash && hash === calendarId) {
      const timeoutId = setTimeout(() => {
        const targetElement = document.getElementById(calendarId)
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
      return () => clearTimeout(timeoutId)
    }
  }, [hash, calendarId])

  return (
    <>
      {showCalendar && (
        <Container className={`container ${isChildOfCard ? 'container--width-100' : ''}`}>
          <div className={styles.calendar} id={calendarId}>
            {heading && (
              <Heading level={2}>
                <>{heading}</>
              </Heading>
            )}
            {selectedMonth && filteredYear && showMonthFilter && calendarFilterItems.length > 0 && (
              <CalendarFilter
                calendarFilterItems={calendarFilterItems}
                handleMonthNavChange={handleMonthNavChange}
                priceRange={priceRange}
                selectedMonth={selectedMonth}
                filteredYear={filteredYear}
              />
            )}
            <div className={styles['calendar-wrapper']}>
              <EventsList
                showMonthSeparator={showMonthSeparator}
                filteredCalendarData={filteredCalendarData}
                handleBtnOnClick={handleBtnOnClick}
                selectedMonth={selectedMonth}
                hidePricing={hidePricing}
                overrideApiSoldOutStatus={overrideApiSoldOutStatus}
              />
            </div>
            {showPagination && pageCount !== currentPage && (
              <div className={styles['load-more']}>
                <Button color="light" onClick={handlePageChange} title="Load More" />
              </div>
            )}
          </div>
        </Container>
      )}
      <PortalModal bgColor="gray" isOpen={showModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <CalendarModalContent
          eventInfo={modalEventInfo}
          confirmationModalButtonCtaText={confirmationModalButtonCtaText}
          soldOutConfirmationModalText={soldOutConfirmationModalText}
          businessUnit={businessUnit}
        />
      </PortalModal>
    </>
  )
}

Calendar.defaultProps = {
  confirmationModalButtonCtaText: 'Buy Tickets',
  eventEngineApiUrl: '',
  loadCalendarDataFromS3: true,
  overrideApiSoldOutStatus: false,
  showCalendar: true,
  showMonthFilter: true,
  soldOutConfirmationModalText: ''
}

export default Calendar
