import parse from 'html-react-parser'
import cx from 'classnames'
import styles from './FormModule.module.scss'
import { Form, Heading } from '~elements'
import { FormProps, ImageProps } from '~types'
import { Container } from 'react-grid-system'

interface FormModuleProps {
  headline: string
  form: FormProps
  formModuleId: string
  showBackgroundImage: boolean
  backgroundImage: ImageProps
  showSuccessMessageInModal: boolean
  formSuccessLogo: ImageProps
  modalSuccessMessage: {
    html: string
  }
  modalSuccessTitle: string
}

const FormModule = ({
  headline,
  form,
  formModuleId,
  showBackgroundImage,
  backgroundImage,
  showSuccessMessageInModal,
  formSuccessLogo,
  modalSuccessMessage,
  modalSuccessTitle
}: FormModuleProps) => {
  const handleWindowReload = () => {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.location.reload()
      })
    }
  }

  return (
    <div className={styles['form-module']}>
      <div
        className={styles['form-module-container']}
        id={formModuleId}
        style={showBackgroundImage ? { backgroundImage: `url(${backgroundImage?.url})` } : {}}
      >
        <Container className={'container'}>
          {headline && <Heading level={2}>{headline}</Heading>}
          <Form
            formId={form?.formId}
            formSubmitHandler={handleWindowReload}
            showSuccessMessageInModal={showSuccessMessageInModal}
            formSuccessLogo={formSuccessLogo}
            onCloseModalHandler={handleWindowReload}
            modalSuccessMessage={modalSuccessMessage}
            modalSuccessTitle={modalSuccessTitle}
            submitButtonText="Submit"
          />
        </Container>
      </div>
    </div>
  )
}

export default FormModule
