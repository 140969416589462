import { createPostCall } from 'utils'
import { FORMSTACK_URL } from '~constants'

export const fetchFormstackData = async (formID: string): Promise<any> => {
  const url = `${FORMSTACK_URL}/${formID}`
  const config = {
    method: 'GET',
    headers: {
      //'app-name': 'www.msg.com'
      'Content-Type': 'application/json'
    }
  }

  const formstackResponse = await fetch(url, config)

  if (!formstackResponse.ok) {
    throw new Error(`Could not fetch data for form with ID: ${formID}`)
  }

  const formstackData = await formstackResponse.json()
  return formstackData
}

export const createFormstackPostCall = (payload: any, formID: string) => {
  const formstackHeaders = {
    //'app-name': 'www.msg.com',
    'Content-Type': 'application/json'
  }

  const call = createPostCall(payload, `${FORMSTACK_URL}/${formID}`, formstackHeaders as any)
  return call
}
