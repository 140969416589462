import React, { useState } from 'react'
import { BenefitsCard, Heading } from '~elements'
import { PortalModal } from '~elements'
import { BenefitsProps, BenefitItemType, Nullable } from '~types'
import styles from './Benefits.module.scss'

const Benefits = ({ items: benefitsCards }: BenefitsProps) => {
  const [selectedBenefit, setSelectedBenefit] = useState<Nullable<BenefitItemType>>(null)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const handleModalEvent = (status: boolean, benefitCardIndex: number, index: number) => {
    if (status && benefitCardIndex > -1 && index > -1) {
      setSelectedBenefit(benefitsCards[benefitCardIndex].items[index])
      setModalIsOpen(true)
    } else {
      setSelectedBenefit(null)
      setModalIsOpen(false)
    }
  }

  const benefitsCardItems = benefitsCards.map((benefitsCard, index) => {
    const { icon, title, items } = benefitsCard
    return <BenefitsCard key={index} benefitCardIndex={index} icon={icon} title={title} items={items} handleModalEvent={handleModalEvent} />
  })

  return (
    <>
      {benefitsCardItems}
      {/* @TODO: add functionality to navigate to other benefits within the column */}
      <PortalModal isOpen={modalIsOpen} handleModalClose={() => handleModalEvent(false, -1, -1)} panel={true}>
        {selectedBenefit && selectedBenefit.title && (
          <div className={styles['benefit-modal-wrapper']}>
            <Heading level={3}>{selectedBenefit.title}</Heading>
            {selectedBenefit && selectedBenefit.description && <p>{selectedBenefit.description}</p>}
          </div>
        )}
      </PortalModal>
    </>
  )
}

export default Benefits
