import React, { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import { Container } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from './Header.module.scss'
import { disableBodyScroll } from 'utils'
import {
  ANALYTICS_LINK_MODULE_LATEST_NEWS,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_LINK_MODULE_GLOBAL_NAV,
  ANALYTICS_LINK_LOCATION_HEADER,
  CTA_CLICK_EVENT,
  NAV_DRAWER
} from '~analyticsConstants'
import { NavigationMenuItemProps, NavigationMenuItemsProps, SubNavLinksType } from '~types'
import { formatAnalyticsText, fireAnalytics, getPageType } from '~analyticsUtils'
import { Button, ConditionalWrapper, Form, PortalModal } from '~elements'
import { useOutsideClick, useWindowSize } from 'utils/hooks'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { ButtonProps } from '~types'

interface HeaderProps {
  navigationMenuItems: NavigationMenuItemsProps
  showBuyTicketsButton: boolean
  ctaPrimary: ButtonProps
  ctaSecondary: ButtonProps
  logoDark: {
    url: string
    fileName: string
  }
  logoLight: {
    url: string
    fileName: string
  }
}

const Header = ({ showBuyTicketsButton, logoDark, logoLight, navigationMenuItems, ctaPrimary, ctaSecondary }: HeaderProps) => {
  const { asPath, query } = useRouter()
  const [navDrawerIsOpen, handleNavDrawerToggle] = useState(false)
  const { isBreakpoint: isBelowOrEqualBreakpointXs } = useWindowSize('xs', '<=')
  const { isBreakpoint: isBelowBreakpointSm } = useWindowSize('sm', '<=')
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<', () => handleNavDrawerToggle(false))
  const [pathName, setPathName] = useState(asPath)
  const [openParentNavId, setOpenParentNavId] = useState('')
  const [onChildPage, setOnChildPage] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false)
  const ref = useRef(null)
  const [, formBlock] = ctaSecondary?.modalBlocks ?? []
  const formId = formBlock?.formId
  const hasCtaForm = ctaSecondary?.title && formId
  const hasCtaTickets = ctaPrimary?.title && ctaPrimary.url
  const requestCameFrom = query.camefrom

  useEffect(() => {
    disableBodyScroll(navDrawerIsOpen)
  }, [navDrawerIsOpen])

  useEffect(() => {
    setPathName(pathName)
    const childPageArr = navigationMenuItems.map(menuItems => menuItems.subNavLinks?.findIndex(subnavLink => subnavLink.url === asPath))
    const filterIndex = childPageArr.filter(index => (index as number) > -1)
    if ((filterIndex[0] as number) > -1) {
      setOnChildPage(true)
    } else {
      setOnChildPage(false)
      setOpenParentNavId('')
    }
  }, [asPath])

  const toggleNavDrawer = () => {
    handleNavDrawerToggle(navDrawerIsOpen ? false : true)
  }

  const analyticsLinkData = (title: string) => ({
    linkId: formatAnalyticsText(title),
    linkModule: ANALYTICS_LINK_MODULE_GLOBAL_NAV,
    linkLocation: ANALYTICS_LINK_LOCATION_HEADER
  })

  const handleClick = (title: string) => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(title))
    handleNavDrawerToggle(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && !onChildPage) {
        setOpenParentNavId('')
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [onChildPage])

  const handleParentNavItemClick = (id: string) => {
    setOpenParentNavId(openParentNavId === id ? '' : id)
  }

  const closeParentNavClickHandler = () => {
    setOpenParentNavId('')
  }

  useOutsideClick(ref, () => {
    closeParentNavClickHandler()
  })

  const closeDrawerHandler = () => {
    if (navDrawerIsOpen) handleNavDrawerToggle(false)
  }

  const ctaButtonFormHandler = (location: string) => {
    setShowSignUpModal(true)
    gaBtnClick(CTA_CLICK_EVENT, location, ctaSecondary?.title)
  }

  const onCloseModalHandler = () => {
    setShowSignUpModal(false)
  }

  const formSubmitHandler = () => {
    setShowSignUpModal(false)
  }

  const activeLink = (item: NavigationMenuItemProps) => {
    if (asPath === item.url) {
      return <span className={asPath === item.url ? styles['is-active-link'] : ''}>{item.title}</span>
    } else if (item.subNavLinks?.find(subNavLink => subNavLink.url === asPath)) {
      const currentChildPath = asPath.split('/')[1].toLowerCase()
      const parentSlug = item.url.slice(1).toLowerCase()
      return currentChildPath === parentSlug ? <span className={styles['is-active-link']}>{item.title}</span> : <span>{item.title}</span>
    }
    return <span>{item.title}</span>
  }

  const ctaBtnTickets = (size = 'sm', color = 'primary', isFullWidth = false, location: string) => {
    return (
      <>
        {hasCtaTickets ? (
          <div className={styles['cta']}>
            {ctaPrimary.title && ctaPrimary.url && (
              <Button
                onClick={() => gaBtnClick(CTA_CLICK_EVENT, location, ctaPrimary.title)}
                color={color}
                fill="solid"
                isFullWidth={isFullWidth}
                size={size}
                title={ctaPrimary.title}
                url={ctaPrimary.url}
              />
            )}
          </div>
        ) : null}
      </>
    )
  }

  const ctaBtnForm = (size = 'sm', color = 'transparent', isFullWidth = false, location: string) => {
    return (
      <>
        {hasCtaForm ? (
          <div className={styles['cta']}>
            <Button
              color={color}
              fill="hollow"
              isFullWidth={isFullWidth}
              size={size}
              title={ctaSecondary?.title}
              onClick={() => ctaButtonFormHandler(location)}
              linkId={formatAnalyticsText(ctaSecondary?.title)}
              linkLocation={ANALYTICS_LINK_LOCATION_HEADER}
              linkModule={ANALYTICS_LINK_MODULE_LATEST_NEWS}
            />
          </div>
        ) : null}
      </>
    )
  }

  const SubMenu = (item: NavigationMenuItemProps) => {
    return (
      <ul className={styles['submenu']}>
        {item.subNavLinks?.map((subnavItem: SubNavLinksType) => {
          return (
            <li key={subnavItem.id} className={asPath === subnavItem.url ? styles['is-current-page'] : ''}>
              <Link
                href={requestCameFrom ? `${subnavItem.url}?camefrom=${requestCameFrom}` : subnavItem.url}
                target={subnavItem.isTargetBlank ? '_blank' : '_self'}
                onClick={() => handleClick(subnavItem.title)}
              >
                {subnavItem.title}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }

  const SubMenuForMobile = (item: NavigationMenuItemProps) => {
    return (
      <ul className={styles['submenu']}>
        {item.subNavLinks?.map((subnavItem: SubNavLinksType) => {
          return (
            <Link
              key={subnavItem.id}
              href={requestCameFrom ? `${subnavItem.url}?camefrom=${requestCameFrom}` : subnavItem.url}
              target={subnavItem.isTargetBlank ? '_blank' : '_self'}
              onClick={() => handleClick(subnavItem.title)}
            >
              <li className={asPath === subnavItem.url ? styles['is-current-page'] : ''}>{subnavItem.title}</li>
            </Link>
          )
        })}
      </ul>
    )
  }

  const BrandLogo = () => {
    return (
      <div className={styles['brand-logo']}>
        {logoLight && logoDark && (
          <div className={'image-container'}>
            <Link href={requestCameFrom ? `/?camefrom=${requestCameFrom}` : '/'}>
              <Image src={logoLight.url} alt="Sphere" width="0" height="0" sizes="100vw" priority />
            </Link>
          </div>
        )}
      </div>
    )
  }

  const NavDesktop = () => {
    return (
      <>
        <Container className={`container container--width-100 ${styles['height-100']}`}>
          <div className={styles['header-global__column']}>
            <div>{BrandLogo()}</div>
            <div className={styles['nav-container']}>
              <nav>{NavMenu()}</nav>
              <div className={styles['ctas']}>
                {ctaBtnForm(ctaSecondary?.size, ctaSecondary?.bgColor, ctaSecondary?.isFullWidth, ANALYTICS_LINK_LOCATION_HEADER)}
                {showBuyTicketsButton && ctaBtnTickets(ctaPrimary.size, ctaPrimary.bgColor, ctaPrimary.isFullWidth, ANALYTICS_LINK_LOCATION_HEADER)}
              </div>
            </div>
          </div>
        </Container>
      </>
    )
  }

  const NavMobile = () => {
    return (
      <>
        <div className={styles['header-global__column']}>
          <div className={'align-contents-vertical'}>
            <div className={styles['nav-drawer-toggler']}>
              {navigationMenuItems.length > 0 && <FontAwesomeIcon icon={faBars} onClick={() => toggleNavDrawer()} />}
            </div>
            {BrandLogo()}
          </div>
        </div>
        <nav onClick={e => e.stopPropagation()} className={cx([styles['nav-drawer'], styles[navDrawerIsOpen ? 'is-open' : '']])}>
          <div className={styles['nav-drawer__header']}>
            <div className={styles['nav-drawer-toggler']}>
              <FontAwesomeIcon icon={faXmark} onClick={() => toggleNavDrawer()} />
            </div>
            <div className={styles['nav-drawer__brand-logo']}>
              <div onClick={() => handleNavDrawerToggle(false)} className={'image-container'}>
                {BrandLogo()}
              </div>
            </div>
          </div>
          <div className={styles['nav-drawer__scroller']}>
            {NavMenu()}
            {hasCtaForm || hasCtaTickets ? (
              <div className={styles['nav-drawer__ctas']}>
                {ctaBtnForm(ctaSecondary?.size, ctaSecondary?.bgColor, true, NAV_DRAWER)}
                {ctaBtnTickets(ctaPrimary.size, ctaPrimary.bgColor, true, NAV_DRAWER)}
              </div>
            ) : null}
          </div>
        </nav>
        <div className={styles['header-global__column']}>
          {/*
           * 1024-768px: Both buttons are displayed.
           * 767-375px: Only one button is displayed, with priority given to tickets button.
           * <= 374px: No buttons are displayed.
           */}
          {!isBelowBreakpointMd
            ? ctaBtnForm(ctaSecondary?.size, ctaSecondary?.bgColor, ctaSecondary?.isFullWidth, ANALYTICS_LINK_LOCATION_HEADER)
            : !hasCtaTickets && !isBelowOrEqualBreakpointXs
            ? ctaBtnForm(isBelowBreakpointSm ? 'xs' : 'sm', 'light', false, ANALYTICS_LINK_LOCATION_HEADER)
            : null}
          {!isBelowOrEqualBreakpointXs && showBuyTicketsButton
            ? ctaBtnTickets(ctaPrimary.size, ctaPrimary.bgColor, ctaPrimary.isFullWidth, ANALYTICS_LINK_LOCATION_HEADER)
            : null}
        </div>
      </>
    )
  }

  const getSelectedMenu = (item, asPath) => {
    let selectedMenu = item.url === asPath ? item.title : null
    if (!selectedMenu && item.subNavLinks && item.subNavLinks.length) {
      const [filteredItem] = item.subNavLinks.filter(subNav => subNav.url === asPath)
      selectedMenu = filteredItem ? item?.title : ''
    }
    return selectedMenu
  }

  const NavMenu = () => {
    return (
      <ul>
        {navigationMenuItems.map((item: NavigationMenuItemProps) => {
          if (item.title && item.url) {
            const hasSubmenu = item.subNavLinks && item.subNavLinks.length > 0
            const selectedMenu = getSelectedMenu(item, asPath)
            return (
              <li
                key={item.id}
                onClick={() => handleParentNavItemClick(item.id)}
                className={cx([
                  styles[openParentNavId === item.id && hasSubmenu ? 'is-open' : ''],
                  styles[selectedMenu === item?.title ? 'active' : '']
                ])}
              >
                <div className={styles['menu-item-wrapper']}>
                  <ConditionalWrapper
                    condition={!hasSubmenu}
                    wrapper={children => (
                      <Link
                        href={requestCameFrom ? `${item.url}?camefrom=${requestCameFrom}` : item.url}
                        target={item.isTargetBlank ? '_blank' : '_self'}
                        onClick={handleClick}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    {activeLink(item)}
                  </ConditionalWrapper>

                  {hasSubmenu && isBelowBreakpointLg && (
                    <div className={styles['caret']}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  )}
                </div>
                {isBelowBreakpointLg ? (
                  <>
                    {hasSubmenu && !onChildPage && openParentNavId === item.id && <>{SubMenuForMobile(item)}</>}
                    {hasSubmenu && onChildPage && openParentNavId === item.id && <>{SubMenuForMobile(item)}</>}
                  </>
                ) : (
                  hasSubmenu && SubMenu(item)
                )}
              </li>
            )
          }
        })}
      </ul>
    )
  }

  return (
    <>
      <header
        ref={ref}
        onClick={closeDrawerHandler}
        className={cx([
          styles['header-global'],
          styles[isBelowBreakpointLg ? 'header-global--mobile' : 'header-global--desktop'],
          styles[navDrawerIsOpen && isBelowBreakpointLg ? 'drawer-open' : ''],
          styles[!isBelowBreakpointLg && formId && ctaSecondary.title && ctaPrimary.title && ctaPrimary.url ? 'header-global--has-two-cta-btns' : '']
        ])}
      >
        {isBelowBreakpointLg ? NavMobile() : NavDesktop()}
      </header>
      <PortalModal isOpen={showSignUpModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <Form formId={formId} formSubmitHandler={formSubmitHandler} isChildOfModal={true} />
      </PortalModal>
      <div className={styles['header-bg-gradient']} />
    </>
  )
}

export default Header
