import { usePathname, useRouter } from 'next/navigation'
import styles from './VotingButton.module.scss'
import { IVotingButton } from 'types/voting'
import { CLICK_CONTENT } from '~analyticsConstants'
import { gaDLPush } from 'utils/hooks/gaDLPush'

const VotingButton = ({ buttonText, buttonThumbnailImage, href, id }: IVotingButton) => {
  const { push } = useRouter()
  const pathname = usePathname() || ''

  const handleButtonClick = () => {
    gaDLPush({
      event: CLICK_CONTENT,
      click_source: pathname,
      click_tracking: `xo_landing_page:${buttonText}`,
      click_type: 'thumbnail:cta',
      link_location: 'body',
      link_module: `xo_landing_page:thumbnail:${buttonText}`,
      link_type: 'internal'
    })
    push(`${pathname}${href}`)
  }

  return (
    <button className={styles['voting-button']} onClick={handleButtonClick} key={id}>
      {buttonThumbnailImage && <img src={buttonThumbnailImage.url} />}
      <div className={styles['gradient-layer']}></div>
      <div className={styles['opacity-layer']}></div>
      <span>{buttonText}</span>
    </button>
  )
}

export default VotingButton
