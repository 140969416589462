import React, { useState } from 'react'
import cx from 'classnames'
import Image from 'next/image'
import parse from 'html-react-parser'
import { Col, Row } from 'react-grid-system'

import { Button, Form, Heading, PortalModal } from '~elements'
import { ButtonProps } from '~types'

import styles from './Card.module.scss'

interface CardMediaProps {
  backgroundImage: { url: string }
  cta: ButtonProps
  description: { html: string }
  headline: string
  icon?: { url: string }
  iconHeight?: number
  iconWidth?: number
}

const Card = ({ backgroundImage, cta, description, headline, icon, iconHeight, iconWidth }: CardMediaProps) => {
  const [showSignUpModal, setShowSignupModal] = useState<boolean>(false)
  const [modalFormId, setModalFormId] = useState<string>('')

  const ctaFormHandler = (id: string) => {
    setShowSignupModal(true)
    setModalFormId(id)
  }

  const onCloseModalHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }

  const formSubmitHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }

  return (
    <>
      <Col sm={12}>
        <Row>
          <Col lg={12} className="align-contents-vertical">
            <div
              className={cx([styles.card, styles['col-wrapper--content']])}
              onClick={() => (cta.modalFormId ? ctaFormHandler(cta.modalFormId) : null)}
            >
              <div className={styles['col-wrapper--media']}>
                {backgroundImage && (
                  <div className={styles['image-container']}>
                    <Image alt={headline} src={backgroundImage.url} fill priority />
                  </div>
                )}
                {icon && (
                  <div className={styles['media-column']}>
                    <Image width={iconWidth} height={iconHeight} sizes="100vw" alt={headline} src={icon.url} loading="lazy" />
                  </div>
                )}
              </div>
              {headline && (
                <Heading level={3}>
                  <>{headline}</>
                </Heading>
              )}
              {description?.html && parse(description.html)}
              {cta && (
                <div className={styles.button}>
                  <Button
                    color="light"
                    fill="hollow"
                    size="md"
                    onClick={() => (cta.modalFormId ? ctaFormHandler(cta.modalFormId) : null)}
                    title={cta.title}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <PortalModal isOpen={showSignUpModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} formSubmitHandler={formSubmitHandler} isChildOfModal={true} />
      </PortalModal>
    </>
  )
}
export default Card
