import type { AppProps } from 'next/app'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { gaDLPush } from 'utils/hooks/gaDLPush'

import {
  ArtistIntroCard,
  Benefits,
  ButtonGroup,
  Carousel,
  CarouselFullScreen,
  Footer,
  Grid,
  Header,
  Hero,
  HeroFullScreen,
  HeroFullScreenVideo,
  Locations,
  Page,
  PressLinks
} from '~modules'
import {
  Benefit,
  Button,
  CardMedia,
  CareerForm,
  CarouselSlide,
  Divider,
  Heading,
  ImageLink,
  Location,
  MediaLegend,
  Paragraph,
  PressLink,
  Vimeo
} from '~elements'
import '../styles/variables.scss'
import breakpointVars from '../styles/variables.breakpoints.module.scss'
import '../styles/globals.scss'
import { TYPEKIT_CSS_URL } from '~constants'
import { useEffect, useState } from 'react'
import { useGa } from 'utils/hooks/useGa'

const components = {
  ArtistIntroCard: ArtistIntroCard,
  Benefit: Benefit,
  Benefits: Benefits,
  Button: Button,
  ButtonGroup: ButtonGroup,
  CardMedia: CardMedia,
  Carousel: Carousel,
  CarouselFullScreen: CarouselFullScreen,
  CarouselSlide: CarouselSlide,
  CareerForm: CareerForm,
  Divider: Divider,
  Footer: Footer,
  grid: Grid,
  Header: Header,
  Heading: Heading,
  Hero: Hero,
  HeroFullScreen: HeroFullScreen,
  HeroFullScreenVideo: HeroFullScreenVideo,
  ImageLink: ImageLink,
  Location: Location,
  Locations: Locations,
  MediaLegend: MediaLegend,
  page: Page,
  Paragraph: Paragraph,
  PressLink: PressLink,
  PressLinks: PressLinks,
  Vimeo: Vimeo
}

// Configure react-grid-system
// @TODO: define breakpointVars[key] as type number
const breakpoints = Object.keys(breakpointVars).map(key => parseInt(breakpointVars[key]))

const containerWidths = Object.keys(breakpointVars).map(key =>
  key == 'xs' || key == 'sm' || key == 'md' ? '100%' : parseInt(breakpointVars[key] - 48)
)

setConfiguration({
  breakpoints: breakpoints,
  containerWidths: containerWidths,
  gutterWidth: 32,
  maxScreenClass: 'xl'
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { isReady: isGaReady, sendEvent } = useGa()
  const router = useRouter()
  const [previousPage, setPreviousPage] = useState('')

  const handleRouteChangeStart = () => {
    setPreviousPage(window.location.pathname)
  }

  const handleRouteChangeComplete = (url: string) => {
    const pageType = url.split('/')[1] || url
    const dataLayerData = {
      event: 'pageview',
      brand_name: 'msg_sphere_vegas',
      business_unit: 'entertainment',
      click_previous_pv: previousPage.includes('concerts') ? 'concert' : 'other_events',
      event_type: 'other_events',
      page_type: pageType,
      venue_name: 'sphere_vegas'
    }
    gaDLPush(dataLayerData)
  }

  useEffect(() => {
    handleRouteChangeComplete(window.location.pathname)

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router.events, previousPage])

  useEffect(() => {
    if (isGaReady) {
      sendEvent('pageview')
    }
  }, [isGaReady, sendEvent])

  return (
    <ScreenClassProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1" />
        <title>Sphere</title>
      </Head>
      <link rel="stylesheet" href={TYPEKIT_CSS_URL} type="text/css" />
      <div id="root-modal" />
      <Component {...pageProps} />
      <div style={{ display: 'none' }}>build number {process.env.NEXT_PUBLIC_BUILD_NUMBER}</div>
    </ScreenClassProvider>
  )
}

export default MyApp
