import React, { useState } from 'react'
import { InfoBarSectionProps } from '~types'
import { Button, Heading, Form, PortalModal } from '~elements'
import { Col } from 'react-grid-system'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { useWindowSize } from 'utils/hooks'
import styles from './InfoBarSection.module.scss'
import parse from 'html-react-parser'
const InfoBarSection = ({
  subheadline,
  mobileImage,
  defaultImg,
  description,
  eyebrow,
  cta,
  icon,
  iconHeight,
  iconWidth,
  subheadlineColor,
  img
}: InfoBarSectionProps) => {
  const [showSignUpModal, setShowSignupModal] = useState<boolean>(false)
  const [modalFormId, setModalFormId] = useState<string>('')
  const ctaFormHandler = (id: string) => {
    setShowSignupModal(true)
    setModalFormId(id)
  }
  const onCloseModalHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }
  const formSubmitHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }
  const clickHandler = () => {
    if (cta && cta.modalFormId) {
      ctaFormHandler(cta.modalFormId)
    } else {
      gaBtnClick(CTA_CLICK_EVENT, subheadline, cta?.title)
    }
  }

  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const renderImageBySize = isBelowBreakpointMd ? mobileImage?.image.url : defaultImg?.image.url

  return (
    <div className={styles['infobar-section']}>
      <Col style={{ padding: 0 }}>
        {mobileImage && defaultImg && (
          <div className={styles['image-container']}>
            <img src={renderImageBySize} alt={'ImageAlt'}></img>
          </div>
        )}
        {eyebrow && (
          <Heading level={5} levelDisplay={'eyebrow'}>
            {eyebrow}
          </Heading>
        )}
        {icon && (
          <div className={styles['media-column']}>
            <img width={iconWidth} height={iconHeight} sizes="100vw" alt={subheadline} src={icon.url} loading="lazy" />
          </div>
        )}
        {subheadline && (
          <Heading level={2} color={subheadlineColor} justify={'left'}>
            {subheadline}
          </Heading>
        )}
        {description && description.html ? <div className={styles['infobar-description']}>{parse(description.html)}</div> : null}
        {cta && (
          <div className={styles.button}>
            <Button onClick={clickHandler} {...cta} />
          </div>
        )}
      </Col>
      <PortalModal isOpen={showSignUpModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} formSubmitHandler={formSubmitHandler} isChildOfModal={true} />
      </PortalModal>
    </div>
  )
}
export default InfoBarSection
