import { memo } from 'react'
import moment from 'moment-timezone'

import MonthViewNav from './MonthViewNav/MonthViewNav'
import MonthViewHeader from './MonthViewHeader/MonthViewHeader'
import MonthViewEventMonth from './MonthViewEventMonth/MonthViewEventMonth'
import { CalendarViewProps } from 'types/calendar'

import styles from '../../ShowsCalendar.module.scss'

const MonthView = memo(
  ({
    calendarList: calendarListProps,
    seasonStartDate: seasonStartDateProps,
    seasonEndDate: seasonEndDateProps,
    handleDateSelection,
    hidePriceRangeInitialView = true,
    month,
    selectedDate,
    calendarViews,
    setSelectedDate,
    activeView,
    setActiveView,
    setHasViewChanged,
    artistDetails,
    loadCalendarData,
    disabled
  }: CalendarViewProps) => {
    const handleMonthChange = (direction: string, scrollTop: boolean) => {
      const currentMonthStart = moment(month).startOf('month')
      const today = moment()

      if (direction === 'next') {
        const nextMonthStart = currentMonthStart.add(1, 'month')
        loadCalendarData(nextMonthStart)
      } else if (direction === 'previous') {
        const previousMonthStart = currentMonthStart.subtract(1, 'month')
        const isSameMonthAsToday = previousMonthStart.isSame(today, 'month')

        if (isSameMonthAsToday) {
          loadCalendarData(today)
        } else {
          loadCalendarData(previousMonthStart)
        }
      }
    }

    const onDateClick = (day: string) => setSelectedDate && setSelectedDate(day)

    return (
      <>
        {calendarListProps && month && (
          <div className={styles.calendar__months} id="showsCalendar">
            <MonthViewNav
              month={month}
              calendarViews={calendarViews}
              prevMonth={() => handleMonthChange('previous', false)}
              nextMonth={() => handleMonthChange('next', false)}
              seasonStartDate={seasonStartDateProps}
              seasonEndDate={seasonEndDateProps}
              position="top"
              hideNavTitle={false}
              activeView={activeView}
              setActiveView={setActiveView}
              setHasViewChanged={setHasViewChanged}
              disabled={disabled}
            />
            <div className={styles['calendar__month']}>
              <MonthViewHeader />
              <MonthViewEventMonth
                calendarList={calendarListProps}
                handleDateSelection={handleDateSelection}
                hidePriceRangeInitialView={hidePriceRangeInitialView}
                month={month}
                artistDetails={artistDetails}
                onDateClick={onDateClick}
                selectedDate={selectedDate}
              />
            </div>
            <MonthViewNav
              hideNavTitle={true}
              month={month}
              calendarViews={calendarViews}
              prevMonth={() => handleMonthChange('previous', true)}
              nextMonth={() => handleMonthChange('next', true)}
              seasonStartDate={seasonStartDateProps}
              seasonEndDate={seasonEndDateProps}
              position="bottom"
              activeView={activeView}
              setActiveView={setActiveView}
              setHasViewChanged={setHasViewChanged}
              disabled={disabled}
            />
          </div>
        )}
      </>
    )
  }
)

MonthView.displayName = 'MonthView'

export default MonthView
