import Image from 'next/image'
import styles from './HeroInfoBarLink.module.scss'

interface HeroInfoBarProp {
  anchorText: string
  imageHeight: number
  imageUrl: string
  imageWidth: number
  url: string
}

const HeroInfoBarLink = ({ imageWidth, imageUrl, imageHeight, anchorText, url }: HeroInfoBarProp) => (
  <div className={styles['hero-infobar-link']}>
    <Image src={imageUrl} width={imageWidth} height={imageHeight} alt={anchorText} />
    {url ? (
      <a href={url}>
        <p>{anchorText}</p>
      </a>
    ) : (
      <p>{anchorText}</p>
    )}
  </div>
)

export default HeroInfoBarLink
