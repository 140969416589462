import React, { Fragment, memo } from 'react'
import moment from 'moment-timezone'
import { Heading } from '~elements'
import EventsListItem from '../EventsListItem/EventsListItem'

import styles from '../Calendar.module.scss'

interface EventListProps {
  filteredCalendarData: any
  selectedMonth: any
  showMonthSeparator: boolean
  handleBtnOnClick: Function
  hidePricing: boolean
  overrideApiSoldOutStatus: boolean
}

const ALL_SHOWS = 'all-dates'

const EventsList = ({
  filteredCalendarData,
  showMonthSeparator,
  selectedMonth,
  handleBtnOnClick,
  hidePricing,
  overrideApiSoldOutStatus
}: EventListProps) => {
  return (
    <>
      {filteredCalendarData &&
        Object.keys(filteredCalendarData).map((eventDate: any, index: number) => {
          const calendarData = Object.values(filteredCalendarData[eventDate])
          const formattedCalendarMonth = moment(eventDate, 'YYYY-MM').format('MMMM YYYY')

          return (
            <Fragment key={index}>
              {showMonthSeparator && selectedMonth && selectedMonth.value === ALL_SHOWS && (
                <div className={styles['month-label']}>
                  <Heading level={6}>{formattedCalendarMonth}</Heading>
                </div>
              )}
              <ul>
                {calendarData.map((event: any, index) => (
                  <EventsListItem
                    key={`Event-List-Item-${index}`}
                    {...event}
                    hidePricing={hidePricing}
                    overrideApiSoldOutStatus={overrideApiSoldOutStatus}
                    handleBtnOnClick={handleBtnOnClick}
                  />
                ))}
              </ul>
            </Fragment>
          )
        })}
    </>
  )
}

export default memo(EventsList)
