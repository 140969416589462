import React from 'react'
import { ArtistIntroCardType } from '~types'
import styles from './ArtistIntroCard.module.scss'

const ArtistIntroCard = ({ backgroundImage, logo, title }: ArtistIntroCardType) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${backgroundImage.url})`
      }}
    >
      {title && <div className={styles.title}>{title}</div>}
      {logo && (
        <div className={styles['logo-container']}>
          <img className={styles.logo} alt="logo" src={logo.url} />
        </div>
      )}
    </div>
  )
}

export default ArtistIntroCard
