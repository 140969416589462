import { useEffect, useState } from 'react'

export const useIsWebpBrowserSupported = (webpImgSrc: string) => {
  const [isImageSupported, setIsImageSupported] = useState<boolean | null>(null)

  useEffect(() => {
    const image = new Image()
    image.src = webpImgSrc
    image.onload = function () {
      setIsImageSupported(image.width > 0 && image.height > 0)
    }
  }, [])

  return isImageSupported
}
