import React, { useEffect } from 'react'
import cx from 'classnames'
import styles from './Modal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { ModalProps } from '~types'
import { disableBodyScroll } from 'utils'

const Modal = ({ children, contentWidth = 'narrow', handleModalClose, isOpen = false, closeOnOutsideClick = true, type }: ModalProps) => {
  useEffect(() => {
    const close = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && handleModalClose) {
        handleModalClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  useEffect(() => {
    // Disable body scroll when modal is open
    disableBodyScroll(isOpen)
  }, [isOpen])

  // close the modal when user clicks outside of modal content
  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }
  const classNames = cx([styles['modal-content'], styles[`modal-content--${contentWidth}`], ...(type ? [styles[`modal-content--${type}`]] : [])])

  return (
    <>
      {isOpen && (
        <div className={styles.modal}>
          <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
            <div className={classNames}>
              <FontAwesomeIcon icon={faXmark} onClick={handleModalClose} />
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Modal
