import React from 'react'
import { useWindowSize, getCustomBreakpoint } from 'utils/hooks'
import HeroEdpHeaderDesktop from './HeroEdpHeaderDesktop/HeroEdpHeaderDesktop'
import HeroEdpHeaderMobile from './HeroEdpHeaderMobile/HeroEdpHeaderMobile'
import HeroEdpHeaderTablet from './HeroEdpHeaderTablet/HeroEdpHeaderTablet'
import { useIsTablet } from 'utils/hooks'

const HeroEdpHeaderV2 = (props: any) => {
  const { isBreakpoint: isDesktop } = getCustomBreakpoint(1279, '>')
  const { isBreakpoint: isMobile } = getCustomBreakpoint(768, '<')
  const { isBreakpoint: isTablet } = getCustomBreakpoint(768, '>=')
  if (isDesktop) {
    return <HeroEdpHeaderDesktop {...props} />
  } else if (isTablet) {
    return <HeroEdpHeaderTablet {...props} />
  } else if (isMobile) {
    return <HeroEdpHeaderMobile {...props} />
  } else {
    return <HeroEdpHeaderDesktop {...props} />
  }
}
HeroEdpHeaderV2.displayName = 'hero_edp_header'

export default HeroEdpHeaderV2
