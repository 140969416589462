import { IGroupVotingButton } from 'types/voting'
import { VotingButton } from '~elements'
import styles from './VotingButtonGroup.module.scss'
import { Container } from 'react-grid-system'

const GroupVotingButton = ({ title, votingButtons }: IGroupVotingButton) => {
  return (
    <Container className={styles['voting-wrapper']}>
      <div className={styles['voting-container']}>
        <h2 className={styles['vpc-heading']}>{title}</h2>
        <div className={styles['voting-buttons-container']}>
          {votingButtons && votingButtons.map((vBtn, votingBtnIndex) => <VotingButton key={`voting-button-${votingBtnIndex}`} {...vBtn} />)}
        </div>
      </div>
    </Container>
  )
}

export default GroupVotingButton
