import React from 'react'
import styles from './Pill.module.scss'
import { Button, Heading } from '~elements'
import { PillProps } from '~types'
import { useWindowSize } from 'utils/hooks'
import { formatAnalyticsText } from '~analyticsUtils'
import { ANALYTICS_LINK_MODULE_HEADLINER, ANALYTICS_LINK_MODULE_HERO } from '~analyticsConstants'

const Pill = ({ eyebrow, headline, description, ctaText, ctaURL }: PillProps) => {
  const { isBreakpoint: isBelowLgBreakpoint } = useWindowSize('lg', '<')

  return (
    <div className={styles['pill-wrapper']}>
      <div className={styles['pill']}>
        <div className={styles['pill-inner']}>
          {eyebrow && (
            <Heading level={5} levelDisplay={'eyebrow'}>
              {eyebrow}
            </Heading>
          )}
          {headline && <Heading level={4}>{headline}</Heading>}
          {description && <Heading level={4}>{description}</Heading>}
          <Button
            color="light"
            fill="solid"
            linkId={formatAnalyticsText(ctaText)}
            linkLocation={ANALYTICS_LINK_MODULE_HERO}
            linkModule={ANALYTICS_LINK_MODULE_HEADLINER}
            size={isBelowLgBreakpoint ? 'sm' : 'md'}
            title={ctaText}
            url={ctaURL}
          />
        </div>
      </div>
    </div>
  )
}

export default Pill
