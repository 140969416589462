import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FormProps } from '~types'
import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_LOCATION_FORM, ANALYTICS_LINK_MODULE_GLOBAL_NAV } from '~analyticsConstants'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'
import PortalModal from '../PortalModal/PortalModal'
import Image from 'next/image'
import parse from 'html-react-parser'

const ReactFormstack = dynamic(() => import('@msgtechnology/react-formstack').then(module => module.default), { ssr: false })

interface FormstackData {
  id: string
}

const Form = ({
  formId,
  inputBgColor = 'white',
  isChildOfModal = false,
  submitButtonBgColor = 'white',
  submitButtonText = 'Sign Up Now',
  showSuccessMessageInModal = false,
  onCloseModalHandler,
  formSuccessLogo,
  formSubmitHandler,
  modalSuccessMessage,
  modalSuccessTitle
}: FormProps) => {
  const { asPath } = useRouter()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

  const analyticsEvent = () => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), {
      linkId: formatAnalyticsText(submitButtonText),
      linkModule: ANALYTICS_LINK_MODULE_GLOBAL_NAV,
      linkLocation: ANALYTICS_LINK_LOCATION_FORM
    })
  }

  const afterSubmit = (data: FormstackData) => {
    if (data.id) {
      setIsSuccessful(true)
      analyticsEvent()
    }
  }

  const formClassNames = `formstack formstack--input-bg-color-transparent formstack--submit-btn-bg-${submitButtonBgColor}`

  return (
    <>
      {(!isSuccessful || showSuccessMessageInModal) && (
        <ReactFormstack className={formClassNames} id={`${formId}`} onAfterSubmit={afterSubmit} submitButtonText={submitButtonText} />
      )}
      <PortalModal
        isOpen={isSuccessful && showSuccessMessageInModal}
        bgColor="form-result"
        {...(onCloseModalHandler && { handleModalClose: onCloseModalHandler })}
        closeOnOutsideClick={true}
      >
        <div className="form-modal-wrapper">
          {formSuccessLogo && formSuccessLogo.url && <Image width={54} height={54} alt="Form success icon" sizes="100vw" src={formSuccessLogo.url} />}
          {modalSuccessTitle && <Heading level={4}>{modalSuccessTitle}</Heading>}
          {modalSuccessMessage && <Heading level={4}>{parse(modalSuccessMessage?.html)}</Heading>}
        </div>
      </PortalModal>
      {isSuccessful && !showSuccessMessageInModal && (
        <div className={`formstack-success ${isChildOfModal ? 'is-child-of-modal' : 'is-child-of-page-content'}`}>
          <Heading level={3}>Success!</Heading>
          <p>We've received your request.</p>
          {isChildOfModal && <Button color="light" fill="hollow" size="md" onClick={formSubmitHandler} title="Close" />}
        </div>
      )}
    </>
  )
}

export default Form
