import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ScrollAnimationProps, ScrollAnimationSceneType, ITransformedScrollAnimationScene, IDisplaySliderContentType } from 'types/ScrollAnimation'
import ScrollAnimationScene from 'components/elements/ScrollAnimationScene/ScrollAnimationScene'
import { useIsIntersecting } from 'utils/hooks/useIsIntersecting'
import styles from './ScrollAnimation.module.scss'
import { useIsWebpBrowserSupported } from 'utils/hooks/useIsWebpBrowserSupported'
import { SCROLL_ANIMATING } from '~constants'
import { Heading } from '~elements'
import { Container } from 'react-grid-system'

const ACCEPTED_FILE_EXTENSIONS = { webp: '.webp', jpg: '.jpg' }
const IMAGES_DIMENSION = { width: 1920, height: 1080 }

let frameIndex = 0
let prevSceneId = ''

const ScrollAnimation = ({ scrollImagesSize, scrollImagesUrl, scrollAnimationScene }: ScrollAnimationProps) => {
  const [scrollAnimationSceneEntry, setScrollAnimationSceneEntry] = useState<ScrollAnimationSceneType | null>(scrollAnimationScene[0])
  const [images, setImages] = useState<Array<HTMLImageElement>>([])
  const [sliderData, setSliderData] = useState<IDisplaySliderContentType>()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const animateLastImageRef = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<HTMLDivElement>(null)
  const isCanvasIntersecting = useIsIntersecting({
    ref: canvasContainerRef
  })
  const isWebpBrowserSupported = useIsWebpBrowserSupported(`${scrollImagesUrl}/1.webp`)

  const displaySliderContent = (frameIndex: number) => {
    const data: Array<IDisplaySliderContentType> = [
      {
        eyebrow: 'sight',
        text: "Introducing the world's first 16K screen. With the desert in all directions, you'll wonder if you're really there.",
        start: 200,
        end: 242
      },
      {
        eyebrow: 'sound',
        text: 'Featuring 164,000 speakers, Sphere Immersive Sound delivers pitch-perfect audio to every seat in the house.',
        start: 243,
        end: 305
      },
      {
        eyebrow: 'touch',
        text: "With Sphere 4D tech, you'll feel the rumble in your bones, the heat on your skin and the wind in your face.",
        start: 305,
        end: 367
      }
    ]

    const result = data.reduce(
      (prev: IDisplaySliderContentType, next: IDisplaySliderContentType) => {
        if (frameIndex >= next.start && frameIndex <= next.end) {
          prev = next
        }
        return prev
      },
      { start: 0, end: 0, eyebrow: '', text: '' }
    )

    if (Object.keys(result).length > 0) {
      setSliderData(result)
    }
  }

  /**
   * gql returns these values as an array, but we'd rather have it as an object where the stopFrame is the key of the object's entry
   *
   */
  const transformedScrollStopScenes = useMemo(() => {
    return scrollAnimationScene.reduce((prev: ITransformedScrollAnimationScene, next: ScrollAnimationSceneType) => {
      prev[next.duration.start] = {
        ...next
      }
      return prev
    }, {})
  }, [scrollAnimationScene])

  /**
   * A way to get a specific scroll scene in between current frames.
   */
  const getScrollSceneByFrameIndex = useCallback(
    (index: number) => {
      const currentScene = scrollAnimationScene.filter(scene => index >= scene.duration.start && index <= scene.duration.end)
      if (currentScene.length > 0) {
        return {
          ...currentScene[0]
        }
      }
    },
    [scrollAnimationScene]
  )

  /**
   * Creating an off render canvas helps performance
   *
   * @param img HTMLImageElement
   */
  const drawKeyframeImage = useCallback((img: HTMLImageElement, requestAnimationId: number) => {
    const { current: canvas } = canvasRef
    const offScreenCanvas = document.createElement('canvas')

    if (!canvas) return

    canvas.width = IMAGES_DIMENSION.width
    canvas.height = IMAGES_DIMENSION.height
    offScreenCanvas.width = canvas.width
    offScreenCanvas.height = canvas.height

    const offScreenCanvasContext = offScreenCanvas.getContext('2d', { alpha: false })
    const context = canvas?.getContext('2d', { alpha: false })
    offScreenCanvasContext?.drawImage(img, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
    context?.drawImage(offScreenCanvas, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)

    // Cancel animation
    cancelAnimationFrame(requestAnimationId)
  }, [])

  /**
   * Determine logic about the incoming index frame number and determine if it needs to be drawn
   * to the canvas.
   */
  const updateImage = useCallback(
    (currentFrameIndex: number, requestAnimationId: number) => {
      const img = images[currentFrameIndex]

      if (!img) {
        return
      }

      const currentScene = getScrollSceneByFrameIndex(frameIndex)

      try {
        if (!currentScene || (currentScene && prevSceneId !== currentScene.id)) {
          drawKeyframeImage(img, requestAnimationId)
        }
      } catch (e) {
        console.error(e)
      }

      if (currentScene && prevSceneId !== currentScene.id) {
        setScrollAnimationSceneEntry(currentScene)
      } else if (!currentScene && prevSceneId !== '') {
        setScrollAnimationSceneEntry(null)
      }

      prevSceneId = (currentScene && currentScene.id) || ''
    },
    [drawKeyframeImage, getScrollSceneByFrameIndex, images]
  )

  /**
   * Load images consumed by canvas. These images come from an s3 bucket.
   */
  useEffect(() => {
    const promises: Array<Promise<HTMLImageElement>> = []
    const imageExt = isWebpBrowserSupported ? ACCEPTED_FILE_EXTENSIONS.webp : ACCEPTED_FILE_EXTENSIONS.jpg

    if (isWebpBrowserSupported !== null) {
      let i = 1,
        j = 1
      while (i < scrollImagesSize) {
        promises.push(
          new Promise((resolve, reject) => {
            const image = new Image()
            image.src = `${scrollImagesUrl}/${i}${imageExt}`
            image.onload = resolve(image)
            image.onerror = resolve(image)
          })
        )

        if (transformedScrollStopScenes[j]) {
          const currentScene = transformedScrollStopScenes[j]
          for (let k = j; k < currentScene.duration.end; k++) {
            promises.push(
              new Promise((resolve, reject) => {
                const repeatedImage = new Image()
                repeatedImage.src = `${scrollImagesUrl}/${i}${imageExt}`
                repeatedImage.onload = resolve(repeatedImage)
                repeatedImage.onerror = resolve(repeatedImage)
              })
            )
            j++
          }
        }

        i++, j++
      }

      Promise.all(promises).then(images => {
        setImages(images)
      })
    }
  }, [isWebpBrowserSupported, scrollImagesSize, scrollImagesUrl, transformedScrollStopScenes])

  /**
   * Draw the first image to canvas on page load (once the images are done loading).
   */
  useEffect(() => {
    if (images.length > 0 && canvasRef.current) {
      updateImage(0, 0)
    }
  }, [canvasRef, images, updateImage])

  /**
   * Future use: To determine when the page is animation for other eventListeners to listen for.
   */
  // useEffect(() => {
  //   if (canvasRef.current) {
  //     if (isCanvasIntersecting) {
  //       dispatchEvent(new CustomEvent(SCROLL_ANIMATING.START))
  //     } else if (!isCanvasIntersecting) {
  //       dispatchEvent(new CustomEvent(SCROLL_ANIMATING.STOP))
  //     }
  //   }
  // }, [canvasFixedClassName, isCanvasIntersecting, canvasRef])

  /**
   * Keeping as this was used for a workaround when displaying the slider in the middle of the animation.
   * 020723 - It isn't used anymore but may be in future iterations.
   */
  // useEffect(() => {
  //   if (scrollAnimationSceneEntry && canvasRef.current) {
  //     requestAnimationFrame(id => {
  //       updateImage(frameIndex + 1, id)
  //     })
  //   }
  // }, [canvasRef, scrollAnimationSceneEntry, updateImage])

  /**
   * This is where the 'magic' happens. It calls a few helper functions to determine necessary writes to canvas.
   */
  const handleWindowScroll = useCallback(() => {
    let animationFrameId = 0

    if (canvasContainerRef.current) {
      const html = document.documentElement
      const scrollTop = html.scrollTop
      const scrollFraction = scrollTop / (html.scrollHeight - html.clientHeight - document.querySelector('footer')?.clientHeight)
      const imagesLength = images.length

      frameIndex = Math.min(imagesLength - 1, Math.floor(scrollFraction * imagesLength))

      if (frameIndex <= imagesLength) {
        animationFrameId = requestAnimationFrame(id => {
          updateImage(frameIndex + 1, id)
        })
      }

      if (sliderRef.current) {
        displaySliderContent(frameIndex)
      }

      if (canvasRef.current && animateLastImageRef.current && isCanvasIntersecting) {
        if (scrollFraction > 1) {
          animateLastImageRef.current.style.display = 'block'
        } else {
          animateLastImageRef.current.style.display = 'none'
        }
      }

      if (frameIndex === imagesLength - 1) {
        setScrollAnimationSceneEntry(null)
        prevSceneId = ''
      }
    }
  }, [images.length, updateImage, isCanvasIntersecting])

  /**
   * Establishing the event listener on scroll.
   */
  useEffect(() => {
    if (canvasContainerRef.current && images.length > 0) {
      window.addEventListener('scroll', handleWindowScroll, { passive: true })
    }
    return () => window.removeEventListener('scroll', handleWindowScroll)
  }, [images.length, handleWindowScroll])

  useEffect(() => {
    prevSceneId = ''
  }, [])

  return (
    <div className={styles['animate-canvas-container']} ref={canvasContainerRef}>
      <div className={styles['animate-canvas-inner-container']}>
        <canvas className={`${styles['animate-canvas']}`} ref={canvasRef} style={{ opacity: scrollAnimationSceneEntry?.opacity || 1 }} />
        {scrollAnimationSceneEntry ? <ScrollAnimationScene {...scrollAnimationSceneEntry} /> : null}
        <div className={styles['animate-slider']} ref={sliderRef}>
          <Container>
            <Heading level={6}>{sliderData?.eyebrow}</Heading>
            <Heading level={3}>{sliderData?.text}</Heading>
          </Container>
        </div>
        <div ref={animateLastImageRef} className={styles['animate-last-image']}>
          <img alt="sphere last image" src={`${(images && images.length > 0 && `${images[images.length - 1].src}`) || ''}`} />
        </div>
      </div>
    </div>
  )
}

export default ScrollAnimation
