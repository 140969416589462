import React, { memo } from 'react'
import moment from 'moment'
import { Button } from '~elements'
import { EventProps } from '~types'
import { Col, Container, Row } from 'react-grid-system'

import styles from '../Calendar.module.scss'

interface EventListItemProps {
  date: number
  events: Array<EventProps>
  handleBtnOnClick: Function
  price: {
    min: number
    max: number
  }
  overrideApiSoldOutStatus: boolean
  spanMultipleDays: boolean
  end_date: number
}

const DATE_FORMATTER = {
  DAY: 'DD',
  DATE: 'MMM D ',
  SHORT_DAY: 'ddd'
}

const EventListItem = ({
  date,
  events,
  price,
  is_sold_out,
  handleBtnOnClick,
  hidePricing,
  overrideApiSoldOutStatus,
  spanMultipleDays,
  end_date
}: EventListItemProps) => {
  const eventTimestamp = moment.utc(date)
  const dayOfMonth = +eventTimestamp.format(DATE_FORMATTER.DAY)
  const formattedEndDate = moment.utc(end_date).format(DATE_FORMATTER.DATE)
  const formattedDate = eventTimestamp.format(DATE_FORMATTER.DATE)
  const formattedDay = eventTimestamp.format(DATE_FORMATTER.SHORT_DAY)
  const formattedEndDay = moment.utc(end_date).format(DATE_FORMATTER.SHORT_DAY)
  const showEventDate = events.some(event => moment.utc().valueOf() <= event.date_time.valueOf())
  const isAllShowsSoldOut = events.every(({ is_sold_out }) => is_sold_out)
  const { min: minPrice, max: maxPrice } = price
  const eventPriceRange = minPrice === maxPrice ? `$${minPrice}` : `$${minPrice} - $${maxPrice}`

  return (
    <>
      {showEventDate && (
        <li className={styles['list-item']}>
          <Container className="container container--width-100">
            <Row>
              <Col lg={2} className="align-contents-vertical-top">
                {spanMultipleDays ? (
                  <div className={styles['list-item-date-holder']}>
                    <div className={styles['list-item__date']}>
                      <span className={styles['list-item__formatted-start-date']}>
                        <span className={styles['list-item__formatted-date']}>{`${formattedDate}`}</span>
                        <span className={styles['list-item__separator']}>|</span>
                        <span className={styles['list-item__formatted-day']}>{`${formattedDay}`}</span>
                      </span>
                      <span className={styles['list-item__range-separator']}>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                      <span className={styles['list-item__formatted-end-date']}>
                        <span className={styles['list-item__formatted-date']}>{`${formattedEndDate}`}</span>
                        <span className={styles['list-item__separator']}>|</span>
                        <span className={styles['list-item__formatted-day']}>{`${formattedEndDay}`}</span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={styles['list-item-date-holder']}>
                    <div className={styles['list-item__date']}>
                      <span className={styles['list-item__formatted-date']}>{`${formattedDate}`}</span>
                      <span className={styles['list-item__separator']}>|</span>
                      <span className={styles['list-item__formatted-day']}>{`${formattedDay}`}</span>
                    </div>
                  </div>
                )}
              </Col>
              <Col lg={3} className="align-contents-vertical-top">
                <div className={styles['list-item-date-holder']}>
                  {isAllShowsSoldOut && !hidePricing && !overrideApiSoldOutStatus && !spanMultipleDays ? (
                    <p className={styles.price}>
                      <span>SOLD OUT</span>
                    </p>
                  ) : price && price.min && price.max && !hidePricing && !isAllShowsSoldOut ? (
                    <p className={styles.price}>
                      Tickets from <span>{eventPriceRange}*</span>
                    </p>
                  ) : null}
                </div>
              </Col>
              <Col lg={7}>
                <div className={styles['list-item-ctas']}>
                  {events.map(({ date_time, formatted_start_time, host_url, id, is_sold_out, name, price, ...rest }, eventIndex) => {
                    const showButton = moment.utc().valueOf() <= date_time
                    const eventInfo = {
                      date_time,
                      formatted_start_time,
                      host_url,
                      is_sold_out,
                      name,
                      price,
                      spanMultipleDays,
                      end_date,
                      id,
                      ...rest
                    }

                    return showButton && formatted_start_time ? (
                      <div key={`${id}_${eventIndex}`}>
                        <Button
                          color="light"
                          disabled={is_sold_out && !overrideApiSoldOutStatus}
                          fill="hollow"
                          size="sm"
                          title={formatted_start_time}
                          {...((!is_sold_out || overrideApiSoldOutStatus) && {
                            onClick: () => handleBtnOnClick({ ...eventInfo, event_date: date, overrideApiSoldOutStatus })
                          })}
                        />
                      </div>
                    ) : null
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </li>
      )}
    </>
  )
}

export default memo(EventListItem)
