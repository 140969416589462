import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import styles from './PortalModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { ModalProps } from '~types'
import { disableBodyScroll } from 'utils'

const PortalModal = ({ isOpen, contentWidth = 'narrow', handleModalClose, children, closeOnOutsideClick = true, bgColor, type }: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event
      if (key === 'Escape' && isOpen && handleModalClose) {
        handleModalClose()
      }
    },
    [isOpen]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }

  const classNames = cx([
    styles['modal-content'],
    styles[`modal-content--${contentWidth}`],
    type && type === 'show-calendar' && styles['modal-content--shows-calendar'],
    ...(bgColor ? [styles[`modal-content--${bgColor}`]] : [])
  ])

  const modalContent = isOpen ? (
    <div className={cx([styles.modal, ...(bgColor ? [styles[`modal-${bgColor}`]] : [])])}>
      <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
        <div className={classNames}>
          <FontAwesomeIcon icon={faXmark} onClick={handleModalClose} />
          {children}
        </div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    let modalRoot = document.getElementById('root-modal')
    if (!modalRoot) {
      modalRoot = document.createElement('div')
      modalRoot.id = 'root-modal'
      document.body.appendChild(modalRoot)
    }
    return ReactDOM.createPortal(modalContent, modalRoot)
  } else {
    return null
  }
}

export default PortalModal
