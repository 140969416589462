import styles from './ToggleButton.module.scss'

interface CloseButtonProps {
  onClick: () => void
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
  const labelText = 'close'

  return (
    <div className={styles['close-button-wrapper']} aria-label={labelText} onClick={onClick}>
      <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" fill="none" opacity="1">
        <g id="icon">
          <rect id="3" x="0.707031" width="16" height="1" rx="0.5" transform="rotate(45 0.707031 0)" fill="white" />
          <rect id="1" y="11.3135" width="16" height="1" rx="0.5" transform="rotate(-45 0 11.3135)" fill="white" />
        </g>
      </svg>
    </div>
  )
}

export default CloseButton
