import React from 'react'
import styles from './FlexBox.module.scss'
import { Row, Col } from 'react-grid-system'
import { useWindowSize } from 'utils/hooks'
import { useSwipe } from 'utils/hooks/useSwipe'
import { useState } from 'react'
import PremiumCard from 'components/elements/PremiumCard/PremiumCard'
import { Heading } from '~elements'

const FlexBox = props => {
  const premiumCards = props.cards
  const colSpan = Math.max(12 / Math.min(premiumCards.length, 4), 3)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [activeIndex, setActiveIndex] = useState(0)
  const nextSlide = () => setActiveIndex(prevIndex => (prevIndex + 1) % premiumCards.length)
  const prevSlide = () => setActiveIndex(prevIndex => (prevIndex - 1 + premiumCards.length) % premiumCards.length)

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipe(premiumCards.length, nextSlide, prevSlide)

  return (
    <>
      <div className={styles['card-section']}>
        {props?.headline && (
          <Heading level={2} justify="center">
            {props?.headline}
          </Heading>
        )}
        {isBelowBreakpointMd && premiumCards ? (
          props.isCarouselTrue ? (
            <div className={styles['carousel-container']} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
              <PremiumCard {...premiumCards[activeIndex]} key={activeIndex} />
              <div className={styles['indicator-position']}>
                {premiumCards.map((_, index) => (
                  <span
                    key={`indicator-${index}`}
                    className={`${styles['indicator']} ${index === activeIndex ? styles['active-indicator'] : ''}`}
                  ></span>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles['mobile-container']}>
              {premiumCards.map((premiumCard, index) => (
                <PremiumCard {...premiumCard} />
              ))}
            </div>
          )
        ) : (
          <Row>
            {premiumCards.map((premiumCard, index) => (
              <Col key={index} xs={12} sm={12} lg={Math.floor(colSpan)}>
                <PremiumCard {...premiumCard} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </>
  )
}

export default FlexBox
