import { Container } from 'react-grid-system'
import styles from './EDPHeader.module.scss'
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { ButtonProps } from '~types'
import { useWindowSize } from 'utils/hooks'
import { Button, Heading } from '~elements'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'

interface EdpHeaderProps {
  logo: { url: string }
  headline: string
  subHeadline: string
  price: string
  cta: Array<ButtonProps>
  businessUnit?: string
  interactive?: any
  isStickyDisclaimerOpen?: boolean
}

const EdpHeader = ({ logo, headline, subHeadline, price, cta, interactive, businessUnit, isStickyDisclaimerOpen }: EdpHeaderProps) => {
  const { query } = useRouter()
  const [source] = query.slug as string[]

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (!buttonProps.displayFevoWidget) {
      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} edp_header:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: EdpHeader.displayName,
        linkPosition: '',
        ticketmasterEventId: '',
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    }
  }

  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [showHeader, setShowHeader] = useState<boolean>(false)
  const requestCameFrom = query.camefrom

  useEffect(() => {
    const handleScroll = () => {
      const EDPBuyBtn = document.querySelector('[data-id="edp-buy-btn"')
      if (EDPBuyBtn) {
        const btnPos = EDPBuyBtn.getBoundingClientRect()
        const visibleHeader = btnPos.top >= 0 && btnPos.bottom <= window.innerHeight
        setShowHeader(!visibleHeader)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  const BrandLogo = () => {
    return (
      <div className={styles['brand-logo']}>
        <Link href={requestCameFrom ? `/?camefrom=${requestCameFrom}` : '/'}>
          {/* <Image /> does not work with svg's */}
          <img src={logo.url} alt="Sphere Logo" />
        </Link>
      </div>
    )
  }

  const NavDesktop = () => {
    return (
      <Container className={'container container--width-100'}>
        <div className={styles['header-global__column']}>
          <div className={styles['brand-headline-container']}>
            <div>{BrandLogo()}</div>
            <hr className={styles['vertical-divider']} />
            <Heading level={2}>{headline}</Heading>
          </div>

          <div className={styles['subheadline-cta-container']}>
            <div className={styles['subheadline']}>
              <p>{subHeadline}</p>
              <span>{price}</span>
              <hr className={styles['vertical-divider-right']} />
            </div>
            <Button {...cta[0]} onClick={() => handleButtonClick(cta[0])} />
          </div>
        </div>
      </Container>
    )
  }

  return (
    <>
      {isBelowBreakpointMd ? (
        <div className={styles['standalone-btn']} style={isStickyDisclaimerOpen ? { bottom: '50px' } : { bottom: 0 }}>
          {interactive ? (
            <Button {...cta[1]} onClick={() => handleButtonClick(cta[1])} />
          ) : (
            <Button {...cta[0]} onClick={() => handleButtonClick(cta[0])} />
          )}
        </div>
      ) : (
        <header className={cx([styles['header-global'], styles['header-global--desktop'], styles[showHeader ? 'show' : '']])}>{NavDesktop()}</header>
      )}
    </>
  )
}

EdpHeader.displayName = 'edp_header'

export default EdpHeader
