import { memo, useEffect, useState } from 'react'
import cx from 'classnames'

import CalendarFilter from '../../elements/CalendarFilter'
import TileViewEventMonths from './TileViewEventMonths/TileViewEventMonths'
import { getFilteredCalendarData } from '../../shows-calendar-services'
import ViewButtonGroup from '../../elements/ViewButtonGroup'
import { CalendarViewProps, ListViewCalendarDataProps } from 'types/calendar'
import MonthViewNav from '../MonthView/MonthViewNav/MonthViewNav'
import styles from '../../ShowsCalendar.module.scss'
import moment from 'moment-timezone'

const TileView = memo(
  ({
    calendarList: calendarListProps,
    activeView,
    artistDetails,
    calendarViews,
    selectedMonth,
    calendarFilterItems,
    setActiveView,
    handleMonthNavChange,
    hasViewChanged,
    setHasViewChanged,
    handleDateSelection,
    loadCalendarData,
    activeMonthList,
    month,
    seasonStartDate: seasonStartDateProps,
    seasonEndDate: seasonEndDateProps,
    disabled
  }: CalendarViewProps) => {
    const [filteredCalendarData, setFilteredCalendarData] = useState<ListViewCalendarDataProps | null>(null)

    const handleMonthChange = (direction: string, scrollTop: boolean) => {
      const currentMonth = moment(month).startOf('month')
      if (direction === 'next') {
        const nextMonth = currentMonth.add(1, 'month')
        loadCalendarData(nextMonth)
      } else if (direction === 'previous') {
        const prevMonth = currentMonth.subtract(1, 'month')
        loadCalendarData(prevMonth)
      }
    }

    useEffect(() => {
      if (calendarListProps && calendarListProps.length > 0) {
        const { calendarDataList } = getFilteredCalendarData(calendarListProps)
        setFilteredCalendarData(calendarDataList)
      }
    }, [calendarListProps])

    return (
      <div className={cx([styles['calendar__months'], styles['calendar__grid']])}>
        <div className={styles['calendar__month']}>
          <div className={cx([styles['calendar__month-navs'], styles['calendar__month-navs--top']])}>
            <CalendarFilter
              calendarFilterItems={calendarFilterItems}
              handleMonthNavChange={handleMonthNavChange}
              selectedMonth={selectedMonth}
              loadCalendarData={loadCalendarData}
              activeMonthList={activeMonthList}
            />
            {calendarViews && (
              <ViewButtonGroup options={calendarViews} activeView={activeView} setActiveView={setActiveView} setHasViewChanged={setHasViewChanged} />
            )}
          </div>

          {calendarListProps && filteredCalendarData && (
            <div className={cx([styles['calendar__months'], styles['calendar__grid']])}>
              <TileViewEventMonths
                showMonthLabel={true}
                hasViewChanged={hasViewChanged}
                filteredCalendarData={filteredCalendarData}
                handleDateSelection={handleDateSelection}
                artistDetails={artistDetails}
                selectedMonth={selectedMonth}
              />
            </div>
          )}

          <MonthViewNav
            hideNavTitle={true}
            month={month}
            calendarViews={calendarViews}
            prevMonth={() => handleMonthChange('previous', true)}
            nextMonth={() => handleMonthChange('next', true)}
            seasonStartDate={seasonStartDateProps}
            seasonEndDate={seasonEndDateProps}
            position="bottom"
            activeView={activeView}
            setActiveView={setActiveView}
            setHasViewChanged={setHasViewChanged}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
)

TileView.displayName = 'TileView'

export default TileView
