import React from 'react'
import { ImageLink } from '~elements'
import { ImageProps } from '~types'
import styles from './ImageLinks.module.scss'
interface ImageLinkProps {
  fadesIn: boolean
  id: string
  img: ImageProps
  imageWidth?: number
  isTargetBlank?: boolean
  title: string
  url: string
}

interface ImageLinksProps {
  items: Array<ImageLinkProps>
  analyticsLinkId: string
}

const ImageLinks = ({ items, analyticsLinkId }: ImageLinksProps) => {
  return (
    <>
      {items &&
        items.map((imageLink: ImageLinkProps) => (
          <div key={imageLink.id} className={styles.imageLink}>
            <ImageLink {...imageLink} analyticsLinkId={analyticsLinkId} />
          </div>
        ))}
    </>
  )
}

export default ImageLinks
