import React, { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { NavigationHeaderProps } from '~types'
import { MAX_LINKS_PER_SECTION, SWIPE_DISTANCE_LIMIT } from '~constants'
import live from 'public/assets/live.svg'
import Link from 'next/link'
import Image from 'next/image'
import { Container } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from './NavigationHeader.module.scss'
import { constructURL, disableBodyScroll } from 'utils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_MODULE_GLOBAL_NAV, ANALYTICS_LINK_LOCATION_HEADER, CTA_CLICK_EVENT } from '~analyticsConstants'
import { NavigationMenuItemProps, SubNavLinksType } from '~types'
import { formatAnalyticsText, fireAnalytics, getPageType } from '~analyticsUtils'
import { HeaderButton, ConditionalWrapper, HamburgerButton, CloseButton } from '~elements'
import { useOutsideClick, getCustomBreakpoint } from 'utils/hooks'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { Heading } from '@msgtechnology/sphere'
import { gaDLPush } from 'utils/hooks/gaDLPush'

const NavigationHeader = ({
  bannerRef,
  brandLogo,
  navigationMenuItems,
  buttonTitle,
  buttonUrl,
  isTargetBlank,
  endNavItem,
  endNavItemUrl
}: NavigationHeaderProps) => {
  const { asPath, query, push } = useRouter()
  const { isBreakpoint: isSmallerThanDesktop } = getCustomBreakpoint(1280, '<')
  const [pathName, setPathName] = useState(asPath)
  const [openParentNavId, setOpenParentNavId] = useState('')
  const [onChildPage, setOnChildPage] = useState(false)
  const ref = useRef(null)
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const requestCameFrom = query.camefrom
  const [navDrawerIsOpen, handleNavDrawerToggle] = useState(false)

  const toggleSidebar = () => {
    handleNavDrawerToggle(!navDrawerIsOpen)
  }

  useEffect(() => {
    const updateTopPosition = () => {
      if (ref && ref.current) {
        ref.current.style.top = bannerRef?.current ? `${bannerRef.current.clientHeight}px` : '0px'
      }
    }
    const timeoutId = setTimeout(updateTopPosition, 0)
    window.addEventListener('resize', updateTopPosition)
    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', updateTopPosition)
    }
  }, [bannerRef, ref])

  useEffect(() => {
    const updateTopPositions = () => {
      if (sidebarRef.current && overlayRef.current) {
        const bannerHeight = bannerRef?.current ? `${bannerRef.current.clientHeight}px` : '0px'
        sidebarRef.current.style.top = `calc(var(--header-height-mobile) + ${bannerHeight})`
        overlayRef.current.style.top = bannerHeight
      }
    }
    const timeoutId = setTimeout(updateTopPositions, 0)
    window.addEventListener('resize', updateTopPositions)
    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', updateTopPositions)
    }
  }, [bannerRef, sidebarRef.current, overlayRef.current])

  useEffect(() => {
    disableBodyScroll(navDrawerIsOpen)
  }, [navDrawerIsOpen])

  useEffect(() => {
    setPathName(pathName)
    const childPageArr = navigationMenuItems.map(menuItems => menuItems.subNavLinks?.findIndex(subNavLink => subNavLink.url === asPath))
    const filterIndex = childPageArr.filter(index => (index as number) > -1)
    if ((filterIndex[0] as number) > -1) {
      setOnChildPage(true)
    } else {
      setOnChildPage(false)
      setOpenParentNavId('')
    }
  }, [asPath])

  const closeDrawerHandler = () => {
    if (navDrawerIsOpen) handleNavDrawerToggle(false)
  }

  const analyticsLinkData = (title: string) => ({
    linkId: formatAnalyticsText(title),
    linkModule: ANALYTICS_LINK_MODULE_GLOBAL_NAV,
    linkLocation: ANALYTICS_LINK_LOCATION_HEADER
  })

  const handleClick = (
    title: string,
    url: string,
    event: any,
    parentIndex: number,
    childIndex: number,
    displayFevoWidget: boolean | null | undefined
  ) => {
    if (displayFevoWidget) {
      // @ts-ignore
      GMWidget.open('DirectorsSeatPackage')
    } else {
      gaDLPush({
        event: 'click_nav',
        click_source: getPageType(asPath)?.linkId,
        click_text: event.target.text,
        click_tracking: `main_navigation:${event.target.text}`,
        click_type: 'navigation',
        click_url: url,
        link_location: 'header',
        link_module: 'main-navigation',
        link_position: `${parentIndex}|${childIndex}`,
        link_type: 'internal',
        nav_type: 'header'
      })
      fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(title))
    }
    handleNavDrawerToggle(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > SWIPE_DISTANCE_LIMIT && !onChildPage) {
        setOpenParentNavId('')
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [onChildPage])

  const handleParentNavItemClick = (id: string) => {
    setOpenParentNavId(openParentNavId === id ? '' : id)
  }

  const closeParentNavClickHandler = () => {
    setOpenParentNavId('')
  }

  useOutsideClick(ref, () => {
    closeParentNavClickHandler()
  })

  const activeLink = (item: NavigationMenuItemProps) => {
    if (asPath === item.url) {
      return <span className={asPath === item.url ? styles['is-active-link'] : ''}>{item.title}</span>
    } else if (item.subNavLinks?.find(subNavLink => subNavLink.url === asPath)) {
      const currentChildPath = asPath.split('/')[1].toLowerCase()
      const parentSlug = item.url.slice(1).toLowerCase()
      return currentChildPath === parentSlug ? <span className={styles['is-active-link']}>{item.title}</span> : <span>{item.title}</span>
    }
    return <span>{item.title}</span>
  }

  const getHref = (url: string, displayFevoWidget: boolean) => {
    if (displayFevoWidget) {
      return 'javascript:void(0)'
    }

    if (requestCameFrom) {
      return `${url}?camefrom=${requestCameFrom}`
    } else {
      return url
    }
  }

  const CommonSubMenu = ({ item, parentIndex, isMobile, asPath, requestCameFrom, handleClick }) => {
    return (
      <ul className={styles['submenu-links']}>
        {item.subNavLinks?.map((subNavItem: SubNavLinksType, childIndex: number) => {
          const isLastItem = childIndex === item.subNavLinks!.length - 1
          const isNextItemASection = item.subNavLinks![childIndex + 1]?.groupTitle

          if (!subNavItem.groupTitle && subNavItem.url) {
            const elements = (
              <li key={subNavItem.id} className={asPath === subNavItem.url ? styles['is-current-page'] : ''}>
                <Link
                  href={getHref(subNavItem.url, subNavItem.displayFevoWidget!)}
                  target={!subNavItem.isTargetBlank || subNavItem.displayFevoWidget ? '_self' : '_blank'}
                  onClick={event => handleClick(subNavItem.title, subNavItem.url, event, parentIndex, childIndex, subNavItem.displayFevoWidget)}
                >
                  {subNavItem.title}
                </Link>
              </li>
            )

            if (subNavItem.hasDivider) {
              return (
                <React.Fragment key={subNavItem.id}>
                  {elements}
                  <hr key={`${subNavItem.id}-divider`} className={styles['divider']} />
                </React.Fragment>
              )
            }
            return elements
          }
          if (subNavItem.groupTitle) {
            return (
              <React.Fragment key={subNavItem.id}>
                {childIndex > 0 && (
                  <hr
                    key={`${subNavItem.id}-top-divider`}
                    className={isMobile ? styles['submenu-links'] + ' ' + styles['divider'] : styles['divider']}
                  />
                )}
                <li key={`${subNavItem.id}-groupTitle`} className={styles['group-title']}>
                  {subNavItem.groupTitle}
                </li>

                <div className={styles['section']}>
                  {subNavItem.sectionLinks?.slice(0, MAX_LINKS_PER_SECTION).map((linkItem, linkIndex) => (
                    <li key={linkIndex} className={asPath === linkItem.url ? styles['is-current-page'] : ''}>
                      <Link
                        href={requestCameFrom ? `${linkItem.url}?camefrom=${requestCameFrom}` : linkItem.url}
                        target="_self"
                        onClick={event => handleClick(linkItem.title, linkItem.url, event, parentIndex, linkIndex)}
                      >
                        {linkItem.title}
                      </Link>
                    </li>
                  ))}
                </div>
                {!isNextItemASection && !isLastItem && (
                  <hr
                    key={`${subNavItem.id}-bottom-divider`}
                    className={isMobile ? styles['submenu-links'] + ' ' + styles['divider'] : styles['divider']}
                  />
                )}
              </React.Fragment>
            )
          }
          return null
        })}
      </ul>
    )
  }

  const SubMenu = (item: NavigationMenuItemProps, parentIndex: number) => {
    const handleFeatureClick = () => {
      gaBtnClick('CTA_CLICK_EVENT', item.submenuImageSubtitle, item.submenuImageTitle)
      const redirectUrl = item.submenuRedirectUrl || navigationMenuItems[0]?.url
      push(requestCameFrom ? constructURL(redirectUrl, { key: 'camefrom', value: requestCameFrom as string }) : redirectUrl)
    }
    return (
      <div className={styles['submenu']}>
        <div className={styles['submenu-left']}>
          <CommonSubMenu
            item={item}
            parentIndex={parentIndex}
            isMobile={false}
            asPath={asPath}
            requestCameFrom={requestCameFrom}
            handleClick={handleClick}
          />
        </div>
        {item.submenuImage && (
          <div className={styles['submenu-feature']} onClick={handleFeatureClick}>
            <Image src={item.submenuImage.url} alt={item.submenuImageTitle} width={256} height={160} priority />
            <div className={styles['submenu-text']}>
              <Heading level={4}>{item.submenuImageTitle}</Heading>
              <p>{item.submenuImageSubtitle}</p>
            </div>
          </div>
        )}
      </div>
    )
  }

  const SubMenuForMobile = (item: NavigationMenuItemProps, parentIndex: number) => {
    return (
      <div className={styles['submenu']}>
        <CommonSubMenu
          item={item}
          parentIndex={parentIndex}
          isMobile={true}
          asPath={asPath}
          requestCameFrom={requestCameFrom}
          handleClick={handleClick}
        />
      </div>
    )
  }

  const BrandLogo = () => {
    return (
      <div className={styles['brand-logo']}>
        {brandLogo && (
          <div className={'image-container'}>
            <Link href={requestCameFrom ? `/?camefrom=${requestCameFrom}` : '/'}>
              <Image src={brandLogo.url} alt="Sphere" width="0" height="0" sizes="100vw" priority />
            </Link>
          </div>
        )}
      </div>
    )
  }

  const NavDesktop = () => {
    return (
      <>
        <Container className={`container container--width-100 ${styles['height-100']}`}>
          <div className={styles['header-global__column']}>
            <div>{BrandLogo()}</div>
            <div className={styles['nav-container']}>
              <nav>{NavMenu()}</nav>
            </div>
            <div className={styles['end-buttons']}>
              {endNavItem && endNavItemUrl && (
                <Link href={requestCameFrom ? constructURL(endNavItemUrl, { key: 'camefrom', value: requestCameFrom as string }) : endNavItemUrl}>
                  <div
                    className={styles['nav-link']}
                    onClick={() => {
                      push(endNavItemUrl)
                      toggleSidebar()
                      gaDLPush({
                        event: 'click_nav',
                        click_source: '/',
                        click_text: 'xostream',
                        click_tracking: `endnavheader:menu_xostream`,
                        click_type: 'navigation',
                        click_url: endNavItemUrl,
                        link_location: 'header',
                        link_module: 'main-navigation',
                        link_position: '5',
                        link_type: 'internal',
                        nav_type: 'header'
                      })
                    }}
                  >
                    <Image alt="live icon" src={live} />
                    <span>{endNavItem}</span>
                  </div>
                </Link>
              )}
              <HeaderButton
                title={buttonTitle}
                url={buttonUrl}
                onClick={event => handleButtonClick(event, buttonUrl)}
                isTargetBlank={isTargetBlank}
              />
            </div>
          </div>
        </Container>
      </>
    )
  }

  const handleButtonClick = (event, buttonUrl) => {
    gaBtnClick(CTA_CLICK_EVENT, ANALYTICS_LINK_LOCATION_HEADER, buttonTitle)
    gaDLPush({
      event: 'click_nav',
      click_source: '/',
      click_text: 'showtimes',
      click_tracking: `endnavheader:menu_showtimes`,
      click_type: 'navigation',
      click_url: buttonUrl,
      link_location: 'header',
      link_module: 'main-navigation',
      link_position: '6',
      link_type: 'internal',
      nav_type: 'header'
    })
  }

  const NavMobile = () => {
    const handleMenuClick = () => {
      gaBtnClick(CTA_CLICK_EVENT, ANALYTICS_LINK_LOCATION_HEADER, buttonTitle)
      toggleSidebar()
    }
    return (
      <div className={styles['navbar-mobile']}>
        <div className={styles['header-mobile']}>
          <div className={styles['nav-drawer-toggler']}>
            {navDrawerIsOpen ? <CloseButton onClick={handleMenuClick} /> : <HamburgerButton onClick={handleMenuClick} />}
          </div>
          <div className={styles['header__brand-logo']}>{BrandLogo()}</div>
          <HeaderButton title={buttonTitle} url={buttonUrl} onClick={event => handleButtonClick(event, buttonUrl)} />
        </div>
        <div className={'align-contents-vertical'}>
          <div onClick={e => e.stopPropagation()} className={cx(styles['sidebar'], styles[navDrawerIsOpen ? 'open' : ''])} ref={sidebarRef}>
            <div className={styles['sidebar__scroller']}>
              <nav>
                {NavMenu()}
                {endNavItem && endNavItemUrl && (
                  <Link href={requestCameFrom ? constructURL(endNavItemUrl, { key: 'camefrom', value: requestCameFrom as string }) : endNavItemUrl}>
                    <div
                      className={styles['nav-link']}
                      onClick={() => {
                        push(endNavItemUrl)
                        toggleSidebar()
                      }}
                    >
                      <Image alt="live icon" src={live} />
                      <span>{endNavItem}</span>
                    </div>
                  </Link>
                )}
              </nav>
            </div>
          </div>
          <div className={cx(styles['overlay'], styles[navDrawerIsOpen ? 'open' : ''])} onClick={toggleSidebar} ref={overlayRef}></div>
        </div>
      </div>
    )
  }

  const getSelectedMenu = (item, asPath) => {
    let selectedMenu = item.url === asPath ? item.title : null
    if (!selectedMenu && item.subNavLinks && item.subNavLinks.length) {
      const [filteredItem] = item.subNavLinks.filter(subNav => subNav.url === asPath)
      selectedMenu = filteredItem ? item?.title : ''
    }
    return selectedMenu
  }

  const NavMenu = () => {
    return (
      <ul>
        {navigationMenuItems.map((item: NavigationMenuItemProps, parentIndex) => {
          if (item.title && item.url) {
            const hasSubmenu = item.subNavLinks && item.subNavLinks.length > 0
            const selectedMenu = getSelectedMenu(item, asPath)
            return (
              <li
                key={item.id}
                onClick={() => handleParentNavItemClick(item.id)}
                className={cx([
                  styles[openParentNavId === item.id && hasSubmenu ? 'is-open' : ''],
                  styles[selectedMenu === item?.title ? 'active' : '']
                ])}
              >
                <div className={styles['menu-item-wrapper']}>
                  <ConditionalWrapper
                    condition={!hasSubmenu}
                    wrapper={children => (
                      <Link
                        href={requestCameFrom ? `${item.url}?camefrom=${requestCameFrom}` : item.url}
                        target={item.isTargetBlank ? '_blank' : '_self'}
                        onClick={() => handleClick(item.title, item.url)}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    {activeLink(item)}
                  </ConditionalWrapper>

                  {hasSubmenu && isSmallerThanDesktop && (
                    <div className={styles['caret']}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  )}
                </div>
                {isSmallerThanDesktop ? (
                  <>
                    {hasSubmenu && !onChildPage && openParentNavId === item.id && <>{SubMenuForMobile(item, parentIndex)}</>}
                    {hasSubmenu && onChildPage && openParentNavId === item.id && <>{SubMenuForMobile(item, parentIndex)}</>}
                  </>
                ) : (
                  hasSubmenu && SubMenu(item, parentIndex)
                )}
              </li>
            )
          }
        })}
      </ul>
    )
  }

  return (
    <>
      <header
        ref={ref}
        onClick={closeDrawerHandler}
        className={cx([
          styles['header-global'],
          styles[isSmallerThanDesktop ? 'header-global--mobile' : 'header-global--desktop'],
          styles[navDrawerIsOpen && isSmallerThanDesktop ? 'drawer-open' : '']
        ])}
      >
        {isSmallerThanDesktop ? NavMobile() : NavDesktop()}
      </header>
    </>
  )
}

export default NavigationHeader
