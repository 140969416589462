import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { SeoProps } from '~types'
import { isProd } from '~constants'

const SEO = ({ allowSearchEnginesToShowThisPage, defaultTitle, ogImage, metaDescription, seoTitle, shouldSearchEnginesFollowLinks }: SeoProps) => {
  const [pageUrl, setPageUrl] = useState('/')

  useEffect(() => {
    setPageUrl(window.location.href)
  })

  const pageTitle = seoTitle || `${defaultTitle} | Sphere`

  const robotsValues = []

  const robotsIndex = allowSearchEnginesToShowThisPage && isProd ? 'index' : 'noindex'
  robotsValues.push(robotsIndex)

  const robotsFollow = shouldSearchEnginesFollowLinks && isProd ? 'follow' : 'nofollow'
  robotsValues.push(robotsFollow)

  return (
    <Head>
      <title>{pageTitle}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      <meta name="robots" content={robotsValues.join(', ')} />
      <link rel="canonical" href={pageUrl} />
      {ogImage && ogImage.url && <meta property="og:image" content={ogImage.url} />}
    </Head>
  )
}

export default SEO
