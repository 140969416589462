import React, { useState } from 'react'
import cx from 'classnames'
import styles from './ArtworkDetail.module.scss'
import { Col, Container, Row } from 'react-grid-system'
import { Breadcrumb, Button, Heading, Paragraph, ShareButton } from '~elements'
import { useWindowSize } from 'utils/hooks'
import { Video, StudentArtModal } from '@msgtechnology/sphere'
import { submitXOVote } from 'lib/voting'
import { XO_PATHNAME_TO_DISPLAYNAME } from '~constants'
import { CLICK_CONTENT } from '~analyticsConstants'
import { gaDLPush } from 'utils/hooks/gaDLPush'
import { useRouter } from 'next/router'

interface ArtworkDetailProps {
  description: {
    html: string
  }
  headline: string
  eyebrow: string
  videoDetails: {
    url: string
    posterImage: string
  }
  educationLevel: number
  sId: string
  schoolName: string
  artWorkTitle: string
  createdAt: string
  votingModalDisclaimer?: string
  votingModalReminder?: string
  isVotingOpen: () => boolean
}

const ArtworkDetail = ({
  description,
  headline,
  videoDetails,
  educationLevel,
  sId,
  schoolName,
  artWorkTitle,
  createdAt,
  votingModalReminder,
  votingModalDisclaimer,
  isVotingOpen
}: ArtworkDetailProps) => {
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const router = useRouter()
  const { query } = router

  const handleModalClose = () => setModalIsOpen(false)
  const handleModalOpen: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = () => {
    if (isVotingOpen()) {
      gaDLPush({
        event: CLICK_CONTENT,
        click_source: window.location.pathname,
        click_tracking: 'artwork_details:vote',
        click_type: 'artwork_details:button',
        link_location: 'body',
        link_module: 'artwork_details:button:vote',
        link_type: 'internal'
      })
      setModalIsOpen(true)
    }
  }
  const confirmationModalCloseAction = () => {
    if (query.slug && typeof query.slug === 'object') {
      const gallerySlug = query.slug.join('/')
      router.push(`/${gallerySlug}`)
    }
  }

  return (
    <Container className={styles['artwork-container']}>
      <Row>
        <Col>
          <div className={cx([styles['artwork-details-container']])}>
            <div className={styles['artwork-details']}>
              <div className={styles['headline-container']}>
                <div className={styles['eyebrow']}>
                  <Breadcrumb pathNameToDisplayName={XO_PATHNAME_TO_DISPLAYNAME} />
                </div>
                <div className={styles['headlinegap']}>
                  <Heading level={1} justify={'left'}>
                    <span className={styles['headline']}>{headline}</span>
                  </Heading>
                  {!isBelowBreakpointMd && (
                    <div className={styles['btn-container']}>
                      <ShareButton isFullWidth={false} title={headline} size="md" />
                      {isVotingOpen() ? (
                        <Button
                          color={'light'}
                          fill="hollow"
                          isFullWidth={false}
                          size={'md'}
                          title={'Vote'}
                          onClick={handleModalOpen}
                          justify="right"
                        />
                      ) : null}
                    </div>
                  )}
                </div>
                <div className={styles['description']}>
                  <Paragraph fontSize={isBelowBreakpointMd ? 'sm' : isBelowBreakpointLg ? 'md' : 'lg'} text={description} justify={'left'} />
                  {isBelowBreakpointMd && (
                    <div className={styles['btn-container-mobile']}>
                      <ShareButton isFullWidth={true} title={headline} />
                      {isVotingOpen() ? (
                        <Button
                          color={'light'}
                          fill="hollow"
                          isFullWidth={true}
                          size={isBelowBreakpointLg ? 'lg' : 'md'}
                          title={'Vote'}
                          onClick={handleModalOpen}
                          justify="right"
                        />
                      ) : null}
                    </div>
                  )}
                </div>

                <Video
                  id={sId}
                  video={{
                    url: videoDetails.url,
                    posterImage: videoDetails.posterImage
                  }}
                  displayThumbnail={false}
                  autoplay={true}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <StudentArtModal
        handleModalClose={handleModalClose}
        modalIsOpen={modalIsOpen}
        submitVote={submitXOVote}
        videoVoteInfo={{
          id: sId,
          educationLevel: educationLevel
        }}
        // This share button will appear in the confirmation modal after a user successfully submits their vote.
        shareButton={<ShareButton isFullWidth={true} title={headline} size="md" type={'result-modal'} />}
        reminder={votingModalReminder}
        disclaimer={votingModalDisclaimer}
        isVotingOpen={isVotingOpen}
        confirmationModalCloseAction={confirmationModalCloseAction}
      >
        <Video
          id={sId}
          video={{
            url: videoDetails.url,
            posterImage: videoDetails.posterImage
          }}
          displayThumbnail={false}
          autoplay={true}
          voteDetails={{
            studentName: headline,
            educationLevel: educationLevel,
            schoolName: schoolName,
            artWorkTitle: artWorkTitle,
            createdAt: createdAt,
            submitVote: submitXOVote,
            isVotingOpen: isVotingOpen
          }}
        />
      </StudentArtModal>
    </Container>
  )
}

export default ArtworkDetail
