import parse from 'html-react-parser'
import styles from './Classification.module.scss'
import Heading from '../Heading/Heading'
import Image from 'next/image'
import { ImageProps } from '~types'

interface ClassificationProps {
  classification: {
    classficiationTitle: string
    classficiationDescription: string
    classficiationRating: number
    classficiationRatingIcon: ImageProps
  }
}

const Classification = ({ classification }: ClassificationProps) => (
  <div className={styles['classification-container']}>
    <div>
      {[...Array(classification?.classficiationRating)].map(
        (rating, index) =>
          classification.classficiationRatingIcon && (
            <Image
              key={`star_rating_${index}`}
              loading="lazy"
              width={34}
              height={32}
              alt={`star_rating_${index}`}
              sizes="100vw"
              src={classification.classficiationRatingIcon.url}
            />
          )
      )}
    </div>
    {classification && (
      <blockquote>
        <Heading level={4} levelDisplay="eyebrow" color="accent">
          {classification?.classficiationTitle}
        </Heading>
        <div>{classification?.classficiationDescription && <p>{parse(classification?.classficiationDescription)}</p>}</div>
      </blockquote>
    )}
  </div>
)

export default Classification
