import React, { useEffect, useState } from 'react'
import { Heading, Paragraph, Tabs } from '~elements'
import { ParagraphProps } from '~types'
import { Col, Container, Row } from 'react-grid-system'
import styles from './CarouselFullScreen.module.scss'

interface CarouselFullScreenSlideProps {
  backgroundImage: { url: string }
  details: ParagraphProps
  tabTitle?: string
  title: string
}

interface CarouselFullScreenProps {
  eyebrow?: any
  slides: Array<CarouselFullScreenSlideProps>
}

const CarouselFullScreen = ({ eyebrow, slides }: CarouselFullScreenProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [activeBackgroundImage, setBackgroundImage] = useState<string>('')
  const [bgImages, setBgImages] = useState<Array<HTMLImageElement>>()
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index)
  }

  useEffect(() => {
    const images: Array<HTMLImageElement> = []
    slides.forEach((slide, index) => {
      const image = new Image()
      image.src = slide.backgroundImage.url
      images.push(image)
      if (index === 0) setBackgroundImage(image.src)
    })

    setBgImages(images)
  }, [])

  useEffect(() => {
    if (bgImages && bgImages.length > 0) {
      setBackgroundImage(bgImages[activeTabIndex].src)
    }
  }, [activeTabIndex, bgImages])

  useEffect(() => {
    const slideCount = slides.length - 1
    const interval = setInterval(() => {
      setActiveTabIndex(activeTabIndex === slideCount ? 0 : activeTabIndex + 1)
    }, 3500)
    return () => clearInterval(interval)
  }, [activeTabIndex])

  let tabs = []
  tabs = slides.map((slide: CarouselFullScreenSlideProps, i: number) => (tabs[i] = slide.tabTitle || ''))

  return (
    <div className={styles['carousel-fullscreen']}>
      <div
        className={styles['carousel-fullscreen__wrapper']}
        style={{
          backgroundImage: `url(${activeBackgroundImage})`
        }}
      >
        <Container className="container">
          <Row>
            <Col xxl={10} offset={{ xxl: 1 }} className={'padding-y-0-lg'}>
              <div className={styles['headlines']}>
                <div className={styles['headlines__wrapper']}>
                  {eyebrow && <Heading level={6}>{eyebrow}</Heading>}
                  {slides &&
                    slides.map(
                      (slide: CarouselFullScreenSlideProps, index: number) =>
                        activeTabIndex === index && (
                          <Heading level={2} key={index}>
                            {slide.title}
                          </Heading>
                        )
                    )}
                </div>
              </div>
              <Tabs labels={tabs} activeIndex={activeTabIndex} setParentIndex={setActiveTabIndex} />
            </Col>
          </Row>
        </Container>

        <div className={styles['content']}>
          <div className={styles['content__wrapper']}>
            <Container>
              <Row>
                <Col xxl={8} offset={{ xxl: 2 }} className={'padding-y-0-sm'}>
                  {slides &&
                    slides.map(
                      (slide: CarouselFullScreenSlideProps, index: number) =>
                        activeTabIndex === index && (
                          <React.Fragment key={index}>
                            <Paragraph justify="center" key={index} text={slide.details} />
                          </React.Fragment>
                        )
                    )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarouselFullScreen
