import React from 'react'
import cx from 'classnames'
import styles from './Heading.module.scss'
import { HeadingProps } from '~types'

const Heading = ({ children, color = 'lightest', justify = 'left', level, levelDisplay, title = '' }: HeadingProps) => {
  const classNames = cx([
    styles[`heading`],
    styles[`is-color-${color}`],
    styles[`level-${levelDisplay ? levelDisplay : level}`],
    styles[justify === 'center' ? `justify-${justify}` : '']
  ])
  const HeadingTag = `h${level === 'display' ? 1 : level}`

  return <HeadingTag className={classNames}>{children ? children : title}</HeadingTag>
}

export default Heading
