import React from 'react'
import { PressLink } from '~elements'
import { PressLinkProps } from '~types'
import styles from './PressLinks.module.scss'
import { Col } from 'react-grid-system'

interface PressLinksProps {
  items: Array<PressLinkProps>
}

const PressLinks = ({ items }: PressLinksProps) => {
  return (
    <Col xs={12}>
      {items &&
        items.map((pressLink: PressLinkProps) => (
          <PressLink attribution={pressLink.attribution} date={pressLink.date} title={pressLink.title} url={pressLink.url} key={pressLink.id} />
        ))}
    </Col>
  )
}

export default PressLinks
