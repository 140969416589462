import { usePathname } from 'next/navigation'
import styles from './Breadcrumb.module.scss'
import Link from 'next/link'
import React from 'react'

interface IBreadcrumbProps {
  separator?: string
  pathNameToDisplayName: { [key: string]: string }
}

function Breadcrumb({ separator = '/', pathNameToDisplayName }: IBreadcrumbProps) {
  const paths = usePathname()

  type PathNameType = keyof typeof pathNameToDisplayName

  const pathNames = paths!.split('/').filter(path => path) as PathNameType[]

  return (
    <div className={styles['breadcrumb-container']}>
      <ul>
        {pathNames.map((link, index) => {
          const href = `/${pathNames.slice(0, index + 1).join('/')}`

          const itemLink = pathNameToDisplayName[link]

          return (
            <React.Fragment key={`${link}-${index}`}>
              <li>
                <Link href={href}>{itemLink}</Link>
              </li>
              {pathNames.length !== index + 1 && separator}
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export default Breadcrumb
