import { useState } from 'react'
import { SWIPE_DISTANCE_LIMIT } from '~constants'

const swipeDistanceLimit = SWIPE_DISTANCE_LIMIT

export const useSwipe = (itemCount: number, onSwipeLeft: () => void, onSwipeRight: () => void) => {
  const [touchStartX, setTouchStartX] = useState(null)
  const [touchEndX, setTouchEndX] = useState(null)

  const handleTouchStart = e => {
    setTouchEndX(null)
    setTouchStartX(e.touches[0].clientX)
  }

  const handleTouchMove = e => {
    setTouchEndX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (!touchStartX || !touchEndX) return
    const distance = touchStartX - touchEndX
    const isSwipeLeft = distance > swipeDistanceLimit
    const isSwipeRight = distance < -swipeDistanceLimit

    if (itemCount > 1) {
      if (isSwipeLeft) {
        onSwipeLeft()
      } else if (isSwipeRight) {
        onSwipeRight()
      }
    }

    setTouchStartX(null)
    setTouchEndX(null)
  }

  return { handleTouchStart, handleTouchMove, handleTouchEnd }
}
