import { Row, Col } from 'react-grid-system'
import { ExperienceCard, Heading } from '~elements'
import { useWindowSize } from 'utils/hooks'
import { ShowsCardProps } from '~types'
import styles from './CardRow.module.scss'
import { useSwipe } from 'utils/hooks/useSwipe'
import { useState } from 'react'

interface CardRowProps {
  sectionHeadline?: string
  experienceCards: Array<ShowsCardProps>
}

const CardRow = ({ sectionHeadline, experienceCards }: CardRowProps) => {
  const colSpan = Math.max(12 / Math.min(experienceCards.length, 4), 2)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [activeIndex, setActiveIndex] = useState(0)
  const nextSlide = () => setActiveIndex(prevIndex => (prevIndex + 1) % experienceCards.length)
  const prevSlide = () => setActiveIndex(prevIndex => (prevIndex - 1 + experienceCards.length) % experienceCards.length)
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipe(experienceCards.length, nextSlide, prevSlide)

  return (
    <div className={styles['card-row-container']}>
      {sectionHeadline && (
        <Heading justify={'center'} level={2}>
          {sectionHeadline}
        </Heading>
      )}
      {isBelowBreakpointMd && experienceCards ? (
        <div className={styles['carousel-container']} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <ExperienceCard {...experienceCards[activeIndex]} key={activeIndex} />
          <div className={styles['indicator-position']}>
            {experienceCards.map((_, index) => (
              <span key={`indicator-${index}`} className={`${styles['indicator']} ${index === activeIndex ? styles['active-indicator'] : ''}`}></span>
            ))}
          </div>
        </div>
      ) : (
        <Row>
          {experienceCards.map((experienceCard, index) => (
            <Col key={index} xs={12} sm={12} lg={Math.floor(colSpan)}>
              <ExperienceCard {...experienceCard} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

export default CardRow
