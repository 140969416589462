import React from 'react'
import cx from 'classnames'
import styles from './Divider.module.scss'
import { Container } from 'react-grid-system'

interface DividerProps {
  height?: 1 | 2 | 3
  hideRule?: boolean
  dividerFullWidth?: boolean
}

const Divider = ({ height = 2, hideRule = false, dividerFullWidth = true }: DividerProps) => {
  const hrComponent = () => (
    <hr className={cx([styles['divider'], styles[`divider--height-${height}`], styles[hideRule ? 'divider--no-rule' : '']])} />
  )
  return <>{dividerFullWidth ? <>{hrComponent()}</> : <Container>{hrComponent()}</Container>}</>
}

export default Divider
