import React, { useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import styles from './RulesCard.module.scss'
import { Col, Container, Row } from 'react-grid-system'
import { Heading, Paragraph } from '~elements'
import { useWindowSize, useIsSmartphoneLandscape } from 'utils/hooks'

interface RulesCardProps {
  headline: string
  rulesCardArray: Array<{
    description: {
      html: string
    }
    image: {
      url: string
    }
  }>
}

interface SliderInterface {
  slickNext: () => void
  slickPrev: () => void
}

const RulesCard = ({ rulesCardArray, headline }: RulesCardProps) => {
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [touchX, setTouchX] = useState<number | null>(null)
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(null)
  const [activeTileIndex, setActiveTileIndex] = useState<number>(0)
  const sliderRef = useRef<SliderInterface | null>(null)
  const limitedItems = rulesCardArray.slice(0, 4)
  const [activeImage, setActiveImage] = useState<string>('')
  const [activeDescription, setActiveDescription] = useState({ html: '' })

  const swipeDistanceLimit = 50

  useEffect(() => {
    if (rulesCardArray[activeTileIndex]?.image?.url) {
      setActiveImage(rulesCardArray[activeTileIndex]['image']?.url)
    }
    if (rulesCardArray[activeTileIndex]?.description) {
      setActiveDescription(rulesCardArray[activeTileIndex]['description'])
    }
  }, [activeTileIndex])

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchX(e.touches[0].clientX)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (touchX && rulesCardArray.length > 1) {
      const xPosition = e.touches[0].clientX
      const distance = Math.abs(touchX - xPosition)
      if (distance > swipeDistanceLimit) {
        if (touchX > xPosition) {
          setSwipeDirection('left')
        } else {
          setSwipeDirection('right')
        }
      }
    }
  }

  const handleTouchEnd = () => {
    if (touchX && rulesCardArray.length > 1) {
      if (swipeDirection === 'right') {
        prevSlideHandler()
      } else if (swipeDirection === 'left') {
        nextSlideHandler()
      }
    }
    setTouchX(null)
    setSwipeDirection(null)
  }

  const prevSlideHandler = () => {
    const newIndex = activeTileIndex === 0 ? limitedItems.length - 1 : activeTileIndex - 1
    setActiveTileIndex(newIndex)
    sliderRef?.current?.slickPrev()
  }

  const nextSlideHandler = () => {
    const newIndex = activeTileIndex === limitedItems.length - 1 ? 0 : activeTileIndex + 1
    setActiveTileIndex(newIndex)
    sliderRef?.current?.slickNext()
  }

  return (
    <Container className={styles['container']}>
      <Row>
        <Col>
          <div className={styles['headline-container']}>
            {headline && (
              <Heading level={1} justify={isBelowBreakpointMd ? 'center' : 'left'}>
                <span className={styles['headline']}>{headline}</span>
              </Heading>
            )}
          </div>
          {isBelowBreakpointMd ? (
            <>
              <div
                className={cx([styles['rules-card-container']])}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
              >
                <div className={styles['image-container']}>
                  <img src={activeImage} alt={'item.text'} className={styles['tile-image']} />
                  <div className={styles['description-container']}>
                    {<Paragraph fontSize={'sm'} text={activeDescription} justify={isBelowBreakpointLg ? 'center' : 'left'} />}
                  </div>
                </div>
              </div>
              <div className={styles['carousel-container']}>
                <div className={styles['indicator-position']}>
                  {limitedItems.map((listItem, index) => (
                    <span
                      key={`indicator-${index}`}
                      className={`${styles['indicator']} ${index === activeTileIndex ? styles['active-indicator'] : ''}`}
                    ></span>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className={cx([styles['rules-card-container']])}>
              {rulesCardArray.map((item, index) => (
                <div className={styles['rules-card']}>
                  <div className={styles['image-container']}>
                    <img src={item.image.url} alt={'item.text'} className={styles['tile-image']} />
                  </div>
                  <div className={styles['description-container']}>
                    {item?.description && <Paragraph fontSize={'xl'} text={item.description} justify={isBelowBreakpointLg ? 'center' : 'left'} />}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default RulesCard
