import React from 'react'
import { Container, Row } from 'react-grid-system'
import { Divider } from '~elements'
import cx from 'classnames'
import styles from './Grid.module.scss'
import * as Elements from '~elements'
import * as Modules from '~modules'
import Heading from '../../elements/Heading/Heading'
import { BenefitsProps, CardMediaProps, ConstantMapProps } from '~types'

interface UrlProps {
  id: string
  url: string
  linktype: string
  fieldtype: string
  cached_url: string
}

interface RowProps {
  url: UrlProps
  _uid: string
  size: string
  title: string
  justify: string
  component: string
  isTargetBlank: boolean
  _editable: string
}

interface GridProps {
  analyticsLinkId?: string
  centerContentVertical: boolean
  constantsMap: Array<ConstantMapProps>
  gridType: string
  headline: string
  justifyContent?: 'Left' | 'Right' | 'Center' | 'Center_evenly_spaced'
  rows: Array<CardMediaProps | BenefitsProps | RowProps>
  showDividerAtBottom: boolean
  showDividerAtTop: boolean
}

const Grid = ({
  analyticsLinkId,
  centerContentVertical = false,
  constantsMap,
  gridType,
  headline,
  justifyContent,
  rows,
  showDividerAtBottom,
  showDividerAtTop
}: GridProps) => {
  const justifyContentMapped: string = constantsMap.justifyContentOptions[justifyContent]
    ? constantsMap.justifyContentOptions[justifyContent]
    : 'start'

  return (
    <div className={styles['grid-container']}>
      {showDividerAtTop && <Divider />}
      {headline && (
        <Heading level={'2'} justify={'center'}>
          <>{headline}</>
        </Heading>
      )}
      <Container className={'container'}>
        <div className={styles[`grid-type-${gridType}`]}>
          <Row justify={justifyContentMapped} className={centerContentVertical ? 'align-contents-vertical' : ''}>
            {rows &&
              rows.map((row, index) => {
                const DynamicComponent = Elements[gridType] || Modules[gridType]
                return DynamicComponent && <DynamicComponent key={index} {...row} constantsMap={constantsMap} analyticsLinkId={analyticsLinkId} />
              })}
          </Row>
        </div>
      </Container>
      {showDividerAtBottom && <Divider />}
    </div>
  )
}

export default Grid
