import React, { useState } from 'react'
import styles from './PauseButton.module.scss'

const PauseButton = () => {
  const [isHovered, setIsHovered] = useState(false)

  const labelText = 'pause video'

  return (
    <div
      className={styles['pause-button-wrapper']}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label={labelText}
      style={{ position: 'relative' }}
    >
      <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" fill="none">
        <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" fill="none" fillOpacity={isHovered ? '0.5' : '0.0'} />
        <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke={isHovered ? 'white' : 'grey'} />
        <rect x="17" y="15.7207" width="2" height="12" rx="1" fill={isHovered ? 'white' : '#919191'} />
        <rect x="24.12" y="15.7207" width="2" height="12" rx="1" fill={isHovered ? 'white' : '#919191'} />
      </svg>
      {isHovered && <div className={styles['tooltip']}>{labelText}</div>}
      <span className={styles['visually-hidden']}>{labelText}</span>
    </div>
  )
}

export default PauseButton
