import React from 'react'
import styles from './PressLink.module.scss'
import { Heading } from '~elements'
import Link from 'next/link'
import { PressLinkProps } from '~types'
import { convertDateToMDYYYY } from 'utils'
const PressLink = ({ attribution, date, title, url }: PressLinkProps) => {
  const formattedDate = convertDateToMDYYYY(date)
  return (
    <div className={styles['press-link']}>
      {formattedDate && <p>{formattedDate}</p>}
      <Heading level={4}>
        <Link href={url} target="_blank">
          {title}
        </Link>
      </Heading>
      <p>{attribution}</p>
    </div>
  )
}

export default PressLink
