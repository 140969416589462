import React, { useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import styles from './Vimeo.module.scss'
import Image from 'next/image'
import { JsonLD, PortalModal } from '~elements'
import { VimeoProps } from '~types'
import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_LOCATION_BODY, ANALYTICS_LINK_MODULE_VIDEO } from '~analyticsConstants'
import { AUTO_PLAY_URL } from '~constants'
import { isTouchDevice } from 'utils'
const Vimeo = ({
  isChildOfCard = false,
  isChildOfSmallCard = false,
  url,
  posterImage,
  externalUrl,
  videoTitle,
  videoDescription,
  thumbnailUrl,
  uploadDate
}: VimeoProps) => {
  const { asPath } = useRouter()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const analyticsLinkData = (title: string) => ({
    linkId: formatAnalyticsText(title),
    linkModule: ANALYTICS_LINK_MODULE_VIDEO,
    linkLocation: ANALYTICS_LINK_LOCATION_BODY
  })

  const handlePosterClick = (alt: string) => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(alt))
    if (externalUrl) {
      window.open(externalUrl, '_blank')
    } else {
      setModalIsOpen(true)
    }
  }

  const handleModalClose = () => setModalIsOpen(false)
  const [videoHeight, setVideoHeight] = useState<number>()
  const ratio = 0.5625
  const modalPaddingOffset = 60

  // Dynamically determine iframe height. Without this modal will not close on overlay click.
  const handleChangeVideoWidth = useCallback(() => {
    const height = (window.innerWidth - modalPaddingOffset) * ratio
    return setVideoHeight(height)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleChangeVideoWidth)
    const height = (window.innerWidth - modalPaddingOffset) * ratio
    setVideoHeight(height)

    return () => {
      window.removeEventListener('resize', handleChangeVideoWidth)
    }
  }, [videoHeight, handleChangeVideoWidth])

  const vimeoIframeHTML = (url: string) => {
    return (
      <div className={styles[!isChildOfCard ? 'vimeo-container' : '']}>
        <div className={styles['iframe-container']}>
          <iframe
            src={url}
            width="100%"
            height={posterImage && videoHeight}
            frameBorder="0"
            allow="autoplay; encrypted-media; fullscreen"
            allowFullScreen={true}
          />
        </div>
      </div>
    )
  }

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: videoTitle,
    description: videoDescription,
    thumbnailUrl: thumbnailUrl,
    uploadDate: uploadDate,
    contentUrl: url
  }
  return (
    <>
      <JsonLD data={structuredData} />
      {url && posterImage?.fileName && !isTouchDevice() ? (
        <div className={styles['poster-container']}>
          <div className={'image-container'}>
            <Image
              alt="Vimeo Video Poster"
              src={posterImage.url}
              onClick={() => handlePosterClick(posterImage.fileName)}
              width="0"
              height="0"
              sizes="100vw"
            />
          </div>
          <button
            onClick={() => handlePosterClick(posterImage.fileName)}
            className={cx([styles['modal-open-button'], styles[isChildOfSmallCard ? 'is-smaller' : '']])}
          >
            <i className={styles['play-icon']} />
          </button>
          <PortalModal contentWidth="wide" isOpen={modalIsOpen} handleModalClose={handleModalClose}>
            {vimeoIframeHTML(url + AUTO_PLAY_URL)}
          </PortalModal>
        </div>
      ) : url ? (
        vimeoIframeHTML(url)
      ) : (
        ''
      )}
    </>
  )
}
export default Vimeo
