import React from 'react'
import { Container, Row } from 'react-grid-system'
import { Location } from '~elements'
import { LocationProps } from '~types'

interface LocationsProps {
  items: Array<LocationProps>
}

const Locations = ({ items }: LocationsProps) => {
  return (
    <>
      {items.length && (
        <Container className={'container'}>
          <Row>
            {items.map((location: LocationProps, index: number) => (
              <Location ctaText={location.ctaText} title={location.title} url={location.url} key={index} isLastChild={items.length === index + 1} />
            ))}
          </Row>
        </Container>
      )}
    </>
  )
}

export default Locations
