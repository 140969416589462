import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import cx from 'classnames'
import { Button, Heading, PortalModal, Paragraph } from '~elements'
import { ButtonProps, ImageProps, VimeoProps } from '~types'
import styles from './HeroEdpHeader.module.scss'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'

interface HeroEdpHeaderProps {
  backgroundImage?: ImageProps
  buttonGroup: Array<ButtonProps>
  ctaSection?: Array<ctaSectionProps>
  description: {
    html: string
  }
  readMoreField: {
    html: string
  }
  detailSection: VimeoProps
  edpHeroVideo: {
    url: String
  }
  videoPosterImage: string
  isShort?: boolean
  posterImage: {
    url: String
  }
  subHeadline: string
  subTitle?: string
  title: string
  showTrailer: {
    url: String
  }
  infiniteVideoLoop: boolean
  showEdpVideoHeader: boolean
  businessUnit?: string
}

interface ctaSectionProps {
  buttonText: string
  categories: Array<string>
  component: string
  id: string
  locations: Array<string>
  urlBase: string
}

const VIDEO_PLAYBACK_MODE = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

const HeroEdpHeader = ({
  buttonGroup,
  description,
  readMoreField,
  detailSection,
  edpHeroVideo,
  infiniteVideoLoop = false,
  posterImage,
  subHeadline = '',
  videoPosterImage,
  subTitle = '',
  showEdpVideoHeader,
  title,
  businessUnit
}: HeroEdpHeaderProps) => {
  const isEdpHero = detailSection && subTitle

  const edpHeroVideoRef = useRef<HTMLVideoElement>(null)
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(true)
  const [videoPlaybackMode, setVideoPlaybackMode] = useState<string>('')
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)
  const toggleReadMore = () => {
    setReadMoreIsOpen(!readMoreIsOpen)
  }

  useEffect(() => {
    if (edpHeroVideoRef && edpHeroVideoRef.current) {
      edpHeroVideoRef.current
        .play()
        .then(() => {
          edpHeroVideoRef.current.poster = ''
          setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)
        })
        .catch(() => {
          /* Automatic playback failed */
          setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.FAILED)
        })
    }
  }, [edpHeroVideoRef])

  const handleVideoEnd = () => {
    setVideoIsPlaying(false)
  }

  const { query } = useRouter()
  const [source] = [...(query.slug as string[])].reverse()

  const handleButtonClick = (buttonProps: ButtonProps, isScrollIntoView: boolean) => {
    if (isScrollIntoView) {
      const targetElement = buttonProps.anchorLink ? document.getElementById(buttonProps.anchorLink) : null
      if (buttonProps && buttonProps.anchorLink && targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }

    gaBuyTicketsClick({
      businessUnit,
      buyType: buttonProps.buyType,
      clickSource: source ?? '',
      clickTracking: `${window.location.href} hero_edp_header:buy_tickets`,
      eventDate: '',
      eventVenueName: 'sphere_vegas',
      eventCategory: 'other',
      eventType: 'other events',
      eventDataStatus: '',
      linkLocation: HeroEdpHeader.displayName,
      linkPosition: '',
      ticketmasterEventId: '',
      cmsIdentifier: buttonProps.cmsIdentifier,
      buttonPlacementDescription: buttonProps.buttonPlacementDescription
    })
  }

  const handleVideoPlay = () => setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)

  const handlePlaying = () => setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)

  return (
    <>
      <div className={cx([styles['hero-container'], styles['has-details-and-subhead']])}>
        <div className={styles['hero']}>
          <Container className={'container container--half-padding-xs'}>
            <Row>
              <Col className={'margin-top-auto'} xl={detailSection && detailSection.url ? 7 : 12} lg={detailSection && detailSection.url ? 6 : 12}>
                <div className={styles['header-title-container']}>
                  <div>
                    {title && <Heading level={1}>{title}</Heading>}
                    {subHeadline && (
                      <Heading level={2} levelDisplay="eyebrow" color="accent">
                        {subHeadline}
                      </Heading>
                    )}
                  </div>
                  {buttonGroup && (
                    <div className={styles['btn-container']}>
                      {buttonGroup.map((buttonProps, index) => (
                        <Button
                          key={buttonProps.title}
                          color={buttonProps.bgColor}
                          fill={buttonProps.fill}
                          size={buttonProps.size}
                          title={buttonProps.title}
                          {...(buttonProps.anchorLink
                            ? { onClick: () => handleButtonClick(buttonProps, true) }
                            : { ...buttonProps, onClick: () => handleButtonClick(buttonProps, false) })}
                        />
                      ))}
                    </div>
                  )}
                </div>
                {description && (
                  <div className={styles['description-container']}>
                    <Paragraph text={description} fontSize={isEdpHero ? 'md' : 'lg'} />
                  </div>
                )}
                {readMoreField && description && (
                  <div>
                    {readMoreIsOpen && <Paragraph text={readMoreField} fontSize={isEdpHero ? 'md' : 'lg'} />}
                    <button onClick={toggleReadMore} className={styles['read-or-hide']}>
                      {readMoreIsOpen ? 'Read Less' : 'Read More'}
                      <div className={cx([styles['caret'], styles[readMoreIsOpen ? '' : 'is-open']])} />
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {posterImage && posterImage.url && !showEdpVideoHeader && (
          <div className={styles['hero-container__bg-img']} style={{ backgroundImage: `url(${posterImage?.url})` }} />
        )}
        {edpHeroVideo && showEdpVideoHeader && (
          <div className={styles['hero-container__bg-video']}>
            <video
              loop={infiniteVideoLoop}
              ref={edpHeroVideoRef}
              onEnded={handleVideoEnd}
              onPlay={handleVideoPlay}
              onTimeUpdate={handlePlaying}
              autoPlay={true}
              muted
              playsInline={true}
              poster={videoPosterImage?.url}
              {...(videoPlaybackMode === VIDEO_PLAYBACK_MODE.FAILED ? {} : { className: styles['video-without-pointer-event'] })}
            >
              <source src={edpHeroVideo.url} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
    </>
  )
}

HeroEdpHeader.displayName = 'hero_edp_header'

export default HeroEdpHeader
