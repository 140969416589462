import React, { useCallback, useEffect, useState } from 'react'
import { VIDEO_RATIO } from '~constants'
import Image from 'next/image'
import styles from './IframeVideo.module.scss'
import { JsonLD } from '~elements'
import { getCustomBreakpoint } from 'utils/hooks'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'

interface IframeVideoProps {
  width: number
  height: number
  url: string
  title: string
  thumbnailUrl?: string
  uploadDate?: string
  endDate?: string
  posterImage: {
    posterImageXl: { url: string }
    posterImageMd: { url: string }
    posterImageSm: { url: string }
  }
  isLiveStreamOn: boolean
}

const IframeVideo = ({ height, url, title, uploadDate, endDate, thumbnailUrl, posterImage, isLiveStreamOn }: IframeVideoProps) => {
  const [videoHeight, setVideoHeight] = useState<number>()
  const modalPaddingOffset = 60

  const handleChangeVideoWidth = useCallback(() => {
    const height = (window.innerWidth - modalPaddingOffset) * VIDEO_RATIO
    return setVideoHeight(height)
  }, [])

  const { posterImageXl, posterImageMd, posterImageSm } = posterImage
  const { isBreakpoint: isAboveBreakpointMd } = getCustomBreakpoint(+breakpoints.md, '>')
  const { isBreakpoint: isAboveBreakpointXL } = getCustomBreakpoint(+breakpoints.xl, '>')

  const posterImageLocal = isAboveBreakpointXL ? posterImageXl : isAboveBreakpointMd ? posterImageMd : posterImageSm

  useEffect(() => {
    window.addEventListener('resize', handleChangeVideoWidth)
    const height = (window.innerWidth - modalPaddingOffset) * VIDEO_RATIO
    setVideoHeight(height)

    return () => {
      window.removeEventListener('resize', handleChangeVideoWidth)
    }
  }, [videoHeight, handleChangeVideoWidth])

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: title,
    thumbnailUrl: thumbnailUrl,
    uploadDate: uploadDate,
    contentUrl: url,
    publication: {
      '@type': 'BroadcastEvent',
      name: title,
      isLiveBroadcast: true,
      startDate: uploadDate,
      endDate: endDate
    }
  }
  return (
    <>
      <JsonLD data={structuredData} />
      <div className={styles['container']}>
        <div>
          {url && isLiveStreamOn ? (
            <div className={styles['iframe-container']}>
              <iframe
                width="100%"
                height={height}
                src={url}
                title={title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          ) : (
            <div className={styles['poster-image']}>
              <div className={'image-container'}>
                <Image alt="Stream Not Available" src={posterImageLocal?.url} width="0" height="0" sizes="100vw" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default IframeVideo
