import React, { useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import styles from './CareerForm.module.scss'
import OutsideClickHandler from 'react-outside-click-handler'
import { Button } from '~elements'
import { fireAnalytics, setLinkId, setCareerFormLinkId, getPageType } from '~analyticsUtils'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_LINK_MODULE_FILTER,
  ANALYTICS_LINK_LOCATION_BODY,
  ANALYTICS_LINK_ID_CATEGORY,
  ANALYTICS_LINK_ID_LOCATION
} from '~analyticsConstants'

interface CareerFormProps {
  buttonText: string
  categories: [
    {
      options: Array<{ label: string; value: string }>
    }
  ]
  locations: [
    {
      options: Array<{ label: string; value: string }>
    }
  ]
}

const CareerForm = ({ careerFormCta, categories, locations }: CareerFormProps) => {
  const { url } = careerFormCta
  const { asPath } = useRouter()
  // Take first options as default values
  const jobCategories = categories ? categories[0].options : []
  const jobLocations = locations ? locations[0].options : []
  const { label: categoryDefault = '' } = jobCategories ? jobCategories[0] : {}
  const { label: locationDefault = '' } = jobLocations ? jobLocations[0] : {}

  const [categoriesAreOpen, showCategoryOptions] = useState(false)
  const [locationsAreOpen, showLocationOptions] = useState(false)

  const [activeCategoryText, handleActiveCategoryText] = useState(categoryDefault)
  const [activeLocationText, handleActiveLocationText] = useState(locationDefault)

  const [activeCategoryUrlParam, handleActiveCategoryUrlParam] = useState('')
  const [activeLocationUrlParam, handleActiveLocationUrlParam] = useState('')

  const toggleOptions = (type: 'category' | 'location') => {
    if (type == 'category') {
      if (categoriesAreOpen) {
        showCategoryOptions(false)
      } else {
        showCategoryOptions(true)
        showLocationOptions(false)
      }
    } else if (type == 'location') {
      if (locationsAreOpen) {
        showLocationOptions(false)
      } else {
        showLocationOptions(true)
        showCategoryOptions(false)
      }
    }
  }

  const closeAllOptions = () => {
    showLocationOptions(false)
    showCategoryOptions(false)
  }

  const commonAnalyticsLinkData = {
    linkModule: ANALYTICS_LINK_MODULE_FILTER,
    linkLocation: ANALYTICS_LINK_LOCATION_BODY
  }

  const linkId = setCareerFormLinkId(activeCategoryText, activeLocationText)

  const setActiveCategory = (e: React.MouseEvent<HTMLLIElement>) => {
    const text = (e.target as HTMLLIElement).innerText
    const param = (e.target as HTMLLIElement).getAttribute('data-id')!
    handleActiveCategoryText(text)
    handleActiveCategoryUrlParam(param ?? '')
    showCategoryOptions(false)
    const linkData = setLinkId(commonAnalyticsLinkData, text, ANALYTICS_LINK_ID_CATEGORY)
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), linkData)
  }

  const setActiveLocation = (e: React.MouseEvent<HTMLLIElement>) => {
    const text = (e.target as HTMLLIElement).innerText
    const param = (e.target as HTMLLIElement).getAttribute('data-id')!
    handleActiveLocationText(text)
    handleActiveLocationUrlParam(param ?? '')
    showLocationOptions(false)
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), setLinkId(commonAnalyticsLinkData, text, ANALYTICS_LINK_ID_LOCATION))
  }

  const submitCareerForm = (text: string) => {
    window.open(`${url}?categories=${activeCategoryUrlParam}&locations=${activeLocationUrlParam}`, '_blank')
  }

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeAllOptions()
        }}
      >
        <div className={styles['career-form']}>
          <div className={styles['career-form-input']}>
            <div onClick={() => toggleOptions('category')} className={cx([styles['options-toggler'], styles[categoriesAreOpen ? 'open' : '']])}>
              {activeCategoryText}
            </div>
            {categoriesAreOpen && jobCategories.length && (
              <div className={styles['options-container']}>
                <ul>
                  {jobCategories.map((category, i: number) => (
                    <li
                      className={cx([styles['option'], styles[activeCategoryText == category.label ? 'active' : '']])}
                      data-id={category.value}
                      key={i}
                      onClick={e => setActiveCategory(e)}
                    >
                      {category.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className={styles['career-form-input']}>
            <div onClick={() => toggleOptions('location')} className={cx([styles['options-toggler'], styles[locationsAreOpen ? 'open' : '']])}>
              {activeLocationText}
            </div>
            {locationsAreOpen && jobLocations.length && (
              <div className={styles['options-container']}>
                <ul>
                  {jobLocations.map((location, i: number) => (
                    <li
                      className={cx([styles['option'], styles[activeLocationText == location.label ? 'active' : '']])}
                      data-id={location.value}
                      key={i}
                      onClick={e => setActiveLocation(e)}
                    >
                      {location.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <Button
            color="primary"
            fill="solid"
            linkId={linkId}
            linkModule={ANALYTICS_LINK_MODULE_FILTER}
            linkLocation={ANALYTICS_LINK_LOCATION_BODY}
            onClick={() => submitCareerForm(careerFormCta.title)}
            size="lg"
            title={careerFormCta.title}
          />
        </div>
      </OutsideClickHandler>
    </>
  )
}

export default CareerForm
