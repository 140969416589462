import { memo, useEffect, useState } from 'react'
import cx from 'classnames'

import CalendarFilter from '../../elements/CalendarFilter'
import { getFilteredCalendarData } from '../../shows-calendar-services'
import ViewButtonGroup from '../../elements/ViewButtonGroup'
import { CalendarViewProps, ListViewCalendarDataProps } from 'types/calendar'
import MonthViewNav from '../MonthView/MonthViewNav/MonthViewNav'
import styles from '../../ShowsCalendar.module.scss'
import moment from 'moment-timezone'
import MonthViewHeader from '../MonthView/MonthViewHeader/MonthViewHeader'
import MonthViewEventMonth from '../MonthView/MonthViewEventMonth/MonthViewEventMonth'

const PreviousIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
    <path d="M6.18687 12.5L0.197754 6.51086L6.18687 0.5L6.80233 1.11869L1.4319 6.51086L6.80233 11.8813L6.18687 12.5Z" fill="white" />
  </svg>
)

const NextIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
    <path d="M5.56819 6.51086L0.197754 1.11869L0.813216 0.5L6.80233 6.51086L0.813216 12.5L0.197754 11.8813L5.56819 6.51086Z" fill="white" />
  </svg>
)

const CalendarView = memo(
  ({
    calendarList: calendarListProps,
    activeView,
    artistDetails,
    calendarViews,
    selectedMonth,
    calendarFilterItems,
    setActiveView,
    handleMonthNavChange,
    setHasViewChanged,
    handleDateSelection,
    loadCalendarData,
    activeMonthList,
    month,
    seasonStartDate: seasonStartDateProps,
    seasonEndDate: seasonEndDateProps,
    disabled
  }: CalendarViewProps) => {
    const [filteredCalendarData, setFilteredCalendarData] = useState<ListViewCalendarDataProps | null>(null)
    const isBeforeCurrentMonth = month?.isBefore(moment())

    const handleMonthChange = (direction: string, scrollTop: boolean) => {
      const currentMonth = moment(month).startOf('month')
      if (direction === 'next') {
        const nextMonth = currentMonth.add(1, 'month')
        loadCalendarData(nextMonth)
      } else if (direction === 'previous') {
        const prevMonth = currentMonth.subtract(1, 'month')
        loadCalendarData(prevMonth)
      }
    }

    const handleTodayClick = () => {
      const today = moment().startOf('month')
      loadCalendarData(today)
    }

    useEffect(() => {
      if (calendarListProps && calendarListProps.length > 0) {
        const { calendarDataList } = getFilteredCalendarData(calendarListProps)
        setFilteredCalendarData(calendarDataList)
      }
    }, [calendarListProps])

    return (
      <div className={cx([styles['calendar__months'], styles['calendar__grid']])}>
        <div className={styles['calendar__month']}>
          <div className={cx([styles['calendar__month-navs'], styles['calendar__month-navs--top']])}>
            <button title="Today" onClick={handleTodayClick} className={styles['calendar__today-button']}>
              Today
            </button>
            <div className={styles['calendar__divider']} />
            <CalendarFilter
              calendarFilterItems={calendarFilterItems}
              handleMonthNavChange={handleMonthNavChange}
              selectedMonth={selectedMonth}
              loadCalendarData={loadCalendarData}
              activeMonthList={activeMonthList}
            />
            <div className={styles['calendar-nav-buttons']}>
              <div className={styles['previous']}>
                <button onClick={() => handleMonthChange('previous')} aria-label="Previous Month" disabled={isBeforeCurrentMonth}>
                  {PreviousIcon}
                </button>
              </div>
              <div className={styles['next']}>
                <button onClick={() => handleMonthChange('next')} className={styles.calendarNavButton} aria-label="Next Month" disabled={disabled}>
                  {NextIcon}
                </button>
              </div>
            </div>
            {calendarViews && (
              <ViewButtonGroup options={calendarViews} activeView={activeView} setActiveView={setActiveView} setHasViewChanged={setHasViewChanged} />
            )}
          </div>

          {calendarListProps && filteredCalendarData && (
            <div className={cx([styles['calendar__months'], styles['calendar__grid']])}>
              <MonthViewHeader />
              <MonthViewEventMonth
                calendarList={calendarListProps}
                handleDateSelection={handleDateSelection}
                month={month}
                artistDetails={artistDetails}
                hidePriceRangeInitialView={false}
                onDateClick={function (selectedDate: string): void {
                  throw new Error('Function not implemented.')
                }}
                selectedDate={null}
              />
            </div>
          )}

          <MonthViewNav
            hideNavTitle={true}
            month={month}
            calendarViews={calendarViews}
            prevMonth={() => handleMonthChange('previous', true)}
            nextMonth={() => handleMonthChange('next', true)}
            seasonStartDate={seasonStartDateProps}
            seasonEndDate={seasonEndDateProps}
            position="bottom"
            activeView={activeView}
            setActiveView={setActiveView}
            setHasViewChanged={setHasViewChanged}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
)

CalendarView.displayName = 'CalendarView'

export default CalendarView
