import { ReactElement } from 'react'
import moment from 'moment'

import styles from '../../../ShowsCalendar.module.scss'

import { DATE_FORMAT, DAYS_OF_THE_WEEK } from '~constants'

const MonthViewHeader = () => {
  const daysOfTheWeek: Array<ReactElement> = DAYS_OF_THE_WEEK.map((dayOfTheWeek: number) => {
    const formattedDay = moment().weekday(dayOfTheWeek).format(DATE_FORMAT.DAY_OF_WEEK_FULL_NAME)

    return (
      <div className={styles['calendar__day-name-label']} key={dayOfTheWeek}>
        {formattedDay}
      </div>
    )
  })

  return <div className={styles['calendar__day-name-labels']}>{daysOfTheWeek}</div>
}

export default MonthViewHeader
