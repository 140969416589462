import React, { useState, useRef } from 'react'
import cx from 'classnames'
import styles from './ImageLink.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useOnScreen } from 'utils/hooks'
import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_LOCATION_FOOTER, ANALYTICS_LINK_MODULE_CTA } from '~analyticsConstants'
import { ImageProps } from '~types'

interface ImageLinkProps {
  fadesIn: boolean
  img: ImageProps
  imageWidth?: number
  isTargetBlank?: boolean
  analyticsLinkId: string
  title: string
  url: string
}

const ImageLink = ({ analyticsLinkId, img, imageWidth, isTargetBlank = false, url, fadesIn = false, title }: ImageLinkProps) => {
  const { asPath } = useRouter()
  const [imageNaturalWidth, setImageNaturalWidth] = useState(0)
  const domRef = useRef<HTMLDivElement | null>(null)
  const inView = useOnScreen(domRef, true)
  const linkId = `${formatAnalyticsText(analyticsLinkId)}:${formatAnalyticsText(title)}`

  const handleImageWidth = ({ currentTarget: img }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth } = img
    setImageNaturalWidth(naturalWidth)
  }

  const analyticsClickHandler = () => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), {
      linkId,
      linkModule: ANALYTICS_LINK_MODULE_CTA,
      linkLocation: ANALYTICS_LINK_LOCATION_FOOTER
    })
  }

  const classNames = cx([styles['image-link'], styles[fadesIn ? 'pop-in' : ''], styles[inView && fadesIn ? 'has-popped-in' : '']])

  const imgWidth = imageWidth ? `${imageWidth}px` : imageNaturalWidth
  return (
    <div className={classNames} ref={domRef} onClick={analyticsClickHandler}>
      {img.image && (
        <div className="image-container" style={{ maxWidth: imgWidth, width: imgWidth }}>
          <Link href={url} target={isTargetBlank ? '_blank' : '_self'}>
            {img.alt && <Image src={img.image.url} alt={img.alt} onLoad={handleImageWidth} width="0" height="0" sizes="100vw" />}
          </Link>
        </div>
      )}
    </div>
  )
}

export default ImageLink
