import React, { useState } from 'react'
import parse from 'html-react-parser'
import { Container } from 'react-grid-system'
import styles from './StickyDisclaimer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

interface StickyDisclaimerProps {
  copy: { html: string }
}

const StickyDisclaimer = ({ copy, handleStickyDisclaimerCloseClick }: StickyDisclaimerProps) => {
  const htmlParseVal = parse(copy.html)
  const [visible, setVisible] = useState(true)

  const closeSticky = () => {
    handleStickyDisclaimerCloseClick(true)
    setVisible(false)
  }

  return (
    <>
      {visible && (
        <Container className={styles['disclaimer-container']}>
          <div className={styles['sticky-disclaimer']} id="disclaimer-container">
            {htmlParseVal}
            <FontAwesomeIcon icon={faXmark} style={{ fontSize: '8px' }} onClick={closeSticky} />
          </div>
        </Container>
      )}
    </>
  )
}

export default StickyDisclaimer
