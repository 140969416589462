import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { Container } from 'react-grid-system'
import styles from './HeaderStandalone.module.scss'
import { useWindowSize } from 'utils/hooks'

interface HeaderProps {
  logoDark: {
    url: string
    fileName: string
  }
  logoLight: {
    url: string
    fileName: string
  }
}

const HeaderStandalone = ({ logoDark, logoLight }: HeaderProps) => {
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')

  const BrandLogo = (color = 'light') => {
    return (
      <div className={styles['brand-logo']}>
        {logoLight && logoDark && (
          <div className={'image-container'}>
            <Image src={color == 'light' ? logoLight.url : logoDark.url} alt="Sphere" width="0" height="0" sizes="100vw" priority />
          </div>
        )}
      </div>
    )
  }

  const NavDesktop = () => {
    return (
      <Container className={'container container--width-100'}>
        <div className={styles['header-global__column']}>
          <div>{BrandLogo('light')}</div>
        </div>
      </Container>
    )
  }

  const NavMobile = () => {
    return (
      <div className={styles['header-global__column']}>
        <div className={'align-contents-vertical'}>{BrandLogo()}</div>
      </div>
    )
  }

  return (
    <header className={cx([styles['header-global'], styles['header-global--mobile']])}>{isBelowBreakpointLg ? NavMobile() : NavDesktop()}</header>
  )
}

export default HeaderStandalone
