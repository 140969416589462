import React from 'react'
import { CarouselSlideProps } from '~types'
import { PortalModal } from '~elements'

import styles from './CarouselSlide.module.scss'

const CarouselSlide = ({ img: { image, alt: imageAlt }, title, description, showModal = false, handleImageClick }: CarouselSlideProps) => {
  const ImageComponent = () => {
    return <img src={image.url} alt={imageAlt} />
  }

  return (
    <div className={styles.slide}>
      {image && imageAlt && (
        <div className={styles['image-container']} onClick={handleImageClick}>
          {ImageComponent()}
        </div>
      )}
      <p>{title}</p>
      {description && <p>{description}</p>}
      {showModal && image && imageAlt && (
        <PortalModal handleModalClose={handleImageClick} isOpen={showModal}>
          {ImageComponent()}
        </PortalModal>
      )}
    </div>
  )
}

export default CarouselSlide
