import { GalleryProps, EducationLevels } from '~types'
import styles from './Gallery.module.scss'
import { EDUCATION_LEVELS, THUMBNAILS_PER_PAGE } from '~constants'
import { Gallery as GalleryComponent } from '@msgtechnology/sphere'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { Leaderboard, Paragraph, ShareButton } from '~elements'
import { fetchSubmissionsByGroup, submitXOVote } from 'lib/voting'
import { IArtWorkResponse, IArtworkWithImage } from 'types/voting'
import ArtworkDetail from '../ArtworkDetail/ArtworkDetail'
import Hero from '../Hero/Hero'
import { isPastDeadline } from 'utils'

interface INextJSOptions {
  isReady: boolean
  onPushNextRouter: (url: string, as?: string | undefined, options?: any) => Promise<boolean>
  onReplaceNextRouter: (url: string, as?: string | undefined, options?: any) => Promise<boolean>
  page: string | string[] | undefined
  slug: string | string[] | undefined
}

interface IDataState {
  data: IArtWorkResponse[]
  isLoading: boolean
}

const Gallery = ({
  id,
  galleryPlaylist,
  activeTab,
  tabLabels,
  resultsHeading,
  filterHeading,
  filterValue,
  sortByHeading,
  sortByOptions,
  leaderboardHeading,
  leaderboardImage,
  heroTitle,
  heroDescription,
  heroBgImage,
  votingModalReminder,
  votingModalDisclaimer,
  votingDeadline
}: GalleryProps) => {
  const router = useRouter()
  const [artworkDetailsData, setArtworkDetailsData] = useState<IArtWorkResponse | undefined>(undefined)
  const { query } = router
  const INITIAL_STATE: INextJSOptions = {
    isReady: router.isReady,
    onPushNextRouter: router.push,
    onReplaceNextRouter: router.replace,
    page: query.page,
    slug: query.slug
  }

  const [nextJSOptions, setNextJSOptions] = useState<INextJSOptions>(INITIAL_STATE)
  const [state, setState] = useState<IDataState>({
    data: [],
    isLoading: true
  })
  const [selectedTab, setSelectedTab] = useState<string | undefined>(activeTab)
  const [showGallery, setShowGallery] = useState<boolean>(false)
  const [votingOpen, setVotingOpen] = useState<boolean>(() => {
    if (votingDeadline) {
      return !isPastDeadline(votingDeadline)
    } else {
      return false //if no deadline is provided, voting is closed
    }
  })

  const createOptimizedImages = (data: IArtWorkResponse[]): IArtworkWithImage[] => {
    return data.map((video: IArtworkWithImage) => {
      video.nextImage = video.video.posterImage ? (
        <Image key={video.sId} src={video.video.posterImage} width="0" height="0" sizes="100vw" alt="Video thumbnail" />
      ) : undefined
      return video
    })
  }

  const getSingleSubmission = (sid: string | string[]) => {
    const data = state.data.find(video => video.sId === sid)
    setArtworkDetailsData(data)
    setState({ ...state, isLoading: false })
  }

  useEffect(() => {
    if (router.isReady && !query.sId) {
      setShowGallery(true)
    } else {
      setShowGallery(false)
    }
  }, [router.isReady, query.sId])

  useEffect(() => {
    if (!router.isReady) {
      return
    }
    const { sId } = query
    if (sId && state.data.length > 0) {
      getSingleSubmission(sId)
    } else if (query.slug && query.slug[1]) {
      //When using browser navigation, activeTab doesn't automatically update, so we have to check the slug
      //If it doesn't match the selectedTab, we reset the selectedTab to match the query parameter
      const active = activeTab?.replace('_', '-').toLowerCase()
      if (query.slug[1] !== active) {
        const newTab = query.slug[1].replace('-', '_')
        const idx = tabLabels?.findIndex(tab => tab.toLowerCase() === newTab)
        if (tabLabels && idx !== undefined) {
          setSelectedTab(tabLabels[idx])
        }
      } else {
        setSelectedTab(activeTab)
      }
    }

    //The ArtworkDetail component renders when artworkDetailsData is set, so we have to unset it
    //when there is no sId query parameter. This is what makes the gallery render instead.
    if (!sId) {
      setArtworkDetailsData(prevArtWorkDetails => (prevArtWorkDetails ? undefined : prevArtWorkDetails))
    }

    //Change nextJSOoptions.slug (which causes a rerender) only if the second slug changes
    setNextJSOptions((prevState: INextJSOptions) => {
      if (prevState.slug && query.slug) {
        if (prevState.slug[1] && query.slug[1]) {
          if (prevState.slug[1] !== query.slug[1]) {
            setState({ ...state, isLoading: true })
            if (votingDeadline) {
              setVotingOpen(!isPastDeadline(votingDeadline))
            }
            return { ...prevState, slug: query.slug }
          }
        }
      }
      return prevState
    })
  }, [query.sId, query.slug, activeTab, router.isReady, state.isLoading])

  useEffect(() => {
    if (state.isLoading) {
      ;(async function getSubmissions(group: string) {
        try {
          const submissions: IArtWorkResponse[] = await fetchSubmissionsByGroup(group)

          if (submissions && submissions.length) {
            const newData = createOptimizedImages(submissions)
            setState({ data: [...newData], isLoading: !!query.sid })
          } else {
            setState(prevState => ({ ...prevState, isLoading: false }))
          }
        } catch (error) {
          setState(prevState => ({ ...prevState, isLoading: false }))
        }
      })(EDUCATION_LEVELS[selectedTab as keyof typeof EDUCATION_LEVELS])
    }
  }, [state.isLoading])

  useEffect(() => {
    setNextJSOptions((prevState: INextJSOptions) => {
      return { ...prevState, isReady: router.isReady }
    })
  }, [router.isReady])

  useEffect(() => {
    setNextJSOptions((prevState: INextJSOptions) => {
      return { ...prevState, page: query.page }
    })
  }, [query.page])

  const displayShareButton = (title: string, url?: string) => {
    //Until we can migrate more elements from this project to msg-fe-components, we need to pass the ShareButton
    //to the Gallery component. And we also need to be able to dynamically pass it a url (in case we want to share
    //a URL that is not the current window.location). An optional **type** prop will help apply styling.
    return <ShareButton isFullWidth={true} title={title} size={'md'} url={url} type={'result-modal'} />
  }

  const isVotingOpen = () => {
    if (votingDeadline) {
      const votingIsOpen = !isPastDeadline(votingDeadline)
      setVotingOpen(prevState => {
        if (prevState !== votingIsOpen) {
          //If voting switches from open to closed or closed to open while the browser is open,
          //reload the page so that all voting functionality disappears/appears accordingly
          router.reload()

          return votingIsOpen
        } else {
          return prevState
        }
      })
      return votingIsOpen
    } else {
      return false //if no deadline is provided, voting is closed
    }
  }

  return (
    <>
      {state.isLoading ? (
        <div className={styles['loading__overlay']}>
          <Paragraph text={{ html: 'Loading...' }} />
        </div>
      ) : null}
      {showGallery ? (
        <Hero
          subTitle=""
          subheadline=""
          isSignupPage={false}
          isShort={true}
          title={heroTitle}
          description={heroDescription}
          backgroundImage={heroBgImage}
          isGalleryComponent={true}
        />
      ) : null}
      <div
        className={styles['gallery-container']}
        id={'gallery-container'}
        key={id}
        {...(artworkDetailsData && { style: { backgroundImage: `url(${heroBgImage?.url})` } })}
      >
        {artworkDetailsData ? (
          <ArtworkDetail
            description={{
              html:
                EducationLevels[artworkDetailsData.educationLevel] +
                ',' +
                ' ' +
                artworkDetailsData.schoolName +
                '<br><em>' +
                artworkDetailsData.artWorkTitle +
                '</em>'
            }}
            headline={artworkDetailsData.firstName + ' ' + artworkDetailsData.lastName}
            eyebrow="XO STUDENT DESIGN CHALLENGE"
            videoDetails={artworkDetailsData.video}
            sId={artworkDetailsData.sId}
            educationLevel={artworkDetailsData.educationLevel}
            schoolName={artworkDetailsData.schoolName}
            artWorkTitle={artworkDetailsData.artWorkTitle}
            createdAt={artworkDetailsData.createdAt}
            votingModalReminder={votingModalReminder}
            votingModalDisclaimer={votingModalDisclaimer}
            isVotingOpen={isVotingOpen}
          />
        ) : showGallery ? (
          <GalleryComponent
            data={state.data ? state.data : galleryPlaylist}
            numThumbnailsPerPage={THUMBNAILS_PER_PAGE}
            nextJSOptions={nextJSOptions}
            tabLabels={tabLabels}
            activeTabIndex={tabLabels?.findIndex(tab => tab === selectedTab)}
            resultsHeading={resultsHeading}
            filterHeading={filterHeading}
            filterValue={filterValue}
            sortByHeading={sortByHeading}
            sortByOptions={sortByOptions}
            leaderboard={
              leaderboardImage && leaderboardHeading ? <Leaderboard imageUrl={leaderboardImage.url} heading={leaderboardHeading} /> : undefined
            }
            submitVote={submitXOVote}
            isLoading={state.isLoading}
            displayShareButton={displayShareButton}
            votingModalDisclaimer={votingModalDisclaimer}
            votingModalReminder={votingModalReminder}
            isVotingOpen={isVotingOpen}
          />
        ) : null}
      </div>
    </>
  )
}

export default Gallery
