import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { Container } from 'react-grid-system'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { CarouselSlide, ConditionalWrapper, Heading, CardList, Paragraph } from '~elements'
import { CarouselSlideProps } from '~types'
import styles from './Carousel.module.scss'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'
import { useWindowSize } from 'utils/hooks'

interface CarouselProps {
  autoPlay: boolean
  carouselSlides: Array<any>
  headline?: string
  isChildOfCard?: boolean
  showArrows: boolean
  showDots: boolean
  showModal: boolean
  loopCarousel: boolean
  slidesToShow: number
  description: {
    html: string
  }
}

const Carousel = ({
  autoPlay = false,
  headline,
  isChildOfCard = false,
  slidesToShow = 1,
  showModal = false,
  showArrows = false,
  showDots = false,
  carouselSlides = [],
  description,
  loopCarousel
}: CarouselProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(1)
  const largeScreenBreakpoint = Number(breakpoints['lg'])
  const mediumScreenBreakpoint = Number(breakpoints['md'])
  const smallScreenBreakpoint = Number(breakpoints['sm'])
  const { isBreakpoint: isBelowBreakpointSm } = useWindowSize('sm', '<=')

  const settings = {
    arrows: showArrows,
    autoplay: autoPlay,
    dots: showDots,
    infinite: loopCarousel,
    slidesToScroll: slidesToShow,
    slidesToShow: slidesToShow,
    speed: 700,
    adaptiveWidth: true,
    beforeChange: (current: number, next: number) => {
      if (slidesToShow === 1) {
        setActiveSlideIndex(next + slidesToShow)
      } else if (slidesToShow === 3) {
        if (isBelowBreakpointSm) {
          setActiveSlideIndex(next ? next + 1 : next + 1)
        } else if (isBelowBreakpointMd) {
          setActiveSlideIndex(next ? next + 2 : next + 1)
        } else {
          setActiveSlideIndex(next ? next + slidesToShow : next + 1)
        }
      }
    },
    responsive: [
      {
        breakpoint: largeScreenBreakpoint,
        settings: {
          slidesToShow: slidesToShow == 1 ? slidesToShow : slidesToShow,
          slidesToScroll: slidesToShow == 1 ? slidesToShow : slidesToShow
        }
      },
      {
        breakpoint: mediumScreenBreakpoint,
        settings: {
          slidesToShow: slidesToShow == 3 ? 2 : slidesToShow,
          slidesToScroll: slidesToShow == 3 ? 2 : slidesToShow
        }
      },
      {
        breakpoint: smallScreenBreakpoint,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const sliderRef = useRef()
  const [currentModalState, setCurrentModalState] = useState(showModal)

  const handleImageClick = () => {
    setCurrentModalState(showModal)
  }

  const handleSlickNext = () => {
    sliderRef?.current?.slickNext()
  }

  const handleSlickPrev = () => {
    sliderRef?.current?.slickPrev()
  }

  const { isBreakpoint: isBelowBreakpointMd, windowSizeCalculation } = useWindowSize('md', '<')
  const [carousel] = carouselSlides
  return (
    <ConditionalWrapper
      condition={!isChildOfCard}
      wrapper={children => (
        <div className={`${styles['carousel-container']} ${description ? styles['edp-experience'] : ''}`}>
          <Container className={'container overflow-visible'}>
            {headline && carousel['componentName'] === 'CarouselSlide' && (
              <Heading level={3} justify={'center'}>
                {headline}
              </Heading>
            )}
            {description && (
              <div>
                <Paragraph text={description} fontSize={'sm'} justify={'center'} />
              </div>
            )}
            {children}
          </Container>
        </div>
      )}
    >
      <div className={styles['nav-container']}>
        <div className={styles['count-container']}>
          {carousel && carousel?.componentName && carousel['componentName'] === 'CarouselSlide' && (
            <p>{`${activeSlideIndex} / ${carouselSlides.length}`}</p>
          )}
          {headline && carousel['componentName'] !== 'CarouselSlide' && (
            <Heading level={3} justify={'left'}>
              {headline}
            </Heading>
          )}
        </div>
        <div className={styles['slider-nav-container']}>
          <div className={cx([styles['slick-arrow-custom'], styles['slick-arrow-custom--prev']])} onClick={() => handleSlickPrev()}>
            <FontAwesomeIcon icon={faChevronLeft} size="lg" className={activeSlideIndex === 1 && !loopCarousel ? styles['disabled-nav'] : ''} />
          </div>
          <div className={cx([styles['slick-arrow-custom'], styles['slick-arrow-custom--next']])} onClick={() => handleSlickNext()}>
            <FontAwesomeIcon
              icon={faChevronRight}
              size="lg"
              className={activeSlideIndex === carouselSlides.length && !loopCarousel ? styles['disabled-nav'] : ''}
            />
          </div>
        </div>
      </div>
      {carouselSlides && windowSizeCalculation && (
        <Slider ref={sliderRef} className={`slides-shown-${slidesToShow} slide-overflow-is-visible`} {...settings}>
          {carouselSlides.map((card, index) => {
            return card.componentName === 'CarouselSlide' ? (
              <CarouselSlide
                img={isBelowBreakpointMd ? card.mobileImage : card.defaultImg}
                title={card.imageTitle}
                description={card.imageDescription}
                key={index}
                showModal={currentModalState}
                handleImageClick={handleImageClick}
              />
            ) : (
              <CardList key={index} {...card} />
            )
          })}
        </Slider>
      )}
    </ConditionalWrapper>
  )
}

export default Carousel
