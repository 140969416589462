import { LeaderboardProps } from '~types'
import styles from './Leaderboard.module.scss'
import Image from 'next/image'
import cx from 'classnames'

const Leaderboard = ({ heading, imageUrl }: LeaderboardProps) => {
  const classNames = cx('image-container', styles['leaderboard-img'])
  return (
    <div className={styles['leaderboard-container']}>
      {/* Not using the Heading component to avoid conflicts when Leaderboard gets passed to msg-fe-components */}
      <h2>{heading}</h2>
      <div className={classNames}>
        <Image src={imageUrl} alt={'Leaderboard'} width={0} height={0} sizes={'100vw'} priority />
      </div>
    </div>
  )
}

export default Leaderboard
