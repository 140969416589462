import 'isomorphic-fetch'
import moment from 'moment-timezone'
import { EventDateProps, EventDatesAPIResponseProps, MonthProps } from '~types'

const DATE_FORMAT = {
  MM_DD_YYYY: 'MM-DD-YYYY',
  MONTH: 'MMMM',
  SHORT_DATE: 'DD',
  SHORT_DATE_FORMAT: 'MM/DD/YYYY',
  SHORT_MM: 'MM',
  SHORT_MONTH: 'MMM',
  SHORT_YEAR: 'YYYY',
  YEAR: 'YYYY'
}

const ALL_SHOWS = 'all-dates'
const HOURS_PER_DAY = 23

const getAllShows = (data: CalendarData) => {
  const allShows: any = {}

  Object.keys(data).map((yearKey: string) => {
    Object.keys(data[yearKey]).map(monthKey => {
      if (!allShows[`${yearKey}-${monthKey}`]) allShows[`${yearKey}-${monthKey}`] = []

      allShows[`${yearKey}-${monthKey}`] = data[yearKey][monthKey]
    })
  })

  return allShows
}

const transformAllEventsResponse = (results: Array<EventDateProps>) => {
  const data: CalendarData = {}
  let allEventsData: any = {}
  results.forEach(result => {
    const dateValue = result.date
    const localeTimestamp = moment.utc(dateValue)
    const year = +localeTimestamp.format(DATE_FORMAT.YEAR)
    const monthIndex = +localeTimestamp.format(DATE_FORMAT.SHORT_MM)
    const dayOfMonth = result.spanMultipleDays ? +localeTimestamp.format(DATE_FORMAT.SHORT_DATE) - 1 : +localeTimestamp.format(DATE_FORMAT.SHORT_DATE)

    if (!data[year]) data[year] = {}
    if (!data[year][monthIndex]) data[year][monthIndex] = {}

    data[year][monthIndex][dayOfMonth] = {
      date: localeTimestamp,
      dayOfMonth,
      events: result.events,
      monthIndex,
      price: result.price,
      timestamp: result.date,
      year,
      spanMultipleDays: result.spanMultipleDays,
      end_date: result.events.length > 0 ? result.events[0].end_date : null
    }
  })
  allEventsData = getAllShows(data)
  return allEventsData
}

const transformResponse = (results: Array<EventDateProps>) => {
  const data: CalendarData = {}
  results.forEach(result => {
    const dateValue = result.date
    const localeTimestamp = moment.utc(dateValue)
    const year = +localeTimestamp.format(DATE_FORMAT.YEAR)
    const monthIndex = +localeTimestamp.format(DATE_FORMAT.SHORT_MM)
    const dayOfMonth = result.spanMultipleDays ? +localeTimestamp.format(DATE_FORMAT.SHORT_DATE) - 1 : +localeTimestamp.format(DATE_FORMAT.SHORT_DATE)

    if (!data[year]) data[year] = {}
    if (!data[year][monthIndex]) data[year][monthIndex] = {}

    data[year][monthIndex][dayOfMonth] = {
      date: localeTimestamp,
      dayOfMonth,
      events: result.events,
      monthIndex,
      price: result.price,
      timestamp: result.date,
      year,
      spanMultipleDays: result.spanMultipleDays,
      end_date: result.events.length > 0 ? result.events[0].end_date : null
    }
  })
  return data
}

const processMonthNavigation = (data: Map<string, object> | any, addDefaultSelection: boolean = true) => {
  let calendarFilterItemsOptions: Array<object> = []
  Object.keys(data).map((yearKey: string) => {
    Object.keys(data[yearKey]).map(monthKey => {
      const isCalendarFilterValid = data[yearKey][monthKey]
      if (isCalendarFilterValid) {
        const date: Moment = moment(`${yearKey}-${monthKey}`, `YYYY-MM`).add(HOURS_PER_DAY, 'hour')
        calendarFilterItemsOptions.push({
          label: date.format(`${DATE_FORMAT.MONTH} ${DATE_FORMAT.YEAR}`),
          shortLabel: date.format(`${DATE_FORMAT.SHORT_MONTH} ${DATE_FORMAT.SHORT_YEAR}`),
          value: date.format(`${DATE_FORMAT.MONTH}-${DATE_FORMAT.YEAR}`)
        })
      }
    })
  })
  if (calendarFilterItemsOptions.length > 0 && addDefaultSelection) {
    calendarFilterItemsOptions = [
      {
        label: 'Select Month',
        shortLabel: 'Select Month',
        value: 'all-dates'
      },
      ...calendarFilterItemsOptions.slice(0)
    ]
  }
  return calendarFilterItemsOptions
}

interface MonthOption {
  label: string
  shortLabel: string
  value: string
}

const getEventMinPrice = (paginatedData: Array<EventDateProps | any>) =>
  Math.min(...paginatedData.filter((event: EventDateProps) => event.price.min > 0).map((event: EventDateProps) => event.price.min))

const getEventMaxPrice = (paginatedData: Array<EventDateProps | any>) =>
  Math.max(...paginatedData.filter((event: EventDateProps) => event.price.max > 0).map((event: EventDateProps) => event.price.max))

const getPaginatedData = (filteredEvents: any, currentPageIndex: number, eventsPerPage: number) => {
  const indexOfLastRecord = currentPageIndex * eventsPerPage
  const paginatedData = filteredEvents.slice(0, indexOfLastRecord)

  return paginatedData
}

const getFilteredYear = results => {
  const yearsList: Array<number> = []

  results.forEach(result => {
    const dateValue = result.date
    const localeTimestamp = moment.utc(dateValue)
    const year = +localeTimestamp.format(DATE_FORMAT.YEAR)
    yearsList.push(year)
  })
  const minYear = Math.min(...yearsList)
  const maxYear = Math.max(...yearsList)

  return minYear === maxYear ? `${minYear}` : `${minYear} - ${maxYear}`
}

const getFilteredCalendarData = (
  monthsList: Array<MonthProps>,
  selectedMonth: MonthProps,
  allEvents: any,
  currentPageIndex: number,
  eventsPerPage: number
) => {
  const [filteredData] = monthsList.filter(month => month.label === selectedMonth.label)
  let calendarDataList: any = {}
  let priceRange = null
  let totalPagesCount = 0
  let calendarYear: string = ''
  if (filteredData.value === ALL_SHOWS) {
    const paginatedData = getPaginatedData(allEvents, currentPageIndex, eventsPerPage)
    calendarDataList = transformAllEventsResponse(paginatedData)
    const minPrice = getEventMinPrice(paginatedData)
    const maxPrice = getEventMaxPrice(paginatedData)
    priceRange = { minPrice, maxPrice }
    totalPagesCount = allEvents.length < eventsPerPage ? 1 : Math.ceil(allEvents.length / eventsPerPage)
    calendarYear = getFilteredYear(paginatedData)
  } else {
    const [filteredMonth, filteredYear] = filteredData.value.split('-')
    const formattedCalendarDataList = transformAllEventsResponse(allEvents)
    const selectedMonth = `${filteredYear}-${+moment().month(filteredMonth).format('M')}`
    const filteredCalendarData = formattedCalendarDataList[selectedMonth]
    const filteredValues = Object.values(filteredCalendarData)
    const paginatedData = getPaginatedData(filteredValues, currentPageIndex, eventsPerPage)
    totalPagesCount = filteredValues.length < eventsPerPage ? 1 : Math.ceil(filteredValues.length / eventsPerPage)

    calendarDataList = {
      [`${filteredYear}-${+moment().month(filteredMonth).format('M')}`]: paginatedData
    }
    const minPrice = getEventMinPrice(paginatedData)
    const maxPrice = getEventMaxPrice(paginatedData)
    priceRange = { minPrice, maxPrice }
    calendarYear = filteredYear
  }

  return {
    priceRange,
    calendarYear,
    currentPageIndex,
    calendarDataList,
    totalPagesCount
  }
}

const processCalendarData = (eventDates: EventDatesAPIResponseProps | any) => {
  let noOfMonths = 0
  let response = {
    calendarData: {},
    noOfMonths,
    nextEventDate: moment().utc().format(DATE_FORMAT.SHORT_DATE_FORMAT)
  }

  if (eventDates.length > 0) {
    const calendarData = transformResponse(eventDates)
    const startDate = Math.min(...eventDates.map((eventDate: EventDate) => eventDate.date))
    const endDate = Math.max(...eventDates.map((eventDate: EventDate) => eventDate.date))
    noOfMonths = Math.round(moment(endDate).endOf('month').diff(moment(startDate).startOf('month'), 'months', true))
    response = {
      calendarData,
      noOfMonths,
      nextEventDate: moment(startDate).utc().format(DATE_FORMAT.SHORT_DATE_FORMAT)
    }
  }
  return response
}

const fetchStaticDatesList = async (s3Url: string) => {
  const s3Response = await fetch(s3Url)
  const eventDates = await s3Response.json()
  return eventDates
}

const fetchEventEngineDatesList = async (eventEngineAPIUrl: string, authorizationToken: string, noOfMonths: number) => {
  const startDate = moment.utc().format('MMDDYYYY')
  const endDate = moment.utc().add(noOfMonths, 'months').format('MMDDYYYY')
  const result = await fetch(`${eventEngineAPIUrl}&start_date=${startDate}&end_date=${endDate}`, {
    headers: {
      Authorization: `Bearer ${authorizationToken}`
    }
  })
  const eventDates = await result.json()
  return eventDates
}

const fetchCalendarData = async (
  eventEngineAPIUrl: string,
  loadCalendarDataFromS3: boolean,
  staticCalendarDataS3Url: string,
  authorizationToken: string,
  noOfMonths: number
) => {
  let eventDates = {
    results: []
  }
  if (loadCalendarDataFromS3) {
    eventDates = await fetchStaticDatesList(staticCalendarDataS3Url)
  } else {
    eventDates = await fetchEventEngineDatesList(eventEngineAPIUrl, authorizationToken, noOfMonths)
  }
  const { results } = eventDates
  const { calendarData } = processCalendarData(results)
  const calendarFilterItems: any = processMonthNavigation(calendarData)
  const [selectedMonth] = calendarFilterItems

  return {
    allEvents: results,
    calendarData,
    calendarFilterItems,
    selectedMonth
  }
}

export const listeners = {
  fetchCalendarData,
  fetchStaticDatesList,
  getFilteredCalendarData,
  processCalendarData,
  processMonthNavigation
}
