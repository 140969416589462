import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './Tabs.module.scss'

interface TabProps {
  activeIndex: number
  setParentIndex: any
  labels: Array<string>
}

const Tabs = ({ activeIndex, setParentIndex, labels }: TabProps) => {
  return (
    <>
      {labels.length && (
        <div className={styles['tabs']}>
          <hr className={'has-border-fade'} />
          <div className={styles['tabs__wrapper']}>
            <div className={styles['tabs__scrollable-container']}>
              {labels.map((label: string, index: number) => (
                <div
                  className={cx([styles['tab'], styles[activeIndex === index ? 'is-active' : '']])}
                  key={index}
                  onClick={() => {
                    setParentIndex(index)
                  }}
                >
                  {label}
                </div>
              ))}
            </div>
          </div>
          <hr className="has-border-fade" />
        </div>
      )}
    </>
  )
}

export default Tabs
