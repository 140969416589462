import { useEffect, useRef, useState } from 'react'
import { CarouselFullScreenType, ScrollAnimationSceneType } from 'types/ScrollAnimation'
import { Heading, PortalModal, Form } from '~elements'
import { CarouselFullScreen, HeroFullScreen } from '~modules'
import Button from '../Button/Button'
import styles from './ScrollAnimationScene.module.scss'
import { Container } from 'react-grid-system'
import { HeroFullScreenProps } from '~types'

const ScrollAnimationScene = ({ alignment, cta, description, label, labelColor, sceneBlock, title }: ScrollAnimationSceneType) => {
  let classNames = `${styles['animate-scroll-animate-scene']}`
  const [showSignUpModal, setShowSignupModal] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [heroFullScreenScene, setHeroFullScreenScene] = useState<HeroFullScreenProps>()
  const [carouselScene, setCarouselScene] = useState<CarouselFullScreenType>()

  const ctaFormHandler = () => {
    setShowSignupModal(true)
  }

  const formSubmitHandler = () => {
    setShowSignupModal(false)
  }

  const onCloseModalHandler = () => {
    setShowSignupModal(false)
  }

  if (carouselScene) {
    classNames += ` ${styles['animate-scroll-animation-scene-carousel']}`
  }

  useEffect(() => {
    if (sceneBlock[0]) {
      if ('slides' in sceneBlock[0]) {
        setCarouselScene(carouselScene)
      } else if ('title' in sceneBlock[0]) {
        setHeroFullScreenScene(sceneBlock[0])
        const { style } = containerRef.current
        style.top = '0'
      }
    }
  }, [containerRef])

  return (
    <>
      <div ref={containerRef} className={classNames}>
        {heroFullScreenScene ? <HeroFullScreen {...heroFullScreenScene} /> : null}
        {carouselScene ? <CarouselFullScreen slides={carouselScene.slides} /> : null}
        <Container>
          <Heading level={5} levelDisplay={'eyebrow'}>
            {label}
          </Heading>
          <Heading level={3}>{title}</Heading>
          <p>{description}</p>
          {cta ? <Button {...cta} onClick={ctaFormHandler} /> : null}
        </Container>
      </div>
      {cta ? (
        <PortalModal isOpen={showSignUpModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
          <Form
            formId={cta.modalFormId}
            formSubmitHandler={formSubmitHandler}
            isChildOfModal={true}
            submitButtonText="Sign Up Now"
            inputBgColor="white"
          />
        </PortalModal>
      ) : null}
    </>
  )
}

export default ScrollAnimationScene
