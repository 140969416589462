import React, { useState } from 'react'
import styles from './ButtonGroup.module.scss'
import cx from 'classnames'
import { Container } from 'react-grid-system'
import { Button, Form, PortalModal } from '~elements'
import { ButtonGroupProps } from '~types'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
const ButtonGroup = ({ buttons, btnGroupTitle, bottomDivider = true }: ButtonGroupProps) => {
  const [showSignUpModal, setShowSignupModal] = useState<any>({})
  const [modalFormId, setModalFormId] = useState<string>('')

  const ctaFormHandler = (id: string, ctaBtnText: string) => {
    const showSignUpModalValue = {
      ...showSignUpModal,
      [id]: true
    }
    setShowSignupModal({ ...showSignUpModalValue })
    setModalFormId(id)
    gaBtnClick(CTA_CLICK_EVENT, btnGroupTitle, ctaBtnText)
  }

  const onCloseModalHandler = () => {
    setShowSignupModal({})
    setModalFormId('')
  }

  const ButtonGroupRender = () => {
    return buttons.map(button => {
      const { id, ctaBtnText, ctaBtnFormId } = button
      return (
        <div className={styles['button-group__button']} key={id}>
          <Button
            color="light"
            fill="hollow"
            onClick={() => (ctaBtnFormId ? ctaFormHandler(ctaBtnFormId, ctaBtnText) : null)}
            size="md"
            title={ctaBtnText}
          />
          {ctaBtnFormId && (
            <PortalModal isOpen={showSignUpModal[ctaBtnFormId] || false} handleModalClose={onCloseModalHandler} closeOnOutsideClick={!ctaBtnFormId}>
              <Form formId={modalFormId} formSubmitHandler={onCloseModalHandler} isChildOfModal={true} />
            </PortalModal>
          )}
        </div>
      )
    })
  }

  return (
    <div className={cx([styles['btn-group-container'], styles[bottomDivider ? '' : 'no-bottom-divider']])}>
      {buttons.length > 0 && (
        <Container className={'container'}>
          <p>{btnGroupTitle}</p>
          <div className={styles['button-group']}>{ButtonGroupRender()}</div>
        </Container>
      )}
    </div>
  )
}

export default ButtonGroup
