import React, { MouseEvent, useState } from 'react'
import styles from '../Calendar.module.scss'
import cx from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import { useWindowSize } from 'utils/hooks'
import { Heading } from '~elements'

interface Price {
  minPrice: number
  maxPrice: number
}

interface MonthProps {
  label: string
  value: string
  shortLabel: string
}

interface CalendarNavProps {
  priceRange: Price
  calendarFilterItems: Array<MonthProps>
  filteredYear: string
  selectedMonth: MonthProps
  handleMonthNavChange: Function
}

const CalendarNav = ({ calendarFilterItems, selectedMonth, filteredYear, handleMonthNavChange }: CalendarNavProps) => {
  const [isNavOpen, showIsNavOpen] = useState(false)
  const toggleOptions = () => showIsNavOpen(!isNavOpen)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const dropdownSelectedField = isBelowBreakpointMd ? 'shortLabel' : 'label'

  const setActiveMonth = (event: MouseEvent<HTMLLIElement>) => {
    const text = (event.target as HTMLLIElement).innerText
    const [selectedMonth] = calendarFilterItems.filter(month => month[dropdownSelectedField] === text)
    showIsNavOpen(false)
    handleMonthNavChange(selectedMonth)
  }

  const closeAllOptions = () => {
    showIsNavOpen(false)
  }

  return (
    <div className={styles['calendar-nav']}>
      <Heading level={4}>{filteredYear}</Heading>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeAllOptions()
        }}
      >
        <div className={styles['calendar-form-input']}>
          <div onClick={() => toggleOptions()} className={cx([styles['options-toggler']])}>
            {selectedMonth[dropdownSelectedField]}
            <div className={cx([styles['caret'], styles[isNavOpen ? '' : 'is-open']])} />
          </div>
          {isNavOpen && calendarFilterItems.length && (
            <div className={styles['options-container']}>
              <ul>
                {calendarFilterItems.map((month: MonthProps, i: number) => (
                  <li
                    className={cx([styles['option'], styles[selectedMonth[dropdownSelectedField] == month[dropdownSelectedField] ? 'active' : '']])}
                    data-id={month.value}
                    key={i}
                    onClick={e => setActiveMonth(e)}
                  >
                    {month[dropdownSelectedField]}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default CalendarNav
