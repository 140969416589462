import React, { useEffect, useState } from 'react'
import { ParagraphProps } from '~types'
import { useRouter } from 'next/router'
import { useWindowSize } from 'utils/hooks'
import cx from 'classnames'

import styles from './Paragraph.module.scss'
import { READ_MORE_WORD_COUNT_MAX } from '~constants'
import { parseHTMLString } from 'utils'

const Paragraph = ({ fontSize = 'md', justify = 'left', showReadMore = false, text }: ParagraphProps) => {
  //solves hydration issue
  const [show, setShow] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { query } = useRouter()
  const requestCameFrom = query.camefrom
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    setShow(true)
  }, [])

  const getTrimmedText = () => {
    const words = text.html.split(' ')
    return isExpanded ? parseHTMLString(text, requestCameFrom) : <p>{`${words.slice(0, READ_MORE_WORD_COUNT_MAX).join(' ')}...`}</p>
  }

  if (show && text.html) {
    return (
      <>
        <div className={'paragraph-container'}>
          <div className={`justify-${justify} full-width font-size-${fontSize}`}>
            {showReadMore && isBelowBreakpointMd ? (
              <>
                {text.html.length > 0 && getTrimmedText()}
                {text.html && text.html.length > READ_MORE_WORD_COUNT_MAX && (
                  <button onClick={toggleExpanded} className={styles['read-or-hide']}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                    <div className={cx([styles['caret'], styles[isExpanded ? '' : 'is-open']])} />
                  </button>
                )}
              </>
            ) : (
              parseHTMLString(text, requestCameFrom)
            )}
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}

export default Paragraph
