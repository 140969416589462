import React, { useEffect, useRef, useState, useCallback, startTransition } from 'react'
import Image from 'next/image'
import cx from 'classnames'
import { Form, Heading, PortalModal, Button } from '~elements'
import styles from './HeroFullScreenVideo.module.scss'
import { Container } from 'react-grid-system'
import { ButtonProps, ImageProps, VideoProps } from '~types'
import { useWindowSize, getCustomBreakpoint } from 'utils/hooks'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT, HOME_PAGE_VIDEO } from '~analyticsConstants'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'

interface HeroFullScreenVideoProps {
  backgroundVideos: {
    backgroundVideoXl: VideoProps
    backgroundVideoMd: VideoProps
    backgroundVideoSm: VideoProps
    posterImageXl: ImageProps
    posterImageMd: ImageProps
    posterImageSm: ImageProps
    videoLoop: boolean
  }
  backgroundVideo: {
    url: String
  }
  cta: ButtonProps
  heroTitle: string
  posterImage: {
    url: string
  }
  showVolumeOption: boolean
  subtitle?: string
  textLogo: {
    fileName: string
    url: string
  }

  videoMuteButton: {
    url: string
  }
  videoUnmuteButton: {
    url: string
  }
  videoLoop: boolean
}

const VIDEO_PLAYBACK_MODE = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

const HeroFullScreenVideo = ({
  backgroundVideos,
  backgroundVideo,
  cta,
  heroTitle,
  subtitle,
  showVolumeOption,
  textLogo,
  videoMuteButton,

  videoUnmuteButton
}: HeroFullScreenVideoProps) => {
  const [mutedVideo, setMutedVideo] = useState<boolean>(true)
  const backgroundVideoRef = useRef<HTMLVideoElement>(null)
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(true)
  const [modalFormId, setModalFormId] = useState<string>('')
  const [showHeroModal, setShowHeroModal] = useState<boolean>(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [videoPlaybackMode, setVideoPlaybackMode] = useState<string>('')

  const { backgroundVideoXl, backgroundVideoMd, backgroundVideoSm, posterImageXl, posterImageMd, posterImageSm, videoLoop } = backgroundVideos
  const { isBreakpoint: isAboveBreakpointMd } = getCustomBreakpoint(+breakpoints.md, '>')
  const { isBreakpoint: isAboveBreakpointXL } = getCustomBreakpoint(1437, '>')

  const heroVideo = isAboveBreakpointXL ? backgroundVideoXl : isAboveBreakpointMd ? backgroundVideoMd : backgroundVideoSm
  const posterImage = isAboveBreakpointXL ? posterImageXl : isAboveBreakpointMd ? posterImageMd : posterImageSm

  useEffect(() => {
    if (cta && cta.modalFormId) {
      setModalFormId(cta.modalFormId)
    }
  }, [cta])

  useEffect(() => {
    if (heroVideo) {
      backgroundVideoRef.current?.pause()
      backgroundVideoRef.current?.load()
      startTransition(() => {
        backgroundVideoRef.current?.play().catch(e => {
          console.log(e)
        })
      })
    }
  }, [heroVideo])

  useEffect(() => {
    if (backgroundVideoRef && backgroundVideoRef.current) {
      backgroundVideoRef.current
        .play()
        .then(() => {
          backgroundVideoRef.current.poster = ''
          setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)
        })
        .catch(() => {
          /* Automatic playback failed */
          setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.FAILED)
        })
    }
  }, [backgroundVideoRef])

  const handleVideoEnd = () => {
    setVideoIsPlaying(false)
  }

  const toggleMute = () => {
    if (backgroundVideoRef && backgroundVideoRef.current) {
      const mutedVideoStatus = !mutedVideo
      backgroundVideoRef.current.muted = mutedVideoStatus
      setMutedVideo(mutedVideoStatus)
    }
  }

  const onCloseModalHandler = useCallback(() => setShowHeroModal(false), [])

  const handleVideoPlay = () => setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)

  const handlePlaying = () => setVideoPlaybackMode(VIDEO_PLAYBACK_MODE.SUCCESS)

  const formSubmitHandler = () => {
    setShowHeroModal(false)
  }

  const handleCtaClick = () => {
    setShowHeroModal(true)
  }

  const volumeToggleButton = () => {
    return showVolumeOption && videoIsPlaying && videoMuteButton && videoUnmuteButton ? (
      <div className={styles['volume-toggle-container']}>
        <div
          aria-label="Volume"
          aria-pressed={!mutedVideo}
          className={styles['volume-toggle']}
          onClick={toggleMute}
          role="button"
          style={{ backgroundImage: `url(${mutedVideo ? videoMuteButton?.url : videoUnmuteButton?.url})` }}
          tabIndex={0}
        />
      </div>
    ) : null
  }

  const contentIsVisible = isBelowBreakpointMd || !videoIsPlaying

  //Temporary functionality for Sphere 2.1 release
  const heroTitles = heroTitle ? heroTitle.split('|').map(word => word.trim()) : []
  const [heroTitleIndex, setHeroTitleIndex] = useState(0)
  useEffect(() => {
    //this makes sure eyebrow starts changing titles when
    //video starts on mobile & after video ends on desktop
    if (contentIsVisible) {
      const interval = setInterval(() => {
        setHeroTitleIndex(index => (index === heroTitles.length - 1 ? 0 : index + 1))
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [heroTitles, contentIsVisible])

  return (
    <>
      <div className={styles['hero-full-screen-video']}>
        <div className={cx([styles.content])}>
          <Container className={styles.container}>
            <div className={styles['is-visible']}>
              <div className={styles['brand-headline-group']}>
                {heroTitles.map((title, i) => (
                  <div className={cx([styles['headline'], i === heroTitleIndex ? styles['show'] : ''])} key={i}>
                    <Heading level={i === 0 ? 1 : 2} levelDisplay={4} isUppercase>
                      {title}
                    </Heading>
                  </div>
                ))}

                <div className={styles['brand-logo-xl']}>
                  {textLogo?.url && (
                    <div className={styles['image-container']}>
                      <Image src={textLogo.url} alt={textLogo.fileName} width="0" height="0" sizes="100vw" priority />
                    </div>
                  )}
                  {subtitle && (
                    <Heading level={5}>
                      <>{subtitle}</>
                    </Heading>
                  )}
                </div>
              </div>
            </div>

            {volumeToggleButton()}
            <div className={cx(styles['cta-container'], styles['is-visible'])}>
              <Button
                onClick={() => gaBtnClick(CTA_CLICK_EVENT, HOME_PAGE_VIDEO, cta.title)}
                {...cta}
                size={'lg'}
                {...(cta.modalFormId && { onClick: handleCtaClick })}
              />
            </div>
          </Container>
        </div>
        {videoPlaybackMode === VIDEO_PLAYBACK_MODE.FAILED ? (
          <div className={styles['poster-container']}>
            <Image alt="Hero Video Poster" src={posterImage?.url} width="0" height="0" sizes="100vw" />
          </div>
        ) : (
          <video
            loop={videoLoop}
            ref={backgroundVideoRef}
            onEnded={handleVideoEnd}
            onPlay={handleVideoPlay}
            onTimeUpdate={handlePlaying}
            autoPlay={true}
            muted
            playsInline={true}
            poster={posterImage?.url}
            className={styles['video-without-pointer-event']}
          >
            <source src={heroVideo.url} type="video/mp4" />
          </video>
        )}
      </div>
      <PortalModal isOpen={showHeroModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} isChildOfModal={true} formSubmitHandler={formSubmitHandler} />
      </PortalModal>
    </>
  )
}

export default HeroFullScreenVideo
