import React, { useState } from 'react'
import { Container, Row } from 'react-grid-system'
import styles from './ContentModuleSection.module.scss'
import { ContentModuleSectionProps } from '~types'
import { Button, CardMedia, Divider, Form, Heading as ContentHeading, ImageLink, PortalModal, Paragraph, Vimeo } from '~elements'
import { ANALYTICS_LINK_MODULE_GLOBAL_NAV, ANALYTICS_LINK_LOCATION_FOOTER } from '~analyticsConstants'
const componentsObj = {
  Button,
  CardMedia,
  ContentHeading,
  Divider,
  Paragraph,
  ImageLink,
  Vimeo,
  Form
}

const ContentModuleSection = ({
  constantsMap,
  blocks = [],
  showDividerAtBottom,
  showDividerAtTop,
  contentModuleDivId,
  dividerFullWidth
}: ContentModuleSectionProps) => {
  const [showSignUpModal, setShowSignupModal] = useState<boolean>(false)
  const [modalFormId, setModalFormId] = useState<string>('')
  const ctaFormHandler = (id: string) => {
    setShowSignupModal(true)
    setModalFormId(id)
  }

  const formSubmitHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }

  const onCloseModalHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }

  return (
    <>
      <div className={styles['content-module']} id={contentModuleDivId}>
        {showDividerAtTop && <Divider dividerFullWidth={dividerFullWidth} />}
        <Container className={'container container--narrow-xxl'}>
          {blocks &&
            blocks.map((block, index) => {
              const { componentName } = block
              const DynamicComponent = componentsObj[componentName]
              if (DynamicComponent) {
                if (componentName === 'ContentHeading') {
                  return (
                    <DynamicComponent key={index} constantsMap={constantsMap} {...block}>
                      {block.title}
                    </DynamicComponent>
                  )
                } else {
                  return (
                    <DynamicComponent
                      onClick={() => (componentName === 'Button' && block.modalFormId ? ctaFormHandler(block.modalFormId) : null)}
                      constantsMap={constantsMap}
                      isChildOfModule={true}
                      key={index}
                      {...block}
                      linkModule={ANALYTICS_LINK_MODULE_GLOBAL_NAV}
                      linkLocation={ANALYTICS_LINK_LOCATION_FOOTER}
                    />
                  )
                }
              }
            })}
        </Container>
        {showDividerAtBottom && <Divider dividerFullWidth={dividerFullWidth} />}
      </div>
      <PortalModal isOpen={showSignUpModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} isChildOfModal={true} formSubmitHandler={formSubmitHandler} />
      </PortalModal>
    </>
  )
}

export default ContentModuleSection
