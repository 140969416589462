import React from 'react'
import { ContentModuleProps } from '~types'
import ContentModuleSection from '../ContentModuleSection/ContentModuleSection'

const ContentModule = ({ constantsMap, moduleSections }: ContentModuleProps) => {
  return (
    <>{moduleSections && moduleSections.map((moduleSection, index) => <ContentModuleSection {...moduleSection} constantsMap={constantsMap} />)}</>
  )
}

export default ContentModule
