import styles from './JumpLinkGroup.module.scss'
import icon from '../../../public/assets/right-arrow.svg'
import Image from 'next/image'
import { Container } from 'react-grid-system'
import { Fragment } from 'react'

interface JumpLinkProps {
  jumpLinkHref: string
  jumpLinkTitle: string
  id?: string
}

const JumpLink = ({ jumpLinkHref, jumpLinkTitle }: JumpLinkProps) => {
  const onClick = (id: string) => {
    const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight
    const target = document.getElementById(id.slice(1))
    if (target) {
      let targetPos = target?.getBoundingClientRect()?.top + window.scrollY - headerHeight
      window.scrollTo({ top: targetPos, behavior: 'smooth' })
    }
  }
  return (
    <div className={styles.jumpLinkContainer} onClick={() => onClick(jumpLinkHref)}>
      <span>{jumpLinkTitle}</span>
      <Image src={icon} alt="right-arrow" />
    </div>
  )
}

const JumpLinkGroup = ({ jumpLinks }: { jumpLinks: JumpLinkProps[] }) => {
  return (
    <Container>
      <div className={styles.jumpLinkGroupContainer}>
        {jumpLinks.map(({ id, jumpLinkHref, jumpLinkTitle }: JumpLinkProps) => {
          return <Fragment key={id}>{JumpLink({ jumpLinkHref, jumpLinkTitle })}</Fragment>
        })}
      </div>
    </Container>
  )
}

export default JumpLinkGroup
