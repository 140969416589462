import React from 'react'
import cx from 'classnames'
import styles from './CardFullWidth.module.scss'
import { Container } from 'react-grid-system'
import { Button, Divider, Heading, Paragraph } from '~elements'
import { ButtonGroup, Calendar } from '~modules'
import { ButtonProps, CalendarProps, ImageProps, WidgetSectionProps } from '~types'
import { useWindowSize } from 'utils/hooks'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'

interface CardFullWidthProps {
  backgroundImageXl?: ImageProps
  backgroundImageMd?: ImageProps
  backgroundImageSm?: ImageProps
  buttonGroup?: Array<ButtonProps>
  ctaSection?: Array<ctaSectionTitle | WidgetSectionProps>
  description: {
    html: string
  }
  disclaimer?: {
    html: string
  }
  headline: string
  subheadline: string
  calendar?: CalendarProps
  cta?: ButtonProps
  showDivider: boolean
}

interface ctaSectionTitle {
  title: string
}

const CardFullWidth = ({
  backgroundImageXl,
  backgroundImageMd,
  backgroundImageSm,
  description,
  disclaimer,
  buttonGroup,
  headline,
  subheadline = '',
  calendar,
  cta,
  ctaSection,
  showDivider
}: CardFullWidthProps) => {
  const { isBreakpoint: isBelowBreakpointSm, windowSizeCalculation } = useWindowSize('sm', '<')
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')
  const CardFullWidthImg = isBelowBreakpointSm ? backgroundImageSm : isBelowBreakpointLg ? backgroundImageMd : backgroundImageXl

  return (
    <>
      <Container className="container">
        <div className={cx([styles['card-full-width-container']])}>
          <div className={styles['card-full-width']}>
            {CardFullWidthImg && windowSizeCalculation && (
              <div className={styles['image-container']}>
                <img alt={CardFullWidthImg.fileName} src={CardFullWidthImg.url} />
              </div>
            )}
            <div className={styles['headline-container']}>
              {headline && <Heading level={2}>{headline}</Heading>}
              {subheadline && <Heading level={6}>{subheadline}</Heading>}
              {description && <Paragraph text={description} />}
              {disclaimer && <Paragraph text={disclaimer} fontSize={'sm'} />}
              {buttonGroup && buttonGroup.length > 0 && (
                <div className={styles['btn-container']}>
                  {buttonGroup.map((buttonProps, index) => (
                    <Button
                      key={`${buttonProps.title}_${index}`}
                      {...buttonProps}
                      onClick={() => gaBtnClick(CTA_CLICK_EVENT, headline, buttonProps.title)}
                    />
                  ))}
                </div>
              )}
            </div>
            {ctaSection && ctaSection.length > 0 && (
              <ButtonGroup btnGroupTitle={ctaSection[0].title} buttons={ctaSection.slice(1)} bottomDivider={false} />
            )}
            {calendar && <Calendar isChildOfCard={true} {...calendar} />}
            {cta && <Button onClick={() => gaBtnClick(CTA_CLICK_EVENT, headline, cta.title)} {...cta} />}
          </div>
          {showDivider && <Divider />}
        </div>
      </Container>
    </>
  )
}

export default CardFullWidth
